function tryParseArray(str) {
  if (typeof str === 'string') {
    // Check if it looks like an array format without properly quoted items
    const fixedStr = str.replace(/(\w+)/g, '"$1"');
    try {
      return JSON.parse(fixedStr); // Attempt to parse
    } catch (e) {
      return null; // Return null if parsing fails
    }
  }
  return null;
}

export default tryParseArray;
