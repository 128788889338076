export const RELEASE_STATE_VALIDATION = 'validation';
export const RELEASE_STATE_BUNDLE = 'asset release';
export const RELEASE_STATE_FINAL_BUNDLE = 'final asset release';
export const RELEASE_STATE_CANCELLED = 'cancelled';
export const RELEASE_STATE_DELETED = 'deleted';
export const RELEASE_STATE_REGRESSION_TEST_VALIDATION = 'regression test validation';
export const RELEASE_STATE_GLOBAL_DEPLOYED = 'global deployed';
export const RELEASE_STATE_ERROR = 'error';
export const RELEASE_STATE_IN_PROGRESS = 'in progress';
export const CONNECTIVITY_FW_NAME = 'Connectivity firmware';
export const MAIN_FW_NAME = 'Main firmware';
export const RECIPE_FW_NAME = 'Recipe firmware';
export const CONNECTIVITY_FW = 'fmw-connectivity';
export const MAIN_FW = 'fmw-main';
export const RECIPE_FW = 'recipe';
export const NEW_DEPLOYMENT_PATH = '/deployments/create';
export const NEW_ASSET_PATH = '/assets/create';
export const PUBLISHED_FW = 'Published';
export const NOT_PUBLISHED_FW = 'Not published';
export const FAILED_TO_PUBLISH_FW = 'Failed to publish';

export const CREATED_IN_ALM = 'created_in_alm';
export const CREATED_IN_IOTHUB = 'created_in_iothub';
export const DELETED_BY_USER = 'deleted_by_user';
export const TO_DELETE_IN_IOTHUB = 'to_delete_in_iothub';
export const ERROR_CREATING_IN_IOTHUB = 'error_creating_in_iothub';
export const ERROR_DELETING_IN_IOTHUB = 'error_deleting_in_iothub';

export const DEPLOY_TYPE_GLOBAL = 'All';
export const DEPLOY_TYPE_SERIAL = 'Serial';
export const DEPLOY_TYPE_MARKET = 'Market';
export const DEPLOY_TYPE_REGEX = 'Regex';

export const DEFAULT_TIMETOAPPLY = '99:99';
export const POLLING_INTERVAL = 1000;
export const POLLING_TIMEOUT = 10000;

// validates that the version number added in the screen follows the pattern v.v.v,
// where v is a number with at least length 1
export const REGEX_VERSION = /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/;

// validates that the timestamp to apply added in the screen follows the pattern HH:mm or
// the default 99:99, where HH are 24 hour and mm are from 0 to 59 minutes
export const REGEX_TIMESTAMPTOAPPLY = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

// validates that serialNumber has no strange characters
export const REGEX_SERIAL_NUMBER = /^[a-zA-Z0-9_.-]*$/;

export const POLLING_REFRESH_INTERVAL = 1000; // in milliseconds

// Permissions
export const SHOW_ASSETS_PERMISSION = 'showAssets';
export const DELETE_ASSETS_PERMISSION = 'deleteAsset';
export const SHOW_BINARIES_PERMISSION = 'showBinaries';
export const SHOW_DEPLOYMENTS_PERMISSION = 'showDeployments';
export const CREATE_NON_GLOBAL_PHASE_PERMISSION = 'createNonGlobalPhase';
export const CREATE_GLOBAL_PHASE_PERMISSION = 'createGlobalPhase';
export const UPLOAD_ASSET_PERMISSION = 'uploadAsset';
export const SHOW_MACHINE_STATUS_PERMISSION = 'showMachineStatus';
export const SHOW_MACHINE_EVENTS_PERMISSION = 'showMachineEvents';
export const SHOW_MACHINE_EVENTS_PERMISSION_CSV = 'showMachineEvents';
export const SHOW_BRAND_CONSUMPTIONS_PERMISSION = 'showBrandConsumptions';
export const SHOW_BRAND_CONSUMPTIONS_PERMISSION_CSV = 'showBrandConsumptionsCSV';
export const SHOW_TELEMETRY_OBJECT_PERMISSION_CSV = 'showTelemetryObjectCSV';
export const SHOW_TELEMETRY_EVENTS_PERMISSION = 'showTelemetryObject';
export const SHOW_LIFE_CYCLE_EVENTS_PERMISSION = 'showLifeCycleObject';
export const SHOW_SANDBOX_OBJECT_PERMISSION = 'showSandboxObject';
export const IS_ROOT = 'isRoot';
export const SHOW_FOTA_EVENTS_PERMISSION = 'showFotaEvents';
export const SHOW_IOT_REPORTS_PERMISSION = 'showIotReports';
export const SHOW_SUPPORT_PERMISSION = 'showSupport';
export const SHOW_MESSAGE_SIMULATOR = 'showMessageSimulator';
export const SHOW_CLOUD_TO_DEVICE = 'showCloudToDevice';
export const SHOW_REQUEST_MANAGER = 'showRequestManager';
export const SHOW_CERTIFICATE_QUOTA = 'showCertificateQuota';
export const CREATE_NON_GLOBAL_DEPLOY_PERMISSION = 'createNonGlobalDeploy';
export const CREATE_GLOBAL_DEPLOY_PERMISSION = 'createGlobalDeploy';
export const DELETE_DEPLOY_PERMISSION = 'deleteDeploy';
export const GET_BUNDLES_FOR_GIVEN_BRAND = 'getBundles';
export const SHOW_CERTIFICATE_STATUS = 'showCertificateStatus';

export const MAX_FILE_SIZE = 525629776; // 50Mb

// Errors message
export const ERROR_WRONG_FILE_TYPE = 'Wrong file type, only accepted .zip';
export const ERROR_CANNOT_BE_EMPTY = 'Cannot be empty';
export const ERROR_MAX_FILE_SIZE = 'Max file size allowed = 500 MB';
export const ERROR_BINARY = 'Binary not found in zip';
export const ERROR_FIRMWARE = 'Mandatory fields not included. Please, make sure the following fields are included in the Manifest file: Type, Metadata, Chunks, AssetDescription, AssetDescription.Version';
export const ERROR_MANIFEST = 'Manifest.json not found in zip';
export const ERROR_JSON = 'Manifest file has invalid JSON';
export const ERROR_ZIP = 'Cannot read zip';
export const ERROR_NO_SERIALS = 'You must upload a list of serial numbers';
export const ERROR_SERIALS_LIMIT = 'Please check the list in order to not exceed the 500 serial numbers';
export const ERROR_DUPLICATED_SERIALS = 'The machine serial numbers are duplicated, please check them out';
export const VALIDATION_REGEX_MANDATORY = 'Regular expression is mandatory';
export const VALIDATION_MAX_1_REGEX = 'Only one regular expression is accepted';
export const VALIDATION_LENGTH_MAX_REGEX = 'Maximum length for regular expression is 300 characters';
export const VALIDATION_MARKET_MANDATORY = 'At least one market is mandatory';

// Filetypes
export const CSV_FILETYPE = 'csv';

// Footer URLs
export const URL_NESTLE = 'https://www.nestle.com/info/copyright';
export const URL_PRIVACY = 'https://www.nestle.com/aboutus/businessprinciples/privacy';
export const URL_TERMS = 'https://www.nestle.com/info/tc';
export const URL_WORKPLACE = 'https://nestle.workplace.com/groups/mseconsumeriot';

// Report screen URLs
export const URL_THIS_FORM = 'https://nestle.service-now.com/sp?id=sc_cat_item&sys_id=2061ac43dbbbbb800f71f9851d96192f';

// Report screen URLs
export const URL_KNOWLEDGE = 'https://nestle.service-now.com/sp?id=nestle_kb_article_view&sys_kb_id=620533ab1ba6e9d48d9d8735464bcb9b';

// Regex for Markets
export const validMarketRegex = /^(AD|AE|AF|AG|AI|AL|AM|AO|AQ|AR|AS|AT|AU|AW|AX|AZ|BA|BB|BD|BE|BF|BG|BH|BI|BJ|BL|BM|BN|BO|BQ|BR|BS|BT|BV|BW|BY|BZ|CA|CC|CD|CF|CG|CH|CI|CK|CL|CM|CN|CO|CR|CU|CV|CW|CX|CY|CZ|DE|DJ|DK|DM|DO|DZ|EC|EE|EG|EH|ER|ES|ET|FI|FJ|FK|FM|FO|FR|GA|GB|GD|GE|GF|GG|GH|GI|GL|GM|GN|GP|GQ|GR|GS|GT|GU|GW|GY|HK|HM|HN|HR|HT|HU|ID|IE|IL|IM|IN|IO|IQ|IR|IS|IT|JE|JM|JO|JP|KE|KG|KH|KI|KM|KN|KP|KR|KW|KY|KZ|LA|LB|LC|LI|LK|LR|LS|LT|LU|LV|LY|MA|MC|MD|ME|MF|MG|MH|MK|ML|MM|MN|MO|MP|MQ|MR|MS|MT|MU|MV|MW|MX|MY|MZ|NA|NC|NE|NF|NG|NI|NL|NO|NP|NR|NU|NZ|OM|PA|PE|PF|PG|PH|PK|PL|PM|PN|PR|PS|PT|PW|PY|QA|RE|RO|RS|RU|RW|SA|SB|SC|SD|SE|SG|SH|SI|SJ|SK|SL|SM|SN|SO|SR|SS|ST|SV|SX|SY|SZ|TC|TD|TF|TG|TH|TJ|TK|TL|TM|TN|TO|TR|TT|TV|TW|TZ|UA|UG|UM|US|UY|UZ|VA|VC|VE|VG|VI|VN|VU|WF|WS|YE|YT|ZA|ZM|ZW)$/;

// Tooltips
export const TOOLTIP_DEVICE_DEPLOYMENT_CSV = 'Download this .csv file with device serials';
export const TOOLTIP_DEVICE_DEPLOYMENT = 'Device deployment';
export const TOOLTIP_MARKET_DEPLOYMENT = 'Market deployment';
export const TOOLTIP_REGEX_DEPLOYMENT = 'Regex deployment';
export const TOOLTIP_GLOBAL_DEPLOYMENT = 'Global deployment';

export const DEPLOYMENT_STATUS = {
  0: 'Error',
  1: 'Request',
  2: 'Validation',
  3: 'Download',
  4: 'Asset Verification',
  5: 'Confirm',
  6: 'Execute',
  7: 'Done',
};
