/* eslint-disable react/jsx-props-no-spreading */
import { Button, makeStyles } from '@material-ui/core';
import { React } from 'react';

export default function PrimaryButton(props) {
  const useStyles = makeStyles((theme) => ({
    primaryButton: {
      backgroundColor: theme.palette.primary.button,
      borderRadius: 12,
      boxShadow: theme.customShadows.regularShadow,
      color: theme.palette.common.white,
      marginLeft: theme.spacing(3),
      '& :disabled': {
        color: theme.palette.primary.light,
      },
    },
  }));

  const classes = useStyles();
  return (<Button {...props} className={classes.primaryButton} color="secondary" variant="contained" />);
}
