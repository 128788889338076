import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container, Grid, Typography, Button,
} from '@material-ui/core';
import { ReactComponent as MaintenanceIcon } from '../assets/maintenance.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
  },
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    fontSize: 50,
  },
  subtitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
    textAlign: 'center',
    fontSize: 30,
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
  },
  iconSubTitles: {
    color: 'black',
    fontSize: 200,
    marginBottom: 20,
  },
}));

const MaintenanceScreen = () => {
  const classes = useStyles();

  const handleTryAgain = () => {
    window.location.reload();
  };

  return (
    <Container className={classes.root}>
      <Grid container direction="column" alignItems="center">
        <Typography variant="h4" className={classes.title}>
          Ooops. We are down for maintenance.
        </Typography>
        <Typography variant="h4" className={classes.subtitle}>
          Don´t worry, we will be right back!
        </Typography>
        <Grid item>
          <MaintenanceIcon className={classes.iconSubTitles} />
        </Grid>
        <Grid item />
        <Grid item className={classes.buttonContainer}>
          <Button variant="contained" color="primary" onClick={handleTryAgain}>
            Try Again
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MaintenanceScreen;
