/* eslint-disable react/jsx-props-no-spreading */
import { Button, makeStyles } from '@material-ui/core';
import { React } from 'react';

export default function LoginButton(props) {
  const useStyles = makeStyles((theme) => ({
    loginButton: {
      backgroundColor: '#295299',
      color: theme.palette.common.white,
      '& :disabled': {
        color: theme.palette.primary.light,
      },
      width: '100%',
      height: '40px',
    },
  }));

  const classes = useStyles();
  return (<Button {...props} id="btnLoginSSO" className={classes.loginButton} color="secondary" variant="contained" />);
}
