// src/CookieConsent.js
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import {
  Snackbar, Button, Typography, makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  snackbar: {
    bottom: theme.spacing(3),
    left: theme.spacing(3),
  },
  button: {
    backgroundColor: '#4CAF50',
    color: 'white',
    '&:hover': {
      backgroundColor: '#45A049',
    },
  },
}));

const CookieConsent = () => {
  const classes = useStyles();
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const cookieConsent = Cookies.get('cookieConsent');
    if (!cookieConsent) {
      setShowPopup(true);
    }
  }, []);

  const acceptCookies = () => {
    Cookies.set('cookieConsent', 'true', { expires: 365 });
    setShowPopup(false);
  };

  return (
    <Snackbar
      open={showPopup}
      message={(
        <Typography variant="body1">
          This website uses cookies to improve your experience. By accepting, you agree to the use of cookies.
        </Typography>
      )}
      action={(
        <Button className={classes.button} onClick={acceptCookies}>
          Accept
        </Button>
      )}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      ContentProps={{
        classes: {
          root: classes.snackbar,
        },
      }}
    />
  );
};

export default CookieConsent;
