import React, { useRef } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';

export default function YesNoDialog({
  open, setOpen, header, body, callback, action, actionName, id,
}) {
  const handleClose = () => {
    setOpen(false);
    if (callback) callback();
  };

  const dialogRef = useRef(null);

  return (
    <Dialog
      ref={dialogRef}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{header || 'Alert'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        <Button onClick={handleClose} id={`${id}-no`}>No</Button>
        <Button onClick={() => { action(); handleClose(); }} variant="contained" color="primary" style={{ borderRadius: 12 }} id={`${id}-action`}>{actionName}</Button>
      </DialogActions>
    </Dialog>
  );
}
YesNoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  callback: PropTypes.func,
  action: PropTypes.func.isRequired,
  actionName: PropTypes.string.isRequired,
  id: PropTypes.string,
};
YesNoDialog.defaultProps = {
  id: undefined,
  callback: undefined,
};
