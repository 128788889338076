/* eslint-disable no-await-in-loop */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable radix */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-curly-brace-presence */
import {
  Box, Button, Grid, makeStyles, TextField, Typography,
  Chip, withStyles, LinearProgress, Link, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  TableCell, Table, TableBody, TableContainer, Paper, TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  React, useContext, useState, useRef,
} from 'react';
import { set, useForm } from 'react-hook-form';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { DropzoneArea } from 'material-ui-dropzone';
import { Alert } from '@material-ui/lab';
import JSZip from 'jszip';
import moment from 'moment';
import { useProject } from '../../config/ProjectContext';
import GraphqlService from '../../service/graphqlService';
import PageBanner from '../../components/utils/PageBanner';
import PrimaryButton from '../../components/utils/PrimaryButton';
import SimpleAlert from '../../components/utils/SimpleAlert';
import ComplexAlert from '../../components/utils/ComplexAlert';
import {
  MAX_FILE_SIZE,
  ERROR_WRONG_FILE_TYPE, ERROR_CANNOT_BE_EMPTY, ERROR_MAX_FILE_SIZE,
  ERROR_BINARY, ERROR_FIRMWARE, ERROR_MANIFEST, ERROR_JSON, ERROR_ZIP,
} from '../../utils/constants';
import { ReactComponent as UploadIcon } from '../../assets/ic_import.svg';
import { ReactComponent as LaunchIcon } from '../../assets/ic_launch.svg';
import { ReactComponent as UserGuideIcon } from '../../assets/ic_user_guide.svg';
import { useSnackbar } from '../../providers/SnackbarContext';
import SelectedObjectsToDeployContext from '../../config/SelectedAssetsContext';

const FirmwaresNamesStartDot = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    height: 8,
    width: 8,
    borderRadius: 90,
    marginRight: 8,
    marginBottom: 3,
    verticalAlign: 'middle',
    display: 'inline-flex',
  },
}))(Box);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 24,
  },
  description: {
    fontSize: 16,
    color: theme.palette.primary.text,
  },
  box: {
    backgroundColor: theme.palette.background.form,
    borderRadius: 12,
    padding: '0px 24px 32px 24px',
    margin: '16px 0',
  },
  formField: {
    width: '100%',
  },
  errorAlert: {
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 4,
  },
  dropZone: {
    border: `2px dashed ${theme.palette.primary.modelPicker}`,
    marginTop: 32,
    minHeight: 0,
    padding: 32,
  },
  dropZoneParagraph: {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontStyle: 'italic',
    fontWeight: 'normal',
    margin: '0 0 24px 0',
    textAlign: 'center',
  },
  releaseData: {
    background: theme.palette.background.paper,
    borderRadius: 8,
    padding: '16px 24px',
    marginTop: 40,
  },
  releaseDataTitle: {
    fontSize: 18,
    marginBottom: 8,
  },
  releaseDataItem: {
    display: 'inline',
    fontSize: 14,
  },
  chipTitle: {
    color: theme.palette.primary.text,
    fontSize: 16,
    marginRight: 16,
  },
  chip: {
    background: theme.palette.background.paper,
    color: theme.palette.secondary.contrastText,
  },
  cancel: {
    border: `2px solid ${theme.palette.primary.main}`,
    fontSize: 14,
    borderRadius: 12,
    color: theme.palette.primary.main,
  },
  uploadButton: {
    background: theme.palette.background.form,
    borderRadius: 2,
    boxShadow: 'none',
    color: theme.palette.primary.text,
    padding: '8px 24px',
  },
  link: {
    fontSize: 12,
    fontFamily: 'Roboto',
    fontWeight: 500,
    lineHeight: '14px',
    color: theme.palette.primary.main,
    paddingLeft: 8,
    paddingRight: 8,
  },
  tableButton: {
    height: 48,
    justifyContent: 'center',
    margin: 4,
    color: 'white',
    borderRadius: 12,
    backgroundColor: theme.palette.primary.main,
    // hover
    '&:hover': {
      backgroundColor: 'black',
    },
  },
}));

function ButtonUploadBin() {
  const classes = useStyles();
  return (
    <Button
      className={classes.uploadButton}
      id="btnCreateUpload"
      startIcon={<UploadIcon />}
      variant="contained"
    >
      UPLOAD PACKAGE
    </Button>
  );
}

export default function AssetCreate() {
  // STATE________________________________
  const { project } = useProject();
  const openSnackBar = useSnackbar();

  const dialogRef = useRef(null);

  //  alert data
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertHeader, setAlertHeader] = useState('');
  const [alertText, setAlertText] = useState('');
  const [alertStringsText, setAlertStringsText] = useState([]);
  const [alertStringsHeader, setAlertStringsHeader] = useState('');
  const [alertStringsOpen, setAlertStringsOpen] = useState(false);
  //  state values
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileZipError, setFileZipError] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);
  const [fileZipErrorSize, setFileZipErrorSize] = useState(false);

  const [listFirmwaresError, setListFirmwaresError] = useState(false);

  const [myFileZip, setMyFileZip] = useState();
  const [listFirmwares, setListFirmwares] = useState();

  const [openDialog, setOpenDialog] = useState(false);

  const { selectedObjectsToDeploy: selectedAssets, setSelectedObjectsToDeploy: setSelectedAssets } = useContext(SelectedObjectsToDeployContext);

  const history = useHistory();
  const match = useRouteMatch();
  const handleDialogClose = (goToDeployedAsset) => {
    setOpenDialog(false);
    if (goToDeployedAsset) {
      // Redirect to the deployed asset page
      if (selectedAssets.length === 1) {
        history.push(`${match.url.replace('/create', '')}/deployments/create`);
      } else {
        history.push(`${match.url.replace('/assets/create', '')}/bundles/create/asset`);
      }
    } else {
      // Redirect to the assets page
      history.push('/assets');
    }
  };

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#E0E0E0',
      },
    },
  }))(TableRow);

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
      minWidth: 100,
      maxWidth: '400px', // Adjust the value as needed
      wordWrap: 'break-word', // Ensure long words break to fit
    },
  }))(TableCell);

  function openAlert(header, text) {
    setAlertHeader(header);
    setAlertText(text);
    setAlertOpen(true);
  }

  function openAlertStrings(header, strings) {
    setAlertStringsHeader(header);
    setAlertStringsText(strings);
    setAlertStringsOpen(true);
  }

  // FILE LOADER_________________________________

  const handleChangeFileZip = async (files) => {
    function firmwareIsValid(firmware) {
      return Array.isArray(firmware.Chunks)
        && typeof firmware.Type === 'string'
        && ((firmware.MetaData) || (firmware.Metadata)) // only mandatory, not take into account the fields type
        && firmware.AssetDescription && typeof firmware.AssetDescription === 'object'
        && typeof firmware.AssetDescription.Version === 'string';
    }

    function binariesExist(firmware, zip, root) {
      for (let i = 0; i < firmware.Chunks.length; i += 1) {
        const binaryName = firmware.Chunks[i];
        const binaryFile = zip.file(binaryName) || zip.file(`${root}/${binaryName}`);
        if (!binaryFile) {
          return false;
        }
      }
      return true;
    }

    if (files.length === 0) {
      return;
    }

    setLoading(true);

    // Read ZIP
    const file = files[0];
    const root = file.name.replace(/\.zip$/, '');
    const data = await file.arrayBuffer();
    const zip = new JSZip();
    try {
      await zip.loadAsync(data);
    } catch (error) {
      setLoading(false);
      openAlert('Error', ERROR_ZIP);
      return;
    }

    // Get manifest
    const manifestFile = zip.file('Manifest.json') || zip.file(`${root}/Manifest.json`);
    if (!manifestFile) {
      setLoading(false);
      openAlert('Error', ERROR_MANIFEST);
      return;
    }

    // Parse manifest
    const manifestText = await manifestFile.async('text');
    let manifest;
    try {
      manifest = JSON.parse(manifestText);
    } catch (error) {
      setLoading(false);
      openAlert('Error', ERROR_JSON);
      return;
    }

    // Validate manifest & binaries
    let isValid = true;
    const { length } = manifest.Contents;
    const list = new Array(length);
    for (let i = 0; i < length; i += 1) {
      const firmware = manifest.Contents[i];
      if (!firmwareIsValid(firmware)) {
        openAlert('Error', ERROR_FIRMWARE);
        isValid = false;
        break;
      }
      if (!binariesExist(firmware, zip, root)) {
        openAlert('Error', ERROR_BINARY);
        isValid = false;
        break;
      }
      list[i] = `${firmware.AssetDescription.Description || firmware.Type} v${firmware.AssetDescription.Version}`;
    }

    setListFirmwares(isValid ? list : '');
    setMyFileZip(isValid ? file : undefined);

    setLoading(false);
  };

  // FORM_________________________________
  const {
    register, handleSubmit, formState: { errors },
  } = useForm();

  function validate(fileZip, firmwares) {
    let validates = true;
    if (!fileZip) {
      setFileZipError(true);
      validates = false;
    }
    if (!firmwares) {
      setListFirmwaresError(true);
      validates = false;
    }
    return validates;
  }

  async function save(myProject, file) {
    setSubmitting(true);
    try {
      const creationResponse = await GraphqlService.uploadAssetsFromPackage(
        myProject.code, file,
      );
      // search if there is errors
      const assetsNotUploaded = creationResponse.filter((asset) => asset.error);
      if (assetsNotUploaded.length > 0) {
        const alertStrings = [];
        creationResponse.forEach((assetOutput) => {
          if (assetOutput.error) {
            // asset upload has failed
            alertStrings.push(`Asset "${assetOutput.typeDescription}" could not be uploaded: ${assetOutput.error}`);
          } else {
            // asset uploaded successfully
            alertStrings.push(`Asset "${assetOutput.typeDescription}" successfully uploaded. FileID: ${assetOutput.fileID}`);
          }
        });
        openAlertStrings('Error', alertStrings);
        openSnackBar('Error uploading assets', 'error');
      } else {
        openSnackBar('Asset uploaded successfully', 'success');
        // add the new assets to the selected assets (add the FileId)
        setSelectedAssets([...creationResponse.map((asset) => ({
          ...asset, fileId: asset.fileID, type: asset.typeDescription, dateTime: moment().format(),
        }))]);
        // open a dialog with material 4, to ask if the user wants to go to the deployed asset if not go to the assets page
        setOpenDialog(true);
      }
    } catch (error) {
      openAlert('Error', error.message);
      openSnackBar('Error uploading assets', 'error');
    }
    setSubmitting(false);
  }

  const onSubmit = (data) => {
    if (validate(myFileZip, listFirmwares)) {
      save(
        project,
        myFileZip,
      );
    }
  };

  const onCloseComplexAlert = () => {
    history.push('/assets');
  };

  // WEBPAGE______________________________
  const classes = useStyles();

  const FileChip = () => (
    <Box style={{ marginTop: 16 }}>
      <Typography display="inline" className={classes.chipTitle}>File to upload:</Typography>
      <Chip className={classes.chip} color="primary" id="chipCreateFile" label={myFileZip.name} onDelete={() => setMyFileZip(undefined)} variant="outlined" />
    </Box>
  );

  return (
    <Box>
      <SimpleAlert open={alertOpen} setOpen={setAlertOpen} header={alertHeader} body={alertText} />
      <ComplexAlert open={alertStringsOpen} setOpen={setAlertStringsOpen} header={alertStringsHeader} body={alertStringsText} callback={onCloseComplexAlert} />
      {(loading || submitting) && <LinearProgress />}
      <PageBanner title="ADD ASSETS" />
      <Grid container spacing={100} className={classes.root}>
        <Grid item container justifyContent="flex-end" alignItems="center" xs={12}>
          <UserGuideIcon style={{ width: 24, height: 24 }} />
          <Link
            href="https://nestle.atlassian.net/wiki/spaces/DIIS/pages/107978873/Portal+-+Assets"
            underline="always"
            target="_blank"
            className={classes.link}
          >
            User Guide: How to create a new Asset zip file
          </Link>
          <LaunchIcon style={{ width: 16, height: 16 }} />
        </Grid>
        <Grid justifyContent="center" container item xs={12} className={classes.box}>
          <Grid item xs={6}>
            <form id="form" onSubmit={handleSubmit(onSubmit)}>
              <DropzoneArea
                Icon={ButtonUploadBin}
                acceptedFiles={['.zip']}
                dropzoneText="Upload your firmware binaries and manifest file in ZIP format. Max filesize allowed = 500 MB"
                dropzoneClass={classes.dropZone}
                dropzoneParagraphClass={classes.dropZoneParagraph}
                filesLimit={1}
                maxFileSize={MAX_FILE_SIZE}
                showPreviewsInDropzone={false}
                showAlerts={false}
                onChange={(files) => {
                  setFileZipError(!files);
                  handleChangeFileZip(files);
                  setFileZipErrorSize(false);
                }}
                onDropRejected={(file) => {
                  if (file[0].size > MAX_FILE_SIZE) {
                    setFileZipErrorSize(true);
                  } else {
                    setFileTypeError(true);
                  }
                }}
              />
              {fileZipError && (
                <Alert severity="error" className={classes.errorAlert}>
                  {ERROR_CANNOT_BE_EMPTY}
                </Alert>
              )}
              {fileZipErrorSize && (
                <Alert severity="error" className={classes.errorAlert}>
                  {ERROR_MAX_FILE_SIZE}
                </Alert>
              )}
              {fileTypeError && (
                <Alert severity="error" className={classes.errorAlert}>
                  {ERROR_WRONG_FILE_TYPE}
                </Alert>
              )}
            </form>
            {myFileZip && <FileChip />}
          </Grid>
          {myFileZip && (
            <Grid container item xs={12} className={classes.releaseData}>
              <Grid item>
                <Typography className={classes.releaseDataTitle}>Assets data</Typography>
              </Grid>
              <Grid container item direction="row">
                {listFirmwares.map((firmware, i) => (
                  <Grid item xs={4} style={{ marginTop: 8 }}>
                    <FirmwaresNamesStartDot />
                    <Typography className={classes.releaseDataItem} id={`txtCreateAsset${i}`}>{`${firmware}`}</Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container item justifyContent="flex-end" spacing={24}>
          <Grid item>
            <Button
              id="btnCreateCancel"
              className={classes.cancel}
              disabled={submitting || loading}
              onClick={history.goBack}
              variant="outlined"
            >
              Cancel
            </Button>
          </Grid>
          <Grid>
            <PrimaryButton
              id="btnCreateSubmit"
              disabled={myFileZip === undefined || (submitting || loading)}
              form="form"
              type="submit"
            >
              Submit
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        ref={dialogRef}
        open={openDialog}
        onClose={() => handleDialogClose(false)}
      >
        <DialogTitle>Upload Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedAssets.length === 1 ? 'Your asset has been uploaded. Do you want to deploy it now?' : 'Your package has been uploaded. Do you want to create a bundle now?'}

          </DialogContentText>
          <TableContainer component={Paper} className={classes.telemetryTable}>
            <Table>
              <TableBody>
                {(selectedAssets && selectedAssets.length > 0) ? selectedAssets.map((asset) => (
                  <StyledTableRow>

                    <StyledTableCell>
                      ✅
                      {' '}
                      {`${asset.type ? asset.type : ''} v.${asset.assetDescription ? asset.assetDescription?.Version : '-'}`}
                    </StyledTableCell>
                    <StyledTableCell>
                      {`${asset.fileID}`}
                    </StyledTableCell>
                    <StyledTableCell>
                      {`${asset.assetDescription?.Description || '-'}`}
                    </StyledTableCell>

                  </StyledTableRow>

                )) : (
                  <>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button className={classes.tableButton} color="secondary" onClick={() => handleDialogClose(true)} autoFocus>
            Yes
          </Button>
          <Button className={classes.tableButton} color="secondary" onClick={() => handleDialogClose(false)}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
