import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';

const EditableInput = ({ value: propValue, onChange }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(propValue);

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value);
  };

  const handleConfirmClick = () => {
    setIsEditing(false);
    if (value !== propValue) {
      onChange(value);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setIsEditing(false);
      onChange(value);
    }
  };

  return (
    <div>
      <IconButton
        onClick={isEditing ? handleConfirmClick : handleEditClick}
        data-testid="toggle-edit-button"
      >

        {isEditing ? <CheckIcon fontSize="small" /> : <EditIcon fontSize="small" />}
      </IconButton>
      {isEditing ? (
        <TextField
          value={value}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          data-testid="editable-input"
        />
      ) : (

        <span data-testid="display-value">{value}</span>
      )}
    </div>
  );
};

EditableInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

EditableInput.defaultProps = {
  value: '',
};

export default EditableInput;
