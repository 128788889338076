/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AuthorizationContext from './AuthorizationContext';
import { useProject } from '../config/ProjectContext';
// This provider is intended to be surrounding the whole application.
// It should receive a method to fetch permissions as parameter
export default function AuthorizationProvider({ fetchPermissions, children }) {
  let cache;
  const { project } = useProject();

  // Creates a method that returns whether the requested permission is granted to the current user
  async function isAllowedTo(permission) {
    if (cache) {
      return (await cache)?.includes(permission);
    }
    cache = fetchPermissions(project?.code);
    return (await cache)?.includes(permission);
  }

  async function refreshCache() {
    cache = fetchPermissions(project?.code);
  }

  useEffect(async () => {
    await refreshCache();
  }, [project]);

  // This component will render its children wrapped around a PermissionContext's provider whose
  // value is set to the method defined above
  return (
    <AuthorizationContext.Provider value={{ isAllowedTo }}>
      {children}
    </AuthorizationContext.Provider>
  );
}
AuthorizationProvider.propTypes = {
  fetchPermissions: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
};
