// Machine Status Lifecycle
export const machineId = 'Device Serial';
export const phaseTimestamp = 'Device';
export const deployTimestamp = 'Platform';
export const eventType = 'Type';
export const status = 'Status';
export const description = 'Description';
export const phase = 'Phase';
export const firmwareType = 'Asset Type';
export const firmwareVersion = 'Version';
