/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable max-len */

import {
  Box, Grid, Typography, makeStyles, Link,
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PageBanner from '../../components/utils/PageBanner';
import { config } from '../../config/awsExports';
import { ReactComponent as ReportIcon } from '../../assets/ic_report.svg';
import {
  URL_THIS_FORM,
} from '../../utils/constants';

// eslint-disable-next-line import/no-dynamic-require
const { env } = config;

const useStyles = makeStyles((theme) => ({
  cardReport: {
    backgroundColor: theme.palette.primary.bannerText,
    width: '100%',
    minHeight: 210,
    maxWidth: 226,
    borderRadius: 12,
    border: `1px solid ${theme.palette.primary.main}`,
    marginTop: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '24px',
  },
  iconReport: {
    marginBottom: 16,
    height: 32,
    width: 32,
  },
  iconOutReport: {
    color: 'grey',
    height: 16,
    width: 16,
    marginLeft: 8,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  titleReport: {
    fontWeight: theme.typography.h12.fontWeight,
    fontFamily: theme.typography.h12.fontFamily,
    lineHeight: theme.typography.h12.lineHeight,
    letterSpacing: theme.typography.h12.letterSpacing,
    fontSize: theme.typography.h12.fontSize,
    color: theme.palette.primary.main,
    marginBottom: 12,
  },
  subtitleReport: {
    fontFamily: theme.typography.h7.fontFamily,
    lineHeight: theme.typography.h7.lineHeight,
    letterSpacing: theme.typography.h7.letterSpacing,
    fontSize: theme.typography.h7.fontSize,
    color: theme.palette.secondary.subText,
    marginBottom: 16,
    marginLeft: 29,
    marginRight: 29,
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.h7.fontWeight,
    fontFamily: theme.typography.h7.fontFamily,
    lineHeight: theme.typography.h7.lineHeight,
    letterSpacing: theme.typography.h7.letterSpacing,
    fontSize: theme.typography.h7.fontSize,
    '&:hover': {
      color: '#337AB7',
      textDecoration: 'none',
    },
  },
  cardFooterReport: {
    backgroundColor: theme.palette.primary.subFooter,
    width: '100%',
    minHeight: 35,
    borderRadius: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'fixed',
    bottom: 96,
  },
  textFooterReport: {
    fontFamily: theme.typography.h11.fontFamily,
    lineHeight: theme.typography.h11.lineHeight,
    letterSpacing: theme.typography.h11.letterSpacing,
    fontSize: theme.typography.h11.fontSize,
    color: theme.palette.secondary.text,
    marginLeft: 16,
  },
  openContent: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function Reports() {
  const classes = useStyles();

  return (
    <Box>
      <PageBanner title="REPORTS" />
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Grid item xs={3} className={classes.cardReport}>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            id="nespresso-report"
          >
            <ReportIcon className={classes.iconReport} />
            <Typography className={classes.titleReport}>Nespresso Dashboard</Typography>
            <Typography className={classes.subtitleReport}>Access to a specific IoT-related dashboard.</Typography>
            <Grid
              container
              spacing={0}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Link id="btnNespressoDashboard" target="_blank" className={classes.link} href={env.nespressoDashboardUrl}>
                <div className={classes.openContent}>
                  OPEN
                  <OpenInNewIcon className={classes.iconOutReport} />
                </div>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.cardReport}>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            id="ndg-report"
          >
            <ReportIcon className={classes.iconReport} />
            <Typography className={classes.titleReport}>NDG Dashboard</Typography>
            <Typography className={classes.subtitleReport}>Access to a specific IoT-related dashboard.</Typography>
            <Grid
              container
              spacing={0}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Link id="btnNDGDashboard" target="_blank" className={classes.link} href={env.ndgDashboardUrl}>
                <div className={classes.openContent}>
                  OPEN
                  <OpenInNewIcon className={classes.iconOutReport} />
                </div>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.cardReport}>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            id="nescafe-report"
          >
            <ReportIcon className={classes.iconReport} />
            <Typography className={classes.titleReport}>Nescafe Dashboard</Typography>
            <Typography className={classes.subtitleReport}>Access to a specific IoT-related dashboard.</Typography>
            <Grid
              container
              spacing={0}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Link id="btnNescafeDashboard" target="_blank" className={classes.link} href={env.nescafeDashboardUrl}>
                <div className={classes.openContent}>
                  OPEN
                  <OpenInNewIcon className={classes.iconOutReport} />
                </div>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.cardReport}>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            id="create-dashboard"
          >
            <ReportIcon className={classes.iconReport} />
            <Typography className={classes.titleReport}>Create a new Dashboard</Typography>
            <Typography className={classes.subtitleReport}>Access to a specific IoT-related dashboard.</Typography>
            <Grid
              container
              spacing={0}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Link id="btnCreateDashboard" target="_blank" className={classes.link} href={env.newDashboardUrl}>
                <div className={classes.openContent}>
                  OPEN
                  <OpenInNewIcon className={classes.iconOutReport} />
                </div>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
      >
        <Grid item xs={12} className={classes.cardFooterReport}>
          <Typography className={classes.textFooterReport}>Don&apos;t have access to a report? Request access using</Typography>
          <Link id="btnReportsForm" target="_blank" className={classes.link} href={URL_THIS_FORM}>
            &nbsp;
            <u>this form.</u>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}
