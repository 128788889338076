/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Grid, Box, LinearProgress, makeStyles, Checkbox, Input,
  Table, TableBody, TableContainer, TableFooter, TableRow, Chip, MenuItem, Select,
  Paper, TableCell, TableHead, withStyles, Button, IconButton, Divider, Tabs, Tab,
  TextField,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import {
  React, useEffect, useState,
} from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import InputLabel from '@material-ui/core/InputLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { motion } from 'framer-motion';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import CachedIcon from '@material-ui/icons/Cached';
import ClearIcon from '@material-ui/icons/Clear';
import PageBanner from '../../components/utils/PageBanner';
import { useSnackbar } from '../../providers/SnackbarContext';
// Local imports
import { useProject } from '../../config/ProjectContext';
import GraphqlService from '../../service/graphqlService';
import MessageCard from '../../components/utils/MessageCard';
import SearchButton from '../../components/utils/SearchButton';
// Import the BundleWizard component
function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  index: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
};

export default function RequestManager() {
  const useStyles = makeStyles((theme) => ({
    separate: {
      padding: 24,
    },
    headerTabPanel: {
      height: 48,
      backgroundColor: theme.palette.background.paper,
    },
    pagination: {
      background: theme.palette.background.paper,
      borderRadius: 12,
      boxShadow: theme.customShadows.regularShadow,
      color: theme.palette.primary.text,
      float: 'right',
      padding: 0,
      width: '100%',
    },
    table: {
      marginTop: theme.spacing(2),
    },
    labelHeaderTabPanel: {
      color: theme.palette.primary.subMain,
      fontFamily: theme.typography.h3.fontFamily,
      fontSize: '16px',
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: '0',
      lineHeight: '19px',
      textAlign: 'center',
      width: '720px',
      height: '48px',
    },
    background: {
      backgroundColor: theme.palette.background.typography,
    },
    button: {
      float: 'left',
      margin: '0 24px 16px 0',
      backgroundColor: '#FFFFFF',
      textTransform: 'none',
      // hover
      '&:hover': {
        backgroundColor: '#FFFFFF',
      },
    },
    tableIcons: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: 24,
      flexWrap: 'wrap',
    },
    selectPaginationSize: {
      marginRight: '8px',
      borderRadius: 12,
    },
    fileTypeFilterStyle: {
      minWidth: 210,
      marginTop: 6,
    },
    orderDateStyle: {
      minWidth: 130,
      marginTop: 6,
    },
    deployIdFilterStyle: {
      minWidth: 450,
      borderRadius: 12,
      marginRight: 8,
    },
    cell: {
      size: 'small',
      whiteSpace: 'nowrap',
    },
    cellwrap: {
      size: 'small',
      whiteSpace: 'normal',
    },
    selectedItems: {
      fontSize: 14,
      color: theme.palette.text.secondary,
    },
    detailIcon: {
      // size of the icon has to be smaller than the row height
      padding: 0,
    },
    detailFooter: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      backgroundColor: 'white',
      boxShadow: theme.shadows[3],
      padding: theme.spacing(2),
      border: `1px solid ${theme.palette.divider}`,
      zIndex: 1300,
      [theme.breakpoints.up('md')]: {
        marginLeft: 279,
      },
      height: '55%',
      // if it's mobile, the height is 100% of the screen
      [theme.breakpoints.down('sm')]: {
        height: '80%',
      },
      // add and scroll to the asset detail footer
      overflow: 'auto',
    },
    titleDivide: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    detailTitleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
    },
    detailFooterContainer: {
      with: '80%',
    },
    filters: {
      marginBottom: theme.spacing(2),
    },
    formControl: {
      minWidth: 250,
    },
    linearProgress: {
      width: '100%',
      marginBottom: 12,
    },
    // align the button to the bottom right
    buttonRefresh: {
      marginTop: 12,
      marginRight: 24,
      float: 'right',
    },
    scheduledTab: {
      backgroundColor: 'orange',
      color: 'black',
    },
    okTab: {
      backgroundColor: 'green',
      color: 'white',
    },
    executingTab: {
      backgroundColor: 'blue',
      color: 'white',
    },
    regularTab: {
      backgroundColor: 'transparent',
      color: '#000',
    },
  }));

  const { project } = useProject();

  const [responseTabValue, setResponseTabValue] = useState(0);
  const [selectedRequests, setSelectedRequests] = useState([]);
  const openSnackBar = useSnackbar();
  const [allChecked, setAllChecked] = useState(false);
  const [indeterminateCheck, setIndeterminateCheck] = useState(false);
  const [selectedReqId, setSelectedReqId] = useState(null);
  const tableHeaderItems = ['Req Id', 'Last Change', 'Status', ''];
  const [deviceSerial, setDeviceSerial] = useState('');
  const [searchMode, setSearchMode] = useState('Device Serial');
  const [reqIdSearchValue, setReqIdSearchValue] = useState('');

  /** QUERY FOR SRFREQUESTS */
  const getRequests = async () => GraphqlService.getSRFRequests({
    projectId: project?.code,
    machineId: deviceSerial,
  });

  const {
    data: dataFetchingRequests,
    isInitialLoading: isInitialLoadingSRFRequests,
    isRefetching: isRefetchingSRFRequests,
    refetch,
  } = useQuery(['deviceRequests'], () => getRequests(), {
    enabled: false,
    cacheTime: 0,
  });

  /** QUERY FOR EXECUTEREQUEST */
  const getSRFRequest = async () => GraphqlService.getSRFRequest({
    projectId: project?.code,
    reqId: selectedReqId || reqIdSearchValue,
  });

  const {
    data: SRFRequestData,
    refetch: refetchSRFRequest,
    isInitialLoading: isInitialLoadingSRFRequest,
    isRefetching: isRefetchingSRFRequest,
  } = useQuery(['SRFrequest', project?.code], getSRFRequest, {
    enabled: !!project?.code && (!!selectedReqId || !!reqIdSearchValue),
    cacheTime: 0,
  });

  const isLoadingSRFRequests = isInitialLoadingSRFRequests || isRefetchingSRFRequests;

  const isLoadingSRFRequest = isInitialLoadingSRFRequest || isRefetchingSRFRequest;

  const cancelRequestMutation = useMutation((variables) => GraphqlService.cancelSRF(variables.projectId, variables.reqId));

  async function cancelRequest(projectId, reqId) {
    try {
      await cancelRequestMutation.mutateAsync({ projectId, reqId });
      openSnackBar(`The request ${reqId} has been cancelled`, 'success');
      setSelectedReqId(null);
      refetch();
    } catch (error) {
      openSnackBar('Error cancelling request', 'error');
    }
  }

  async function cancelAllRequests() {
    try {
      await Promise.all(selectedRequests.map((item) => cancelRequest(project?.code, item.functionId)));
      setSelectedRequests([]);
      refetch();
      openSnackBar('Requests cancelled successfully', 'success');
    } catch (error) {
      openSnackBar('Error cancelling requests', 'error');
    }
  }

  const classes = useStyles();

  const handleSRFRequest = () => {
    if (selectedReqId) refetchSRFRequest();
  };

  const handleSRFCancelRequest = () => {
    cancelRequest(project?.code, selectedReqId);
    refetch();
  };

  const handleSelectAllClick = () => {
    if (dataFetchingRequests?.length === selectedRequests?.length) {
      setSelectedRequests([]);
      setAllChecked(false);
    } else {
      const newSelecteds = dataFetchingRequests?.map((n) => n);
      setSelectedRequests(newSelecteds);
      setAllChecked(true);
    }
    setIndeterminateCheck(false);
  };

  const handleClick = (event, item) => {
    const selectedIndex = selectedRequests?.indexOf(item);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRequests, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRequests.slice(1));
    } else if (selectedIndex === selectedRequests?.length - 1) {
      newSelected = newSelected.concat(selectedRequests.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRequests.slice(0, selectedIndex),
        selectedRequests.slice(selectedIndex + 1),
      );
    }

    if (newSelected?.length === dataFetchingRequests?.length) {
      setAllChecked(true);
      setIndeterminateCheck(false);
    } else if (newSelected?.length === 0) {
      setAllChecked(false);
      setIndeterminateCheck(false);
    } else {
      setIndeterminateCheck(true);
      setAllChecked(false);
    }

    setSelectedRequests(newSelected);
  };

  const isSelected = (item) => selectedRequests?.find((b) => b.functionId === item.functionId);

  const handleDetailClick = (item) => {
    setSelectedReqId(item.functionId);
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(0, 0, 0, 0.02)',
      },
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
      // selected
      '&$selected': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
  }))(TableRow);

  const handleResponseTabChange = (event, newValue) => {
    setResponseTabValue(newValue);
  };

  // Function to determine the class based on the status
  const getStatusTabClass = (status) => {
    switch (String(status).toLocaleUpperCase()) {
      case 'SCHEDULED':
        return classes.scheduledTab;
      case 'OK':
        return classes.okTab;
      case 'EXECUTING':
        return classes.executingTab;
      default:
        return classes.regularTab;
    }
  };

  const handleSearch = () => {
    setAllChecked(false);
    if (searchMode === 'Device Serial') {
      setSelectedReqId(null);
      refetch();
    } else {
      setDeviceSerial('');
      setSelectedReqId(reqIdSearchValue);
    }
  };

  const handleSelectSearchMode = (event) => {
    setSearchMode(event.target.value);
  };

  // USE EFFECTS
  useEffect(() => {
    refetchSRFRequest();
  }, [selectedReqId, refetchSRFRequest]);

  // BEGIN THE RENDERING

  return (
    <Box id="request-manager">
      <PageBanner title="REQUEST MANAGER" />
      <>

        <Grid item xs={12} className={classes.separate}>

          {/* Search Mode Selector */}
          <Grid container className={classes.filters} spacing={2}>
            <Grid item sm="auto">
              <Select
                id="search-selector"
                value={searchMode}
                onChange={handleSelectSearchMode}
                variant="outlined"
                style={{ minWidth: 150 }}
              >
                <MenuItem value="Device Serial">Device Serial</MenuItem>
                <MenuItem value="Request ID">Request ID</MenuItem>
              </Select>
            </Grid>

            {/* Search Input Field */}
            {searchMode === 'Device Serial' && (
              <Grid item sm="auto">
                <TextField
                  id="device-serial"
                  className={classes.formControl}
                  value={deviceSerial}
                  onChange={(e) => setDeviceSerial(e.target.value)}
                  label="Device Serial"
                  variant="outlined"
                  style={{ minWidth: 300 }}
                />
              </Grid>
            )}

            {searchMode === 'Request ID' && (
              <Grid item sm="auto">
                <TextField
                  className={classes.formControl}
                  value={reqIdSearchValue}
                  onChange={(e) => setReqIdSearchValue(e.target.value)}
                  label="Request ID"
                  variant="outlined"
                  style={{ minWidth: 300 }}
                />
              </Grid>
            )}

            {/* Search Button */}
            <Grid item sm="auto" style={{ alignSelf: 'center' }}>
              <SearchButton
                id="search-button"
                onClick={handleSearch}
                disabled={isLoadingSRFRequests}
                className={classes.bottomRightButton}
              >
                SEARCH
              </SearchButton>
            </Grid>
          </Grid>

          <Box>
            <Typography className={classes.selectedItems}>
              {`${selectedRequests?.length || 0} requests selected`}
            </Typography>
          </Box>
          {isLoadingSRFRequests ? (<LinearProgress className={classes.linearProgress} />) : null}
          <TableContainer component={Paper}>
            <Table aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      indeterminate={indeterminateCheck}
                      checked={allChecked}
                      onChange={handleSelectAllClick}
                      className={classes.headerCheckbox}
                    />
                  </StyledTableCell>
                  {tableHeaderItems?.map((head) => <StyledTableCell key={head}>{head}</StyledTableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataFetchingRequests?.map((item) => {
                  const isItemSelected = isSelected(item);
                  return (
                    <StyledTableRow
                      key={item.releaseID}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      selected={isItemSelected}
                    >
                      <StyledTableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} onClick={(event) => handleClick(event, item)} />
                      </StyledTableCell>
                      <StyledTableCell id={`${item.releaseID}-1`} component="td" className={classes.cellwrap}>
                        {item.functionId}
                      </StyledTableCell>
                      <StyledTableCell id={`${item.releaseID}-2`} component="td" className={classes.cell}>
                        {moment.unix(item.lastChange).format('DD/MM/YYYY HH:mm:ss')}
                      </StyledTableCell>
                      <StyledTableCell id={`${item.releaseID}-3`} component="td" className={classes.cell}>
                        <Chip
                          className={getStatusTabClass(item?.status)}
                          variant="outlined"
                          label={item.status}
                        />
                      </StyledTableCell>
                      <StyledTableCell id={`${item.releaseID}-4`}>
                        <IconButton onClick={() => handleDetailClick(item)} className={classes.detailIcon}>
                          <VisibilityIcon />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={4}>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      onClick={() => cancelAllRequests()}
                      disabled={selectedRequests?.length === 0}
                      className={classes.button}
                    >
                      {selectedRequests?.length === 1 ? 'Cancel Request' : 'Cancel Requests'}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>

          </TableContainer>

        </Grid>

        {isLoadingSRFRequest && selectedReqId ? (<LinearProgress className={classes.linearProgress} />)
          : selectedReqId && SRFRequestData && (
            <motion.div
              key="selectedDetail"
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.1 }}
            >
              <Box
                bgcolor="#F5E6CA" // Yellow cake background
                borderRadius={4}
                p={2}
                width="100%"
                position="relative"
              >
                <Box className={classes.detailTitleContainer}>
                  <IconButton className={classes.closeButton} onClick={() => setSelectedReqId(null)}>
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h4" style={{ marginBottom: '12px' }}>
                    {selectedReqId}
                    {' - '}
                    {moment.unix(SRFRequestData?.payload.lastChange).format('DD/MM/YYYY HH:mm:ss')}
                  </Typography>
                </Box>

                <Divider className={classes.titleDivide} />
                {/* **Render the detail of the selected bundle** */}

                <Tabs value={responseTabValue} onChange={handleResponseTabChange} aria-label="dynamic status tabs">
                  <Tab
                    label="Response"
                    className={getStatusTabClass(SRFRequestData.status)}
                  />

                </Tabs>

                <Box p={3} hidden={responseTabValue !== 0}>
                  <Grid container display="flex" spacing={2}>
                    <Grid item md={4}>
                      <InputLabel htmlFor="responseEncodedToken">
                        Encoded Token
                      </InputLabel>
                      <textarea
                        id="responseEncodedToken"
                        value={SRFRequestData?.encodedToken}
                        rows="15"
                        cols="50"
                        disabled
                        style={{ width: '100%', padding: '8px', marginTop: '8px' }}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <InputLabel htmlFor="responseHeader">
                        Decoded Header
                      </InputLabel>
                      <textarea
                        id="responseHeader"
                        value={SRFRequestData?.header}
                        rows="15"
                        cols="50"
                        disabled
                        style={{ width: '100%', padding: '8px', marginTop: '8px' }}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <InputLabel htmlFor="responseHeader">
                        Decoded Payload
                      </InputLabel>
                      <textarea
                        id="responsePayload"
                        value={JSON.stringify(SRFRequestData?.payload, null, 2)}
                        rows="15"
                        cols="50"
                        disabled
                        style={{ width: '100%', padding: '8px', marginTop: '8px' }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box p={3} hidden={responseTabValue !== 1}>
                  <Grid container display="flex" spacing={2}>
                    <Grid item md={4}>
                      <textarea
                        id="responsePayloadResults"
                        value={JSON.stringify(SRFRequestData?.payload?.results, null, 2)}
                        rows="15"
                        cols="50"
                        disabled
                        style={{ width: '100%', padding: '8px', marginTop: '8px' }}
                      />
                    </Grid>
                  </Grid>
                </Box>

              </Box>

              <Box display="flex" justifyContent="space-between" p={2}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#C82319', color: 'white' }}
                  onClick={handleSRFCancelRequest}
                  startIcon={<ClearIcon />}
                  className={classes.buttonRefresh}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSRFRequest}
                  startIcon={<CachedIcon />}
                  className={classes.buttonRefresh}
                >
                  Refresh
                </Button>
              </Box>

            </motion.div>
          )}
      </>

    </Box>
  );
}
