/* eslint-disable react/jsx-props-no-spreading */
import { Button, makeStyles } from '@material-ui/core';
import { React } from 'react';

export default function BannerStyledButton(props) {
  const useStyles = makeStyles((theme) => ({
    bannerStyledButton: {

      borderRadius: 12,
      boxShadow: theme.customShadows.regularShadow,
      color: theme.palette.common.black,
      marginLeft: theme.spacing(3),

    },
  }));

  const classes = useStyles();
  return (<Button {...props} className={classes.bannerStyledButton} color="secondary" variant="contained" />);
}
