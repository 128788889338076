/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Grid, Box, LinearProgress, makeStyles, Checkbox, MenuItem,
  Table, TableBody, TableContainer, TableFooter, TableRow, Chip,
  Paper, TableCell, TableHead, withStyles, Button, IconButton, TextField,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import {
  React, useEffect, useState,
} from 'react';
import { useQuery } from '@tanstack/react-query';
import BlockIcon from '@material-ui/icons/Block';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh'; // Import the refresh icon
import FilterListIcon from '@material-ui/icons/FilterList';
import PageBanner from '../../components/utils/PageBanner';
import SimpleAlert from '../../components/utils/SimpleAlert';
// Local imports
import { useProject } from '../../config/ProjectContext';
import GraphqlService from '../../service/graphqlService';
import YesNoDialog from '../../components/utils/YesNoDialog';
import { useSnackbar } from '../../providers/SnackbarContext';
import MessageCard from '../../components/utils/MessageCard';
import { stringHasValue } from '../../utils/stringHasValue';
// Import the BundleWizard component
function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  index: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
};

export default function QuotaManagement() {
  const useStyles = makeStyles((theme) => ({
    separate: {
      padding: 24,
    },
    headerTabPanel: {
      height: 48,
      backgroundColor: theme.palette.background.paper,
    },
    pagination: {
      background: theme.palette.background.paper,
      borderRadius: 12,
      boxShadow: theme.customShadows.regularShadow,
      color: theme.palette.primary.text,
      float: 'right',
      padding: 0,
      width: '100%',
    },
    table: {
      marginTop: theme.spacing(2),
    },
    labelHeaderTabPanel: {
      color: theme.palette.primary.subMain,
      fontFamily: theme.typography.h3.fontFamily,
      fontSize: '16px',
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: '0',
      lineHeight: '19px',
      textAlign: 'center',
      width: '720px',
      height: '48px',
    },
    background: {
      backgroundColor: theme.palette.background.typography,
    },
    button: {
      float: 'left',
      margin: '0 24px 16px 0',
      backgroundColor: '#FFFFFF',
      textTransform: 'none',
      // hover
      '&:hover': {
        backgroundColor: '#FFFFFF',
      },
    },
    tableIcons: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: 24,
      flexWrap: 'wrap',
    },
    selectPaginationSize: {
      marginRight: '8px',
      borderRadius: 12,
    },
    fileTypeFilterStyle: {
      minWidth: 210,
      marginTop: 6,
    },
    orderDateStyle: {
      minWidth: 130,
      marginTop: 6,
    },
    deployIdFilterStyle: {
      minWidth: 450,
      borderRadius: 12,
      marginRight: 8,
    },
    cell: {
      size: 'small',
      whiteSpace: 'nowrap',
    },
    cellwrap: {
      size: 'small',
      whiteSpace: 'normal',
    },
    selectedItems: {
      fontSize: 14,
      color: theme.palette.text.secondary,
    },
    detailIcon: {
      // size of the icon has to be smaller than the row height
      padding: 0,
    },
    detailFooter: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      backgroundColor: 'white',
      boxShadow: theme.shadows[3],
      padding: theme.spacing(2),
      border: `1px solid ${theme.palette.divider}`,
      zIndex: 1300,
      [theme.breakpoints.up('md')]: {
        marginLeft: 279,
      },
      height: '55%',
      // if it's mobile, the height is 100% of the screen
      [theme.breakpoints.down('sm')]: {
        height: '80%',
      },
      // add and scroll to the asset detail footer
      overflow: 'auto',
    },
    titleDivide: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    detailTitleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
    },
    detailFooterContainer: {
      with: '80%',
    },
    formControl: {
      minWidth: 250,
    },
    linearProgress: {
      width: '100%',
      marginBottom: 12,
    },
    // align the button to the bottom right
    buttonRefresh: {
      marginTop: 12,
      marginRight: 24,
      float: 'right',
    },
    greenTab: {
      backgroundColor: '#c2fcc2',
      color: 'black',
      width: 150,
    },
    orangeTab: {
      backgroundColor: '#fdd07e',
      color: 'black',
      width: 150,
    },
    redTab: {
      backgroundColor: '#f5a5a5',
      color: 'black',
      width: 150,
    },
    regularTab: {
      backgroundColor: 'transparent',
      color: '#000',
      width: 150,
    },
    addButton: {
      backgroundColor: theme.palette.primary.button,
      color: theme.palette.common.white,
      padding: '8px 16px',
      borderRadius: 12,
      '& :disabled': {
        color: theme.palette.primary.light,
      },
    },
  }));

  const { project } = useProject();
  const [selectedRequests, setSelectedRequests] = useState([]);
  const openSnackBar = useSnackbar();

  // USE STATES
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertHeader, setAlertHeader] = useState('');
  const [alertId, setAlertId] = useState('');
  const [alertText, setAlertText] = useState('');
  const [yesNoDialogOpen, setYesNoDialogOpen] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [indeterminateCheck, setIndeterminateCheck] = useState(false);
  const tableHeaderItems = ['User ID', 'Description', 'Added On', 'Remaining Quota', ''];
  const [userIdToAdd, setUserIdToAdd] = useState();
  const [descriptionToAdd, setDescriptionToAdd] = useState();
  const [labelButtonAddOrUpdate, setLabelButtonAddOrUpdate] = useState('ADD');
  const [showFilters, setShowFilters] = useState(false);
  const [filterUserId, setFilterUserId] = useState('');
  const [filterDescription, setFilterDescription] = useState('');

  /* cachedUsersIds contains an array of the cached users.
   Each user is an object with the properties
   userId, description, lastChange (DD/MM/YYYY HH:mm:ss), quota, projectCode */
  const [cachedUsersIds, setCachedUsersIds] = useState([]);

  const [selectedQuotas, setSelectedQuotas] = useState([]);

  const quotaOptions = [
    { value: 'green', label: 'Green', color: '#c2fcc2' },
    { value: 'orange', label: 'Orange', color: '#fdd07e' },
    { value: 'red', label: 'Red', color: '#f5a5a5' },
  ];

  const handleQuotaChange = (event) => {
    const quota = event.target.value;

    // If the quota is not already selected, add it to the selectedQuotas array
    if (!selectedQuotas.includes(quota)) {
      setSelectedQuotas((prevSelectedQuotas) => [...prevSelectedQuotas, quota]);
    }
  };

  const handleDeleteQuota = (quotaToDelete) => {
    setSelectedQuotas((prevSelectedQuotas) => prevSelectedQuotas.filter((quota) => quota !== quotaToDelete));
  };

  function openAlert(header, text, id) {
    setAlertHeader(header);
    setAlertText(text);
    setAlertOpen(true);
    setAlertId(id);
  }

  /* QUERY FOR getCertificateQuota for one userId
   The response will be a json with the following structure:
    {
    "quota": 100,
      "restrictedUser": "Description of the user"
    }
   */
  const getCertificateQuota = async () => GraphqlService.getCertificateQuota(
    userIdToAdd,
  );

  const clearFilters = () => {
    setFilterUserId('');
    setFilterDescription('');
  };

  const clearInputs = () => {
    setUserIdToAdd('');
    setDescriptionToAdd('');
    setLabelButtonAddOrUpdate('ADD');
  };

  const {
    data: dataFetchingCertificateQuota,
    error: errorFetchingCertificateQuota,
    isRefetching: isRefetchingCertificateQuota,
    refetch: refetchCertificateQuota,
  } = useQuery(['CertificateQuota'], () => getCertificateQuota(), {
    enabled: stringHasValue(userIdToAdd),
    cacheTime: 0,
  });

  async function hideUser(projectId, userId) {
    try {
      // Delete the user from local storage
      const cachedUsers = JSON.parse(localStorage.getItem('cachedUsers')) || [];
      const updatedUsers = cachedUsers.filter((user) => user.userId !== userId);

      // Save the updated user list back to local storage
      localStorage.setItem('cachedUsers', JSON.stringify(updatedUsers));

      // Show a success message
      openSnackBar(`User ${userId} has been successfully hidden`, 'success');

      // Refresh the table
      setCachedUsersIds(updatedUsers.filter((user) => user.projectCode === projectId));
    } catch (error) {
      openAlert('Error', `Error hiding user: ${error.message}`, 'closeAlertCancelRequestError');
    }
  }

  async function hideAllUsers() {
    try {
      await Promise.all(selectedRequests.map((item) => hideUser(project?.code, item.userId)));
      setSelectedRequests([]);
      refetchCertificateQuota();
      openSnackBar('Requests cancelled successfully', 'success');
    } catch (error) {
      openAlert('Error', `Error cancelling requests ${JSON.stringify(error)}`, 'closeAlertCancelRequestsError');
    }
  }

  const classes = useStyles();

  const handleRefreshAllData = async () => {
    try {
      const updatedUsers = await Promise.all(
        cachedUsersIds.map(async (user) => {
          // Call API to get the updated quota for each user
          const response = await GraphqlService.getCertificateQuota(user.userId);

          return {
            ...user,
            quota: response.quota,
            lastChange: new Date().toLocaleString(), // Update the last change timestamp
          };
        }),
      );

      // Update local storage with new data
      localStorage.setItem('cachedUsers', JSON.stringify(updatedUsers));

      // Show a message with the result of the operation
      openSnackBar('All data refreshed successfully', 'success');

      // Refresh the table
      setCachedUsersIds(updatedUsers);
    } catch (error) {
      openAlert('Error', `Error refreshing data: ${error.message}`, 'refreshError');
    }
  };

  const handleSelectAllClick = () => {
    if (selectedRequests?.length === cachedUsersIds?.length) {
      setSelectedRequests([]);
      setAllChecked(false);
      setIndeterminateCheck(false);
    } else {
      setSelectedRequests(cachedUsersIds);
      setAllChecked(true);
      setIndeterminateCheck(false);
    }
  };

  const handleClick = (event, item) => {
    const selectedIndex = selectedRequests?.findIndex((b) => b.userId === item.userId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRequests, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRequests.slice(1));
    } else if (selectedIndex === selectedRequests?.length - 1) {
      newSelected = newSelected.concat(selectedRequests.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRequests.slice(0, selectedIndex),
        selectedRequests.slice(selectedIndex + 1),
      );
    }

    if (newSelected?.length === cachedUsersIds?.length) {
      setAllChecked(true);
      setIndeterminateCheck(false);
    } else if (newSelected?.length === 0) {
      setAllChecked(false);
      setIndeterminateCheck(false);
    } else {
      setIndeterminateCheck(true);
      setAllChecked(false);
    }

    setSelectedRequests(newSelected);
  };

  const isSelected = (item) => selectedRequests?.find((b) => b.userId === item.userId);

  const handleHiddeUser = (userId) => {
    hideUser(project?.code, userId);
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(0, 0, 0, 0.02)',
      },
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
      // selected
      '&$selected': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
  }))(TableRow);

  const getQuotaColor = (quota) => {
    if (quota <= 5000) {
      return classes.redTab;
    } if (quota > 5000 && quota < 10000) {
      return classes.orangeTab;
    } if (quota >= 10000) {
      return classes.greenTab;
    }
    return classes.regularTab;
  };

  // Filtering logic
  const filteredUsers = cachedUsersIds.filter((user) => {
    const matchesUserId = !filterUserId || user.userId.includes(filterUserId);
    const matchesDescription = !filterDescription || user.description.includes(filterDescription);

    // Check if user's quota color matches any of the selected quotas
    const userQuotaColor = getQuotaColor(user.quota);
    const matchesQuota = selectedQuotas.length === 0 || selectedQuotas.includes(userQuotaColor);

    return matchesUserId && matchesDescription && matchesQuota;
  });

  const handleAddUser = async () => {
    try {
      // Get the certificate quota for the user via the API
      const response = await GraphqlService.getCertificateQuota(userIdToAdd);

      if (!response || response.restrictedUser === false) {
        // If the API does not find the user, display an error message
        openSnackBar('The User ID has not been found.', 'error');
        return;
      }

      const newUser = {
        userId: userIdToAdd,
        description: descriptionToAdd,
        quota: response.quota,
        lastChange: new Date().toLocaleString(), // Add the current date and time
        projectCode: project.code,
      };

      // Get the existing users from local storage
      const cachedUsers = JSON.parse(localStorage.getItem('cachedUsers')) || [];

      // Check if the user already exists
      const existingUserIndex = cachedUsers.findIndex((user) => user.userId === userIdToAdd);

      if (existingUserIndex !== -1) {
        // Update the existing user's data
        cachedUsers[existingUserIndex] = newUser;
      } else {
        // Add the new user to the list
        cachedUsers.push(newUser);
      }

      // Save the updated user list to local storage
      localStorage.setItem('cachedUsers', JSON.stringify(cachedUsers));

      // Show success message
      openSnackBar('User added successfully', 'success');

      // Refresh the table
      setCachedUsersIds(cachedUsers.filter((user) => user.projectCode === project.code));

      // Clear the input fields
      clearInputs();
    } catch (error) {
      openAlert('Error', `Error adding user: ${error.message}`, 'addUserError');
    }
  };

  const onChangeUserIdToAdd = (value) => {
    setUserIdToAdd(value);
    // if exists change the label of the button to update, if not put the add label to the button
    const cachedUsers = JSON.parse(localStorage.getItem('cachedUsers')) || [];
    const existingUserIndex = cachedUsers.findIndex((user) => user.userId === value);
    if (existingUserIndex !== -1) {
      setLabelButtonAddOrUpdate('UPDATE');
    } else {
      setLabelButtonAddOrUpdate('ADD');
    }
  };

  useEffect(() => {
    // Get all users from local storage for the selected project
    const cachedUsers = JSON.parse(localStorage.getItem('cachedUsers')) || [];

    // Filter users based on the current project and update state
    setCachedUsersIds(cachedUsers.filter((user) => user.projectCode === project.code));
  }, [project]);

  // BEGIN THE RENDERING

  if (!project || dataFetchingCertificateQuota?.length === 0) {
    return <MessageCard message="No requests retrieved" />;
  }

  const handleShowHiddeFilters = () => {
    setShowFilters(!showFilters);
    clearFilters();
  };

  return (
    <Box id="quota-management">
      <PageBanner title="QUOTA MANAGEMENT" />
      <>
        <YesNoDialog
          open={yesNoDialogOpen}
          setOpen={setYesNoDialogOpen}
          header="Cancel all requests"
          body="Are you sure you want to cancel all selected requests?"
          action={() => hideUser()}
          actionName="Yes"
          id="confirmationRequestDeploy"
        />
        <SimpleAlert open={alertOpen} setOpen={setAlertOpen} header={alertHeader} body={alertText} id={alertId} />

        <Grid item xs={12} className={classes.separate}>
          <Box style={{ marginBottom: 28 }}>
            <Typography variant="h7">
              Please, add your User ID to see their related data
            </Typography>
          </Box>

          <Grid container className={classes.filters} spacing={2}>
            <Grid item sm="auto">
              <TextField
                id="user-id"
                onChange={(event) => onChangeUserIdToAdd(event.target.value)}
                value={userIdToAdd}
                label="User ID"
                variant="outlined"
                style={{ minWidth: 300 }}
              />
            </Grid>
            <Grid item sm="auto">
              <TextField
                id="description-to-add"
                label="Description"
                onChange={(event) => setDescriptionToAdd(event.target.value)}
                value={descriptionToAdd}
                variant="outlined"
                style={{ minWidth: 400 }}
              />
            </Grid>
            <Grid item sm="auto" style={{ alignSelf: 'center' }}>
              <Button
                id="btnTelemetrySearch"
                onClick={handleAddUser}
                className={classes.addButton}
                variant="contained"
                color="secondary"
                disabled={!stringHasValue(userIdToAdd) || isRefetchingCertificateQuota}
                startIcon={<AddIcon />}
              >
                {labelButtonAddOrUpdate}
              </Button>
            </Grid>

          </Grid>

          {/* table counter and icons */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Box>
              <Typography className={classes.selectedItems} style={{ marginBottom: 8 }}>
                {`${selectedRequests?.length || 0} users selected`}
              </Typography>
            </Box>

            <Box>
              <IconButton onClick={handleRefreshAllData} title="Refresh All Data">
                <RefreshIcon />
              </IconButton>
              <IconButton
                onClick={handleShowHiddeFilters}
                title="Show Filters"
                style={{
                  backgroundColor: showFilters ? '#e0e0e0' : 'transparent', // Change background when filters are shown
                  borderRadius: '50%',
                }}
              >
                <FilterListIcon />
              </IconButton>
            </Box>
          </Box>

          {/* TODO
          */}

          {showFilters ? (

            <Box display="flex" flexDirection="column" mb={2} p={4} style={{ backgroundColor: '#e0e0e0', borderRadius: 10 }}>
              <Box style={{ marginBottom: 28 }}>
                <Typography variant="h7">
                  Add filters to search for specific users
                </Typography>
              </Box>
              {/* User ID Filter */}
              <Box mb={2}>
                <TextField
                  id="filterUserId"
                  label="User ID"
                  value={filterUserId}
                  onChange={(event) => setFilterUserId(event.target.value)}
                  variant="outlined"
                  style={{ minWidth: 400 }}
                />
              </Box>

              {/* Description Filter */}
              <Box mb={2}>
                <TextField
                  id="filterDescription"
                  label="Description"
                  value={filterDescription}
                  onChange={(event) => setFilterDescription(event.target.value)}
                  variant="outlined"
                  style={{ minWidth: 400 }}
                />
              </Box>

              {/* Quota Filter */}
              {/* Quota Filter using Chips */}
              <Box mb={2}>

                {/* Quota selector */}
                <TextField
                  id="quota-selector"
                  select
                  label="Select Quota"
                  variant="outlined"
                  onChange={handleQuotaChange}
                  value="" // Keeps the selector "reset" after each selection
                  style={{ minWidth: 150, marginBottom: 16 }}
                >
                  {quotaOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <div
                        style={{
                          backgroundColor: option.color,
                          height: '30px',
                          width: '100%',
                          borderRadius: 4,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {option.label}
                      </div>
                    </MenuItem>
                  ))}
                </TextField>

                {/* Render selected quotas as chips */}
                <Box mt={2}>
                  {selectedQuotas.map((quota) => {
                    const selectedOption = quotaOptions.find((q) => q.value === quota);

                    return (
                      <Chip
                        key={quota}
                        label={selectedOption.label}
                        style={{
                          backgroundColor: selectedOption.color, color: '#000', marginRight: 8, marginBottom: 8,
                        }}
                        onDelete={() => handleDeleteQuota(quota)}
                      />
                    );
                  })}
                </Box>
              </Box>

            </Box>
          ) : null}

          <TableContainer component={Paper}>
            <Table aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      indeterminate={indeterminateCheck}
                      checked={allChecked}
                      onChange={handleSelectAllClick}
                      className={classes.headerCheckbox}
                    />
                  </StyledTableCell>
                  {tableHeaderItems?.map((head) => <StyledTableCell key={head}>{head}</StyledTableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers?.filter((item) => item.projectCode === project?.code).map((item) => {
                  const isItemSelected = isSelected(item);
                  return (
                    <StyledTableRow
                      key={item.userId}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      selected={isItemSelected}
                    >
                      <StyledTableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} onClick={(event) => handleClick(event, item)} />
                      </StyledTableCell>
                      <StyledTableCell id={`${item.userId}-0`} component="td" className={classes.cellwrap}>
                        {item.userId}
                      </StyledTableCell>
                      <StyledTableCell id={`${item.userId}-1`} component="td" className={classes.cellwrap}>
                        {item.description}
                      </StyledTableCell>
                      <StyledTableCell id={`${item.userId}-2`} component="td" className={classes.cell}>
                        {item.lastChange}
                      </StyledTableCell>
                      <StyledTableCell id={`${item.userId}-3`} component="td" className={classes.cell}>
                        <Chip
                          className={getQuotaColor(item?.quota)}
                          variant="outlined"
                          label={new Intl.NumberFormat('de-DE').format(item.quota)} // Format the number with comma separator
                        />
                      </StyledTableCell>
                      <StyledTableCell id={`${item.releaseID}-4`}>
                        <IconButton onClick={() => handleHiddeUser(item.userId)} className={classes.detailIcon}>
                          <BlockIcon />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={4}>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<BlockIcon />}
                      onClick={() => hideAllUsers()}
                      disabled={selectedRequests?.length === 0}
                      className={classes.button}
                    >
                      {selectedRequests?.length === 1 ? 'Hide User' : 'Hide Users'}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>

          </TableContainer>
        </Grid>

      </>

    </Box>
  );
}
