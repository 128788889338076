/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card, CardContent, Grid, Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: 24,
  },
  cardsTypography: {
    color: theme.palette.primary.text,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 16,
    textAlign: 'center',
  },
}));

export default function MessageCard(params) {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="stretch"
    >
      <Card className={classes.card}>
        <CardContent style={{ padding: 16 }}>
          <Typography className={classes.cardsTypography}>
            {params.message}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
