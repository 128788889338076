/* eslint-disable no-nested-ternary */
import {
  TableCell, TableRow, makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  React,
} from 'react';
import { withStyles } from '@material-ui/core/styles';

// import { formatToPrintableDate } from '../../utils/dateUtils';

const useStyles = makeStyles(() => ({
  cell: {
    size: 'small',
    whiteSpace: 'nowrap',
  },
  cellwrap: {
    size: 'small',
    whiteSpace: 'normal',
  },
}
));

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.form,
    },
  },
}))(TableRow);

export default function BrandConsumptionsListItem({
  consumption, id,
}) {
  // WEBPAGE

  // WEBPAGE______________________________
  const classes = useStyles();

  return (
    <StyledTableRow>
      <StyledTableCell id={`${id}-0`} component="td" className={classes.cellwrap}>
        {consumption.id ? consumption.id : '-'}
      </StyledTableCell>
      <StyledTableCell id={`${id}-1`} component="td" className={classes.cell}>
        {consumption.deviceSerial ? consumption.deviceSerial : '-'}
      </StyledTableCell>
      <StyledTableCell id={`${id}-2`} component="td" className={classes.cell}>
        {consumption.moduleSerial ? consumption.moduleSerial : '-'}
      </StyledTableCell>
      <StyledTableCell id={`${id}-3`} component="td" className={classes.cell}>
        {consumption.countryCode ? consumption.countryCode : '-'}
      </StyledTableCell>
      <StyledTableCell id={`${id}-4`} component="td" className={classes.cell}>
        {consumption.recipeCode ? consumption.recipeCode : '-'}
      </StyledTableCell>
      <StyledTableCell id={`${id}-5`} component="td" className={classes.cell}>
        {consumption.productCode ? consumption.productCode : '-'}
      </StyledTableCell>
      <StyledTableCell id={`${id}-6`} component="td" className={classes.cell}>
        {consumption.customerCode ? consumption.customerCode : '-'}
      </StyledTableCell>
      <StyledTableCell id={`${id}-7`} component="td" className={classes.cell}>
        {consumption.isValidTime ? (consumption.isValidTime ? 'true' : 'false') : '-'}
      </StyledTableCell>
      <StyledTableCell id={`${id}-8`} component="td" className={classes.cell}>
        {consumption.extra ? consumption.extra : '-'}
      </StyledTableCell>
      <StyledTableCell id={`${id}-9`} component="td" className={classes.cell}>
        {consumption.createdOnLocal ? consumption.createdOnLocal : '-'}
      </StyledTableCell>
      <StyledTableCell id={`${id}-10`} component="td" className={classes.cell}>
        {consumption.createdOnUtc}
      </StyledTableCell>
      <StyledTableCell id={`${id}-11`} component="td" className={classes.cell}>
        {consumption.receivedOnUtc}
      </StyledTableCell>
      <StyledTableCell id={`${id}-12`} component="td" className={classes.cell}>
        {consumption.processedOnUtc}
      </StyledTableCell>
      <StyledTableCell id={`${id}-13`} component="td" className={classes.cell}>
        {consumption.dictProduct ? consumption.dictProduct.code : '-'}
      </StyledTableCell>
      <StyledTableCell id={`${id}-14`} component="td" className={classes.cell}>
        {consumption.dictProduct ? consumption.dictProduct.market : '-'}
      </StyledTableCell>
      <StyledTableCell id={`${id}-15`} component="td" className={classes.cell}>
        {consumption.dictProduct ? consumption.dictProduct.name : '-'}
      </StyledTableCell>
      <StyledTableCell id={`${id}-16`} component="td" className={classes.cell}>
        {consumption.dictProduct ? consumption.dictProduct.insertedOn : '-'}
      </StyledTableCell>
      <StyledTableCell id={`${id}-17`} component="td" className={classes.cell}>
        {consumption.dictProduct ? consumption.dictProduct.updatedOn : '-'}
      </StyledTableCell>
      <StyledTableCell id={`${id}-18`} component="td" className={classes.cell}>
        {consumption.dictProduct ? (consumption.dictProduct.isDeleted ? 'true' : 'false') : '-'}
      </StyledTableCell>
      <StyledTableCell id={`${id}-19`} component="td" className={classes.cell}>
        {consumption.dictRecipe ? consumption.dictRecipe.code : '-'}
      </StyledTableCell>
      <StyledTableCell id={`${id}-20`} component="td" className={classes.cell}>
        {consumption.dictRecipe ? consumption.dictRecipe.market : '-'}
      </StyledTableCell>
      <StyledTableCell id={`${id}-21`} component="td" className={classes.cell}>
        {consumption.dictRecipe ? consumption.dictRecipe.name : '-'}
      </StyledTableCell>
      <StyledTableCell id={`${id}-22`} component="td" className={classes.cell}>
        {consumption.dictRecipe ? consumption.dictRecipe.extra : '-'}
      </StyledTableCell>
      <StyledTableCell id={`${id}-23`} component="td" className={classes.cell}>
        {consumption.dictRecipe ? consumption.dictRecipe.insertedOn : '-'}
      </StyledTableCell>
      <StyledTableCell id={`${id}-24`} component="td" className={classes.cell}>
        {consumption.dictRecipe ? consumption.dictRecipe.updatedOn : '-'}
      </StyledTableCell>
      <StyledTableCell id={`${id}-25`} component="td" className={classes.cell}>
        {consumption.dictRecipe ? (consumption.dictRecipe.isDeleted ? 'true' : 'false') : '-'}
      </StyledTableCell>
    </StyledTableRow>
  );
}
BrandConsumptionsListItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  consumption: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};
