import { useContext, useState, useEffect } from 'react';
import AuthorizationContext from './AuthorizationContext';

const usePermission = (permission) => {
  const [loading, setLoading] = useState(true);
  const [allowed, setAllowed] = useState(false);

  const { isAllowedTo } = useContext(AuthorizationContext);

  useEffect(() => {
    let isMounted = true;

    const checkPermissions = async () => {
      try {
        let isAllowed = false;

        if (Array.isArray(permission)) {
          // Map permissions to an array of promises
          const permissionPromises = permission.map((perm) => isAllowedTo(perm));

          // Wait for all permission checks to complete
          const results = await Promise.all(permissionPromises);

          // Check if any permission is allowed
          isAllowed = results.some((result) => result === true);
        } else {
          isAllowed = await isAllowedTo(permission);
        }

        if (isMounted) {
          setAllowed(isAllowed);
          setLoading(false);
        }
      } catch (err) {
        console.debug('isAllowed error', err);
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    checkPermissions();

    // Cleanup function to prevent state updates on unmounted component
    return () => {
      isMounted = false;
    };
  }, [permission, isAllowedTo]);

  return [loading, allowed];
};

export default usePermission;
