/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box, Grid, InputLabel, makeStyles, Radio, FormControlLabel, FormControl, FormLabel, RadioGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { React, useEffect, useState } from 'react';
import { set, useForm } from 'react-hook-form';

import { DEPLOY_TYPE_GLOBAL } from '../../../utils/constants';
import CustomTextArea from '../../utils/CustomTextArea';

const useStyles = makeStyles((theme) => ({
  labelDescription: {
    color: theme.palette.primary.labelDescription,
    fontFamily: theme.typography.h5.fontFamily,
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '19px',
  },
  summaryContent: {
    backgroundColor: theme.palette.background.typography,
  },
  messageGlobal: {
    paddintTop: '22px',
    paddingRight: '24px',
    paddingBottom: '16px',
  },
  iconGlobalFrame: {
    paddingTop: '24px',
    paddingBottom: '22px',
  },
  iconGlobal: {
    height: '72px',
    width: '72px',
  },
  gridBackground: {
    backgroundColor: theme.palette.background.form,
    borderRadius: '12px',
  },
  commentGrid: {
    paddingBottom: '22px',
  },
  exclusionsGroup: {
    alignItems: 'flex-start',
  },
  excludeRadioButtons: {
    paddingTop: '22px',
    paddingLeft: '24px',
    paddingRight: '24px',
  },
}));

export default function GlobalDeploy({
  setType, project, values, setValues, selectedExcludedValue, setSelectedExcludedValue,
  excludedValues, setExcludedValues,
}) {
  // DATA RETRIEVAL __________________________
  async function loadType() {
    setType(DEPLOY_TYPE_GLOBAL);
  }

  useEffect(() => {
    loadType();
  });

  const classes = useStyles();

  const EXCLUDED_VALUES = ['NO-EXCLUDED', 'EXCLUDE-SERIAL', 'EXCLUDE-MARKET'];

  useEffect(() => {
    setExcludedValues('');
    setSelectedExcludedValue(EXCLUDED_VALUES[0]);
  }, []);

  const handleChange = (event) => {
    setExcludedValues('');
    setSelectedExcludedValue(event.target.value);
  };

  const handleTextAreaChange = (text) => {
    if (!text) {
      setExcludedValues('');
      return;
    }
    const valueArray = text.split(',').map((item) => item.trim());
    setExcludedValues(valueArray);
  };

  return (
    <Box>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        className={classes.summaryContent}
      >
        <Grid
          container
          spacing={0}
          direction="row"
          alignItems="flex-start"
          className={classes.summaryContent}
        >
          <Grid item xs={12} className={classes.messageGlobal}>
            <InputLabel className={classes.labelDescription}>
              The deployment will be globally applied to all&nbsp;
              {project.name}
              &nbsp;machines.
            </InputLabel>
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.gridBackground}
        >
          <Grid item xs={12} className={classes.excludeRadioButtons}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Exclusion Options</FormLabel>
              <RadioGroup value={selectedExcludedValue} onChange={handleChange}>
                <Grid container spacing={2}>
                  <Grid item>
                    <FormControlLabel
                      value={EXCLUDED_VALUES[0]}
                      control={<Radio />}
                      label="No exclusions"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      value={EXCLUDED_VALUES[1]}
                      control={<Radio />}
                      label="Exclude Serial Numbers"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      value={EXCLUDED_VALUES[2]}
                      control={<Radio />}
                      label="Exclude Market Locations"
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>

          {
            [EXCLUDED_VALUES[1], EXCLUDED_VALUES[2]].includes(selectedExcludedValue) && (
              <Grid item xs={12}>
                <CustomTextArea
                  screenId="inputExcludedGlobalDeployment"
                  placeHolder="Excluded fields, separated by commas (option1, option2, ...)"
                  size={300}
                  values={excludedValues}
                  setValues={handleTextAreaChange}
                  isComment
                />
              </Grid>
            )
          }

          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            className={classes.commentGrid}
          >
            <Grid
              item
              xs={12}
              spacing={0}
              direction="column"
              container
            >
              <CustomTextArea
                screenId="inputDeploymentComments-globalDeployment"
                title="Deployment description"
                placeHolder="Comments (optional)"
                size={300}
                values={values}
                setValues={setValues}
                isComment
                rows={4}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
GlobalDeploy.propTypes = {
  setType: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  project: PropTypes.object.isRequired,
  values: PropTypes.string.isRequired,
  setValues: PropTypes.func.isRequired,
  selectedExcludedValue: PropTypes.string.isRequired,
  setSelectedExcludedValue: PropTypes.func.isRequired,
  excludedValues: PropTypes.string.isRequired,
  setExcludedValues: PropTypes.func.isRequired,
};
