import React, { useState } from 'react';
import {
  Grid,
  Box,
  TextField,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useSnackbar } from '../../providers/SnackbarContext';
import GraphqlService from '../../service/graphqlService';
import LoadingAnimation from '../../components/LoadingAnimation';
import PageBanner from '../../components/utils/PageBanner';

const NotificationsCenter = () => {
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationDescription, setNotificationDescription] = useState('');
  const [notificationTitleToDelete, setNotificationTitleToDelete] = useState('');
  const [showSendNotifications, setShowSendNotifications] = useState(false);
  const [showDeleteNotifications, setShowDeleteNotifications] = useState(false);

  const openSnackBar = useSnackbar();

  // Load users only when showSendNotifications is true
  const getCognitoUsersGroupsCSV = async () => GraphqlService.getCognitoUsersGroupsCSV();

  const {
    data: csvData,
    error: errorFetching,
    isLoading,
  } = useQuery(['cognitoUsers'], getCognitoUsersGroupsCSV, {
    enabled: showSendNotifications, // Load users only when sending notifications
    cacheTime: 300000, // Cache for 5 minutes
    staleTime: 300000, // Data is considered stale after 5 minutes
    refetchOnWindowFocus: false, // Disable refetching on window focus
    refetchOnReconnect: false, // Disable refetching on reconnect
    refetchInterval: false, // Disable polling
    select: (res) => {
      const rows = res
        .split('\n')
        .slice(1)
        .map((row) => {
          if (!row) {
            return null;
          }
          const [user, createdDate, email, groups] = row.split(',');
          if (!user || !email) {
            return null;
          }
          const parsedGroups = groups
            && groups
              .split(';')
              .map((group) => {
                const parts = group && group.trim().split(':');
                return `${parts[0]}: ${parts[1]}`;
              })
              .join(', ');
          return {
            user,
            createdDate,
            email,
            groups: parsedGroups,
          };
        });
      return { rows, file: res };
    },
  });

  const createNotificationMutation = useMutation((variables) => GraphqlService.createNotification(
    variables.email,
    variables.notificationTitle,
    variables.notificationDescription,
  ));

  const deleteNotificationsByTitleMutation = useMutation((variables) => GraphqlService.deleteNotificationsByTitle(variables.title));

  const sendNotifications = async () => {
    try {
      if (!csvData || !csvData.rows) {
        openSnackBar('No users available to send notifications', 'warning');
        return;
      }
      const usersWithEmail = csvData.rows?.filter((row) => row?.email);
      const promiseNotifications = usersWithEmail.map((user) => createNotificationMutation.mutateAsync({
        email: user.email.trim(),
        notificationTitle,
        notificationDescription,
      }));
      await Promise.all(promiseNotifications);
      // Clean fields
      setNotificationTitle('');
      setNotificationDescription('');
      openSnackBar('Notifications sent successfully', 'success');
    } catch (error) {
      openSnackBar(error.message, 'error');
    }
  };

  const deleteNotifications = async () => {
    try {
      if (!notificationTitleToDelete) {
        openSnackBar('Please enter a notification title to delete', 'warning');
        return;
      }
      await deleteNotificationsByTitleMutation.mutateAsync({
        title: notificationTitleToDelete,
      });
      setNotificationTitleToDelete('');
      openSnackBar('Notifications deleted successfully', 'success');
    } catch (error) {
      openSnackBar(error.message, 'error');
    }
  };

  const handleCancelSend = () => {
    setNotificationTitle('');
    setNotificationDescription('');
    setShowSendNotifications(false);
    openSnackBar('Notification sending canceled', 'info');
  };

  const handleCancelDelete = () => {
    setNotificationTitleToDelete('');
    setShowDeleteNotifications(false);
    openSnackBar('Notification deletion canceled', 'info');
  };

  return (
    <Box>
      <PageBanner title="NOTIFICATION CENTER" />
      <Box m={24}>
        {/* Send Notifications Section */}
        <Box mb={12} mt={12}>
          <Accordion
            expanded={showSendNotifications}
            onChange={() => setShowSendNotifications((prev) => !prev)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Send Notifications</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                {isLoading && (
                  <Grid item xs={12}>
                    <LoadingAnimation title="Loading users to send notifications" />
                  </Grid>
                )}
                {errorFetching && (
                  <Grid item xs={12}>
                    <Typography color="error">
                      Error fetching users:
                      {' '}
                      {errorFetching.message}
                    </Typography>
                  </Grid>
                )}
                {!isLoading && !errorFetching && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        autoFocus
                        margin="dense"
                        label="Notification Title"
                        fullWidth
                        value={notificationTitle}
                        onChange={(e) => setNotificationTitle(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Notification Description"
                        fullWidth
                        multiline
                        rows={4}
                        value={notificationDescription}
                        onChange={(e) => setNotificationDescription(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} container justifyContent="flex-end">
                      <Button
                        id="btnCancelSendNotifications"
                        startIcon={<CancelScheduleSendIcon />}
                        color="primary"
                        variant="contained"
                        onClick={handleCancelSend}
                      >
                        Cancel
                      </Button>
                      <Button
                        id="btnSendNotifications"
                        startIcon={<SendIcon />}
                        color="primary"
                        variant="contained"
                        onClick={sendNotifications}
                        style={{ marginLeft: '10px' }}
                      >
                        Send
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>

        {/* Delete Notifications Section */}
        <Box>
          <Accordion
            expanded={showDeleteNotifications}
            onChange={() => setShowDeleteNotifications((prev) => !prev)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Delete Notifications by Title</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3} alignItems="flex-end">
                <Grid item xs={12}>
                  <TextField
                    label="Notification Title to Delete"
                    fullWidth
                    value={notificationTitleToDelete}
                    onChange={(e) => setNotificationTitleToDelete(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="flex-end">
                  <Button
                    id="btnCancelDeleteNotifications"
                    startIcon={<CancelScheduleSendIcon />}
                    color="primary"
                    variant="contained"
                    onClick={handleCancelDelete}
                  >
                    Cancel
                  </Button>
                  <Button
                    id="btnDeleteNotifications"
                    startIcon={<DeleteIcon />}
                    color="primary"
                    variant="contained"
                    onClick={deleteNotifications}
                    style={{ marginLeft: '10px' }}
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationsCenter;
