/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { motion, AnimatePresence } from 'framer-motion';

const useStyles = makeStyles((theme) => ({
  dialogTitleContainer: {
    backgroundColor: 'red',
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
  },
  dialogContent: {
    padding: theme.spacing(3),
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(2),
  },
  errorText: {
    wordBreak: 'break-word',
  },
}));

function ErrorPopup({
  open, onClose, title, description, error,
}) {
  const classes = useStyles();

  const dialogRef = useRef(null);

  const popupVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 50 },
  };

  const errorVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <AnimatePresence>
      {open && (
        <Dialog
          ref={dialogRef}
          open={open}
          onClose={onClose}
          maxWidth="md"
          fullWidth
          TransitionComponent={React.forwardRef((props, ref) => (
            <motion.div
              ref={ref}
              variants={popupVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              transition={{ duration: 0.3 }}
              {...props}
            />
          ))}
        >
          <DialogTitle className={classes.dialogTitleContainer}>
            {title}
          </DialogTitle>
          <DialogContent dividers className={classes.dialogContent}>
            <Typography variant="body1" className={classes.errorText}>
              {description}
            </Typography>

            <AnimatePresence>
              {error && (
                <motion.div
                  variants={errorVariants}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  transition={{ duration: 0.2 }}
                >
                  <Typography variant="body2" className={classes.errorMessage}>
                    {error}
                  </Typography>
                </motion.div>
              )}
            </AnimatePresence>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary" variant="contained">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </AnimatePresence>
  );
}

ErrorPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  error: PropTypes.string,
};

ErrorPopup.defaultProps = {
  error: '',
};

export default ErrorPopup;
