import { useEffect, useRef } from 'react';
import { API, Auth } from 'aws-amplify';
import { useQueryClient } from '@tanstack/react-query';
import { onUpdateConfig } from '../config/graphql';
import { useSnackbar } from '../providers/SnackbarContext';

const useMaintenanceSubscription = () => {
  const queryClient = useQueryClient();
  const openSnackBar = useSnackbar();
  const subscriptionRef = useRef(null);

  useEffect(() => {
    const subscribeToNotifications = async () => {
      try {
        const subscription = API.graphql({
          query: onUpdateConfig,
          authMode: 'AMAZON_COGNITO_USER_POOLS',
          variables: {
            authToken: Auth.user?.attributes?.sub || Auth.user?.signInUserSession?.accessToken?.payload?.sub,
          },
        }).subscribe({
          next: () => {
            queryClient.invalidateQueries(['config']);
          },
          error: (error) => {
            console.log(`Error setting up subscription to config update: ${error}`, 'error');
          },
        });

        subscriptionRef.current = subscription;
      } catch (error) {
        console.log(`Error setting up subscription to config update: ${error}`, 'error');
      }
    };

    subscribeToNotifications();

    return () => {
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
      }
    };
  }, [queryClient, openSnackBar]);
};

export default useMaintenanceSubscription;
