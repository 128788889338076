/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import {

  makeStyles, ListItem, ListItemText, ListItemIcon, Collapse, List,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useLocation } from 'react-router-dom';
import Restricted from '../../authorization/Restricted';
import LeftBarItem from '../LeftBarItem';
import itemIsSelected from '../utils/SelectedItems';

export default function ExpandableMenuSection({
  name, items, permissions, icon, isActive, onToggle,
}) {
  const [open, setOpen] = React.useState(false);

  const useStyles = makeStyles((theme) => ({
    drawerItemSection: {
      paddingLeft: theme.spacing(4),
      cursor: 'pointer',
    },
    drawerItemIcon: {
      minWidth: 24,
      marginRight: 12,
    },
    drawerItemText: {
      textTransform: 'none',
    },
    activeParent: {
      backgroundColor: '#E2EAF9',
      '&:hover': {
        backgroundColor: '#E2EAF9',
      },
    },
  }));

  const handleClick = () => {
    setOpen(!open);
  };

  const { pathname } = useLocation();

  /// if some of the childs itemIsSelected(path, pathname) is true, then the parent item is selected
  useEffect(() => {
    if (items.filter((item) => itemIsSelected(item.path, pathname)).length > 0) {
      setOpen(true);
    }
  }, [pathname]);

  const classes = useStyles();

  return (
    <Restricted to={permissions}>
      <ListItem button onClick={handleClick} className={isActive ? classes.activeParent : ''}>
        {/* <ListItemIcon className={classes.drawerItemIcon}>{icon}</ListItemIcon> */}
        <ListItemText
          primary={name}
          primaryTypographyProps={{ className: classes.drawerItemText }}
        />
        <ListItemIcon>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </ListItemIcon>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map((item) => (
            <LeftBarItem
              key={item.name}
              name={item.name}
              path={item.path}
              permission={item.permission}
              icon={item.icon}
              onToggle={onToggle}
            />
          ))}
        </List>
      </Collapse>
    </Restricted>
  );
}
ExpandableMenuSection.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  icon: PropTypes.element.isRequired,
  isActive: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};
