/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Grid, Link, Icon, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { ReactComponent as Logo } from '../assets/logo.svg';
import {
  URL_NESTLE, URL_PRIVACY, URL_TERMS, URL_WORKPLACE,
} from '../utils/constants';

export default function Footer() {
  const useStyles = makeStyles((theme) => ({
    footer: {
      alignItems: 'center',
      background: theme.palette.background.footer,
      bottom: 0,
      display: 'flex',
      fontSize: 14,
      gap: 20,
      justifyContent: 'center',
      padding: '12px 0',
      position: 'fixed',
      zIndex: theme.zIndex.drawer + 1,
    },
    icon: {
      height: 32,
      marginRight: 120,
      width: 125,
    },
    link: {
      color: theme.palette.primary.footer,
      '&:hover': {
        color: '#337AB7',
        textDecoration: 'none',
      },
    },
    text: {
      color: theme.palette.primary.footer,
    },
  }));
  const classes = useStyles();

  return (
    <Grid container item xs={12} className={classes.footer}>
      <Icon component={Logo} className={classes.icon} />
      <Link target="_blank" className={classes.link} href={URL_NESTLE}>© 2023 Nestlé</Link>
      <Typography className={classes.text}>|</Typography>
      <Link target="_blank" className={classes.link} href={URL_PRIVACY}>Privacy</Link>
      <Typography className={classes.text}>|</Typography>
      <Link target="_blank" className={classes.link} href={URL_TERMS}>Terms of use</Link>
      <Typography className={classes.text}>|</Typography>
      <Link target="_blank" className={classes.link} href={URL_WORKPLACE}>Workplace link</Link>
    </Grid>
  );
}
