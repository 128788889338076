import { useState } from 'react';
import GraphqlService from '../service/graphqlService';

export default function usePermissionsEndpoint() {
  // Output state
  const [permissions, setPermissions] = useState();
  // Input state
  const [projectId, setProjectId] = useState();

  async function loadPermissions(newProjectId) {
    setProjectId(newProjectId);
    try {
      const fetchedPermissions = await GraphqlService.getPermissions(
        newProjectId,
      );
      setPermissions(fetchedPermissions);
      return fetchedPermissions;
    } catch (err) {
      setPermissions([]);
      return [];
    }
  }

  async function getPermissions(newProjectId) {
    if (!permissions || projectId !== newProjectId) {
      return loadPermissions(newProjectId);
    }
    return permissions;
  }

  return {
    loadPermissions,
    getPermissions,
  };
}
