/* eslint-disable react/destructuring-assignment */
import {
  Box, makeStyles, Paper, Typography,
} from '@material-ui/core';
import PropTypes, { string } from 'prop-types';
import React from 'react';
import BannerStyledButton from './BannerStyledButton';
import Restricted from '../../authorization/Restricted';

export default function PageBanner(props) {
  const useStyles = makeStyles((theme) => ({
    bannerText: {
      float: 'left',
      textAlign: 'left',
      paddingLeft: 24,
      paddingTop: 24,
      paddingBottom: 24,
      color: theme.palette.background.paper,
    },
    bannerButton: {
      paddingRight: 24,
      paddingTop: 16,
      paddingBottom: 16,
    },
    bannerContainer: {
      zIndex: theme.zIndex.appBar - 1,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      background: theme.palette.primary.banner,
    },
    buttonSection: {
      display: 'flex',
      height: '100%',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
      alignItems: 'center',

    },
  }));

  const {
    title, buttons, buttonIcons, buttonDisabled, onClicks, buttonPermission, id,
  } = props;

  function BannerButton(bbProps) {
    return (
      <BannerStyledButton
        id={id}
        startIcon={buttonIcons[bbProps.index]}
        onClick={onClicks[bbProps.index]}
        disabled={buttonDisabled[bbProps.index]}
        style={{
          borderRadius: 12, paddingTop: 8, paddingBottom: 8, color: 'white',
        }}
      >
        {buttons[bbProps.index]}
      </BannerStyledButton>
    );
  }

  const classes = useStyles();

  return (
    <Box className={classes.bannerContainer} component="div" elevation={0}>
      <div className={classes.bannerText}>
        <Typography
          variant="h2"
          style={{
          }}
        >
          {title}
        </Typography>
      </div>
      <div className={classes.buttonSection}>
        {buttons && buttons.map((button, index) => button
          && (
            <div
              key={button}
              className={classes.bannerButton}
            >
              {(button && buttonPermission)
                ? (
                  <Restricted
                    to={[buttonPermission]}
                  >
                    <BannerButton index={index} />
                  </Restricted>
                )
                : ((button) && (
                  <BannerButton />
                ))}
              <Paper elevation={0} />
            </div>
          ))}
      </div>

    </Box>
  );
}
PageBanner.propTypes = {
  title: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/forbid-prop-types
  buttonIcons: PropTypes.arrayOf(PropTypes.object),
  buttonDisabled: PropTypes.arrayOf(PropTypes.bool),
  onClicks: PropTypes.arrayOf(PropTypes.func),
  orderBy: PropTypes.shape({ date: PropTypes.string, published: PropTypes.string }),
  buttonPermission: string,
  id: string,
};
PageBanner.defaultProps = {
  buttons: undefined,
  buttonIcons: undefined,
  buttonDisabled: undefined,
  onClicks: undefined,
  orderBy: undefined,
  buttonPermission: undefined,
  id: undefined,
};
