import React from 'react';
import PropTypes from 'prop-types';
import usePermission from './permissionHook';

const Restricted = ({
  to, fallback, loadingComponent, children,
}) => {
  const [loading, allowed] = usePermission(to);

  if (loading) {
    return <>{loadingComponent}</>;
  }

  if (allowed) {
    return <>{children}</>;
  }

  return <>{fallback}</>;
};

Restricted.propTypes = {
  to: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
  loadingComponent: PropTypes.node,
};

Restricted.defaultProps = {
  fallback: null,
  loadingComponent: null,
  to: [],
};

export default Restricted;
