/* eslint-disable no-unused-vars */
import React, { useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  Typography,
  CircularProgress,
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputLabel,
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useQuery } from '@tanstack/react-query';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory, useRouteMatch } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import GraphqlService from '../../service/graphqlService';
import { useProject } from '../../config/ProjectContext';
import JsonPopup from '../../components/JsonPopup';
import SelectedObjectsToDeployContext from '../../config/SelectedAssetsContext';

const useStyles = makeStyles((theme) => ({
  dialogTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: '#F3916A',
    color: theme.palette.primary.contrastText,
  },
  dialogTitleText: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '1.5rem',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  detailTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleDivide: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    marginTop: theme.spacing(2),
  },
  detailIcon: {
    padding: 0,
  },
  button: {
    color: theme.palette.primary.contrastText,
    // blue
    backgroundColor: '#3F51B5',
    '&:hover': {
      backgroundColor: '#303F9F',
    },
    borderRadius: '5px',
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
}));

function BundleDetailPopup({ releaseID }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const dialogRef = useRef(null);

  const history = useHistory();
  const match = useRouteMatch();

  const { setSelectedObjectsToDeploy: setSelectedBundles } = useContext(SelectedObjectsToDeployContext);

  const { project } = useProject();

  const { data: bundleDetail, isLoading: loading } = useQuery(
    ['bundleDetail', releaseID],
    () => GraphqlService.getBundle({ projectId: project?.code, releaseID }),
    {
      enabled: open && !!project?.code,
    },
  );

  const { data: bundleDeployments, isLoading: bundleDeploymentsLoading } = useQuery(
    ['bundleDeployments', releaseID],
    () => GraphqlService.getBundleDeployments({ projectId: project?.code, releaseID }),
    {
      enabled: open && !!project?.code,
    },
  );

  const { data: bundleWaves, isLoading: bundleWavesLoading } = useQuery(
    ['bundleWaves', releaseID],
    () => GraphqlService.listPaginatedWaves({ projectId: project?.code, releaseID }),
    {
      enabled: open && !!project?.code,
    },
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDeployBundle = () => {
    // Redirect to the deployed asset page
    setSelectedBundles([{
      releaseID,
      ...bundleDetail,
      type: 'bundle',
    }]);
    history.push(`${match.url.replace('/bundles', 'assets/deployments/create')}`);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openAsset = (fileId) => {
    const url = `${window.location.origin}/#/assetDetail/${fileId}`;
    window.open(url, '_blank');
  };

  const openDeployment = (deploymentID) => {
    const url = `${window.location.origin}/#/Detail/${deploymentID}`;
    window.open(url, '_blank');
  };

  const openWave = (waveID) => {
    const url = `${window.location.origin}/#/waveDetail/${waveID}`;
    window.open(url, '_blank');
  };

  const getVersionFromString = (stringValue) => {
    const versionMatch = stringValue?.match(/Version=([^,}]*)/);
    if (versionMatch && versionMatch[1]) {
      return versionMatch[1].trim();
    }
    return undefined;
  };

  const deleteAssetFromRelease = async (fileID) => {
    console.log('Delete asset from release', fileID);
    // TODO implement delete asset from release
  };

  return (
    <div>
      {/* Button to open the dialog */}
      <IconButton onClick={handleClickOpen} color="primary">
        <VisibilityIcon />
      </IconButton>

      {/* Dialog for showing bundle details */}
      <Dialog
        open={open}
        ref={dialogRef}
        onClose={handleClose}
        aria-labelledby="bundle-detail-dialog-title"
        maxWidth="md"
        fullWidth
      >

        <div className={classes.dialogTitleContainer}>
          <Typography className={classes.dialogTitleText}>
            {bundleDetail?.title
              ? `Bundle detail: ${bundleDetail.title} - ${bundleDetail.dateTime?.toString('dd/MM/yyyy HH:mm:ss')}`
              : 'Bundle detail'}
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent dividers>
          {loading || bundleDeploymentsLoading || bundleWavesLoading ? (
            <CircularProgress />
          ) : (
            bundleDetail && (
              <>
                {/* Render the detail of the selected bundle */}
                <Grid container spacing={2}>

                  {/* Render the assets of the selected bundle */}
                  <Grid item xs={12}>
                    <Typography variant="h6">Assets</Typography>
                    <TableContainer component={Paper} className={classes.table}>
                      <Table aria-label="selected assets table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Version</TableCell>
                            <TableCell>Creation Date</TableCell>
                            {/* <TableCell /> */}
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {bundleDetail.fileIDs?.map((asset) => (
                            <TableRow key={asset.fileID}>
                              <TableCell>{asset.type}</TableCell>
                              <TableCell>{getVersionFromString(asset.assetDescription)}</TableCell>
                              <TableCell>{asset.dateTime?.toString('dd/MM/yyyy HH:mm:ss')}</TableCell>
                              {/* <TableCell>
                                <IconButton className={classes.detailIcon} onClick={() => deleteAssetFromRelease(asset.fileID)}>
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell> */}
                              <TableCell>
                                <IconButton className={classes.detailIcon} onClick={() => openAsset(asset.fileID)}>
                                  <ExitToAppIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  {/* Render the deployments of the selected bundle */}
                  <Grid item xs={12}>
                    <Typography variant="h6">Deployments</Typography>
                    <TableContainer component={Paper} className={classes.table}>
                      <Table aria-label="selected assets table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Deployment ID</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {bundleDeployments?.map((deployment) => (
                            <TableRow key={deployment.deployID}>
                              <TableCell>{deployment.deployID}</TableCell>
                              <TableCell>{deployment.dateTime?.toString('dd/MM/yyyy HH:mm:ss')}</TableCell>
                              <TableCell>
                                <IconButton className={classes.detailIcon} onClick={() => openDeployment(deployment.deployID)}>
                                  <ExitToAppIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  {/* Render the waves of the selected bundle */}
                  <Grid item xs={12}>
                    <Typography variant="h6">Waves</Typography>
                    <TableContainer component={Paper} className={classes.table}>
                      <Table aria-label="selected assets table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Wave ID</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {bundleWaves?.data?.length && bundleWaves.data.map((wave) => (
                            <TableRow key={wave.waveID}>
                              <TableCell>{wave.waveID}</TableCell>
                              <TableCell>{wave.dateTime?.toString('dd/MM/yyyy HH:mm:ss')}</TableCell>
                              <TableCell>{wave.waveDescription?.userDescription}</TableCell>
                              <TableCell>
                                <IconButton className={classes.detailIcon} onClick={() => openWave(wave.waveID)}>
                                  <ExitToAppIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  {/* Render the description of the selected bundle */}
                  <Grid item xs={12}>
                    <InputLabel htmlFor="description"> Description</InputLabel>
                    <textarea
                      id="description"
                      disabled
                      value={bundleDetail.releaseDescription?.description}
                      rows="4"
                      cols="50"
                      style={{ width: '100%', padding: '8px', marginTop: '8px' }}
                    />
                  </Grid>
                </Grid>
              </>
            )
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeployBundle} color="secondary" className={classes.button}>
            Deploy Bundle
          </Button>
          <JsonPopup
            title={`Bundle ${typeof bundleDetail?.title === 'string' ? bundleDetail?.title : 'Unknown'}`}
            jsonObject={typeof bundleDetail === 'object' && bundleDetail !== null ? bundleDetail : {}}
          />
          <Button onClick={handleClose} color="primary" className={classes.button}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

BundleDetailPopup.propTypes = {
  releaseID: PropTypes.string.isRequired,
};

export default BundleDetailPopup;
