/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import {
  CREATE_NON_GLOBAL_DEPLOY_PERMISSION,
  UPLOAD_ASSET_PERMISSION,
  NEW_DEPLOYMENT_PATH,
  NEW_ASSET_PATH,
  SHOW_BINARIES_PERMISSION,
  SHOW_DEPLOYMENTS_PERMISSION,
  SHOW_ASSETS_PERMISSION,
  SHOW_MACHINE_EVENTS_PERMISSION,
  SHOW_BRAND_CONSUMPTIONS_PERMISSION,
  SHOW_TELEMETRY_EVENTS_PERMISSION,
  SHOW_FOTA_EVENTS_PERMISSION,
  SHOW_IOT_REPORTS_PERMISSION,
  SHOW_SUPPORT_PERMISSION,
  SHOW_LIFE_CYCLE_EVENTS_PERMISSION,
  SHOW_SANDBOX_OBJECT_PERMISSION,
  IS_ROOT,
  GET_BUNDLES_FOR_GIVEN_BRAND,
  SHOW_MESSAGE_SIMULATOR,
  SHOW_CERTIFICATE_QUOTA,
  SHOW_CERTIFICATE_STATUS,
} from '../utils/constants';
import Binary from '../screens/Binary';
import NewDeploy from '../components/AssetList/Deploy/NewDeploy';
import AssetCreate from '../screens/Asset/CreateAsset';
import AssetList from '../screens/Asset/AssetList';
import BundleList from '../screens/Bundle/BundleList';
import BundleWizard from '../screens/Bundle/BundleWizard';
import CognitoUsersList from '../screens/Admin/CognitoUsersList';
import PortalInfo from '../screens/Admin/PortalInfo';
import KPIsCharts from '../screens/Admin/KPIsCharts';
import UserActivity from '../screens/Admin/UserActivity';
import DeploymentsList from '../screens/Deployment/DeploymentList';
import WaveList from '../screens/Wave/WaveList';
import MachineEvents from '../screens/Statistics/MachineEvents';
import BrandConsumptions from '../screens/Statistics/BrandConsumptions';
import BrandEvents from '../screens/Statistics/BrandEvents';
import DeviceInfo from '../screens/Statistics/DeviceInfo';
import DeploymentEventsHistory from '../screens/Statistics/DeploymentEventsHistory';
import FotaEvents from '../screens/Statistics/FotaEvents';
import Reports from '../screens/Reports/Reports';
import Support from '../screens/Support/Support';
import DeviceSettings from '../screens/Statistics/DeviceSettings';
import DeviceRawData from '../screens/Statistics/DeviceRawData';
import ModuleSettings from '../screens/Statistics/ModuleSettings';
import DeploymentDetails from '../screens/Statistics/DeploymentDetails';
import DeploymentStatus from '../screens/Statistics/DeploymentStatus';
import PortalConfig from '../screens/Admin/PortalConfig';
import CloudToDevice from '../screens/MessageSimulator/CloudToDevice';
import RequestManager from '../screens/MessageSimulator/RequestManager';
import QuotaManagement from '../screens/CertificateManagement/QuotaManagement';
import CertificateStatus from '../screens/CertificateManagement/CertificateStatus';
import NotificationsCenter from '../screens/Admin/NotificationsCenter';

export default [ // Must go from specific to general
  {
    path: '/assets', name: 'Assets', component: AssetList, permission: SHOW_ASSETS_PERMISSION,
  },
  {
    path: '/bundles', name: 'Bundles', component: BundleList, permission: GET_BUNDLES_FOR_GIVEN_BRAND,
  },
  {
    path: '/bundles/create/:source?', name: 'Bundles', component: BundleWizard, permission: GET_BUNDLES_FOR_GIVEN_BRAND,
  },
  {
    path: '/deployments', name: 'Deployments', component: DeploymentsList, permission: SHOW_DEPLOYMENTS_PERMISSION,
  },
  {
    path: '/waves', name: 'Waves', component: WaveList, permission: SHOW_DEPLOYMENTS_PERMISSION,
  },
  {
    path: '/binaries', name: 'Binaries', component: Binary, permission: SHOW_BINARIES_PERMISSION,
  },
  {
    path: NEW_ASSET_PATH, name: 'Create new Asset', component: AssetCreate, permission: UPLOAD_ASSET_PERMISSION,
  },
  {
    path: '/assetDetail/:fileId', name: 'Assets', component: AssetList, permission: SHOW_ASSETS_PERMISSION,
  },
  {
    path: '/deploymentDetail/:deploymentID', name: 'Deployments', component: DeploymentsList, permission: SHOW_DEPLOYMENTS_PERMISSION,
  },
  {
    path: '/waveDetail/:waveID', name: 'Waves', component: WaveList, permission: SHOW_DEPLOYMENTS_PERMISSION,
  },
  {
    path: `/assets${NEW_DEPLOYMENT_PATH}`, name: 'Create new Deployment', component: NewDeploy, permission: CREATE_NON_GLOBAL_DEPLOY_PERMISSION,
  },
  {
    path: '/statistics/machineEvents', name: 'Machine events', component: MachineEvents, permission: SHOW_MACHINE_EVENTS_PERMISSION,
  },
  {
    path: '/statistics/brandConsumptions', name: 'Device consumptions', component: BrandConsumptions, permission: SHOW_BRAND_CONSUMPTIONS_PERMISSION,
  },
  {
    path: '/statistics/brandEvents', name: 'Device events', component: BrandEvents, permission: SHOW_TELEMETRY_EVENTS_PERMISSION,
  },
  {
    path: '/statistics/deviceInfo', name: 'Device info', component: DeviceInfo, permission: SHOW_TELEMETRY_EVENTS_PERMISSION,
  },
  {
    path: '/statistics/deviceSettings', name: 'Device settings', component: DeviceSettings, permission: SHOW_TELEMETRY_EVENTS_PERMISSION,
  },
  {
    path: '/statistics/deviceRawData', name: 'Device raw data', component: DeviceRawData, permission: SHOW_SANDBOX_OBJECT_PERMISSION,
  },
  {
    path: '/statistics/deploymentEventsHistory', name: 'Deployment events history', component: DeploymentEventsHistory, permission: SHOW_LIFE_CYCLE_EVENTS_PERMISSION,
  },
  {
    path: '/statistics/moduleSettings', name: 'Module settings', component: ModuleSettings, permission: SHOW_TELEMETRY_EVENTS_PERMISSION,
  },
  {
    path: '/statistics/fotaEvents', name: 'Fota Events', component: FotaEvents, permission: SHOW_FOTA_EVENTS_PERMISSION,
  },
  {
    path: '/reports', name: 'Reports', component: Reports, permission: SHOW_IOT_REPORTS_PERMISSION,
  },
  {
    path: '/support', name: 'Support', component: Support, permission: SHOW_SUPPORT_PERMISSION,
  },
  {
    path: '/statistics/deploymentDetails/:deploymentID/:detailStartDate?', name: 'Deployments', component: DeploymentDetails, permission: SHOW_DEPLOYMENTS_PERMISSION,
  },
  {
    path: '/statistics/deploymentStatus/:deploymentID/:detailStartDate?', name: 'Deployment status summary', component: DeploymentStatus, permission: SHOW_LIFE_CYCLE_EVENTS_PERMISSION,
  },
  {
    path: '/listCognitoUsers', name: 'CognitoUsersList', component: CognitoUsersList, permission: IS_ROOT,
  },
  {
    path: '/notifications', name: 'Notifications', component: NotificationsCenter, permission: IS_ROOT,
  },
  {
    path: '/portalInfo', name: 'portalInfo', component: PortalInfo, permission: IS_ROOT,
  },
  {
    path: '/userActivity/:userId', name: 'UserActivity', component: UserActivity, permission: IS_ROOT,
  },
  {
    path: '/portalConfiguration', name: 'PortalConfiguration', component: PortalConfig, permission: IS_ROOT,
  },
  {
    path: '/userActivity', name: 'UserActivity', component: UserActivity, permission: IS_ROOT,
  },
  {
    path: '/kpis', name: 'KPIS', component: KPIsCharts, permission: IS_ROOT,
  },
  {
    path: '/cloudToDevice', name: 'CloudToDevice', component: CloudToDevice, permission: SHOW_MESSAGE_SIMULATOR,
  },
  {
    path: '/requestManager', name: 'RequestManager', component: RequestManager, permission: SHOW_MESSAGE_SIMULATOR,
  },
  {
    path: '/quotaManagement', name: 'QuotaManagement', component: QuotaManagement, permission: SHOW_CERTIFICATE_QUOTA,
  },
  {
    path: '/certificateStatus', name: 'CertificateStatus', component: CertificateStatus, permission: SHOW_CERTIFICATE_STATUS,
  },
  {
    path: '/', name: 'Default', component: AssetList, permission: SHOW_ASSETS_PERMISSION,
  },

];
