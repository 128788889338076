/* eslint-disable no-unused-vars */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Restricted from '../../authorization/Restricted';
import routes from '../../config/routes';

const useStyles = makeStyles((theme) => ({
  drawerItem: {
    minHeight: 48,
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: 'white',
    },
  },
  drawerItemIcon: {
    minWidth: 24, // Ensure consistent icon alignment
    marginRight: 12, // Ensure consistent spacing between icon and text
  },
}));

function LeftBarMainItem({
  name, path, icon, permission, closeDrawer,
}) {
  const { pathname } = useLocation();
  const classes = useStyles();

  const isThisLocation = (localPath) => pathname.substring(0, localPath.length) === localPath;

  const isSelected = () => {
    // If no specific location is matched, default to 'Assets'
    if (routes.find((route) => route.path === pathname) === undefined) {
      return path === '/assets'; // Default to assets if no other path is matched
    }
    return isThisLocation(path);
  };

  return (
    <Restricted to={[permission]}>
      <ListItem
        id={`listItem${name.replaceAll(' ', '')}`}
        button
        component={Link}
        to={path}
        key={name}
        onClick={closeDrawer}
        selected={isSelected(path)}
        classes={{ root: classes.drawerItem }}
      >
        {/* <ListItemIcon className={classes.drawerItemIcon}>{icon}</ListItemIcon> */}
        <ListItemText primary={name} />
        <ListItemIcon>
          <KeyboardArrowRightIcon />
        </ListItemIcon>
      </ListItem>
    </Restricted>
  );
}

LeftBarMainItem.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  permission: PropTypes.string.isRequired,
  closeDrawer: PropTypes.func.isRequired,
};

export default LeftBarMainItem;
