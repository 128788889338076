/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box, Grid, makeStyles, InputLabel,
} from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  React, useContext, useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useProject } from '../../config/ProjectContext';
import GraphqlService from '../../service/graphqlService';
import PageBanner from '../../components/utils/PageBanner';
import SimpleAlert from '../../components/utils/SimpleAlert';
import ProgressDialog from '../../components/utils/ProgressDialog';
import Filters from '../../components/utils/Filters';
import { validate, calculateEndDateTimeIso, calculateStartDateTimeIso } from '../../utils/dateUtils';
import DeviceRawDataTable from './MachineTelemetry/DeviceRawDataTable';

const useStyles = makeStyles((theme) => ({
  formField: {
    width: '100%',
    '& ::placeholder': {
      paddingLeft: 8,
      paddingTop: 8,
    },
  },
  formPlaceholder: {
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: 'blue',
    },
  },
  inputDescriptionLabel: {
    font: theme.typography.h4.font,
    fontSize: 16,
    color: theme.palette.primary.text,
    paddingTop: 24,
    paddingLeft: 24,
    backgroundColor: theme.palette.background.form,
  },
  contentInfo: {
    paddingTop: 24,
    paddingBottom: 8,
    paddingLeft: 24,
    paddingRight: 24,
    backgroundColor: theme.palette.background.typography,
  },
  // tabs
  headerTabPanel: {
    backgroundColor: theme.palette.background.paper,
  },
  labelHeaderTabPanel: {
    color: theme.palette.primary.labelHeader,
    fontFamily: theme.typography.h3.fontFamily,
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '0',
    lineHeight: '16px',
    textAlign: 'center',
    width: '720px',
    height: '48px',
  },
  detailsTitle: {
    paddingRight: '5px',
    fontFamily: theme.typography.h5.fontFamily,
    lineHeight: theme.typography.h5.lineHeight,
    letterSpacing: theme.typography.h5.letterSpacing,
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.primary.subMain,
  },
  accordion: {
    borderRadius: '12px !important',
  },
  details: {
    paddingLeft: 24,
    paddingRight: 24,
  },
}));

export default function DeviceRawData({ openSnackBar }) {
  const MAX_DAYS_BETWEEN_DATES = 90;

  // STATE________________________________
  const { project } = useProject();

  // PARAMS
  const { deploymentId } = useParams();

  // grid
  const [totalCount, setTotalCount] = useState(0);
  const [reducedPage, setReducedPage] = useState(0);
  const [paginationTokens, setPaginationTokens] = useState(['']);
  const [rowsPerPageConsumption] = useState(100);
  const [reducedResponse, setReducedResponse] = useState(null);

  //  alert data
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertHeader, setAlertHeader] = useState('');
  const [alertText, setAlertText] = useState('');

  //  state values
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);

  // errors
  const [dateStartError, setDateStartError] = useState(false);
  const [dateEndError, setDateEndError] = useState(false);
  const [greaterSmallerDateError, setGreaterSmallerDateError] = useState(false);
  const [moreThanMaxDaysDateError, setMoreThanMaxDaysDateError] = useState(false);
  const [deviceSerialError, setDeviceSerialError] = useState(false);

  // data
  const [selectedFilters, setSelectedFilters] = useState('');
  const [validity, setValidity] = useState('valid');
  const [selectedStartDate, setSelectedStartDate] = useState(moment().add(-30, 'days'));
  const [selectedStartTime, setSelectedStartTime] = useState(moment().add(-30, 'days').format('HH:mm'));
  const [selectedEndDate, setSelectedEndDate] = useState(moment());
  const [selectedEndTime, setSelectedEndTime] = useState(moment().format('HH:mm'));
  const [dateRangePickerVisibility, setDateRangePickerVisibility] = useState(true);
  // progress dialog
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);

  // params
  const [params, setParams] = useState({ deviceSerial: '', messageType: '' });

  const onChangeDeviceSerial = (newValue) => {
    setDeviceSerialError(false);
    setParams({ ...params, deviceSerial: newValue });
  };

  const onChangeMessageType = (newValue) => {
    setParams({ ...params, messageType: newValue });
  };

  function openAlert(header, text) {
    setAlertHeader(header);
    setAlertText(text);
    setAlertOpen(true);
  }

  // FORM_________________________________
  const {
    handleSubmit, register, formState: { errors },
  } = useForm();

  // functions to obtain the data to show
  async function searchObject(myProject, filters, startDate, endDate, paginationToken) {
    setDateRangePickerVisibility(!dateRangePickerVisibility);
    const queryParams = params.deviceSerial === '' && params.messageType === '' ? {} : params.deviceSerial === '' && params.messageType !== '' ? JSON.stringify({ messageType: params.messageType }) : params.deviceSerial !== '' && params.messageType === '' ? JSON.stringify({ deviceSerial: params.deviceSerial }) : JSON.stringify(params);
    const response = await GraphqlService.getSandboxObject(
      myProject.code, `rawmessages/${myProject.code}/${validity}`, queryParams, filters, startDate, endDate, MAX_DAYS_BETWEEN_DATES, paginationToken,
    );

    // eslint-disable-next-line no-plusplus
    if (response) {
      setPaginationTokens((prev) => {
        if (response?.pagination && response?.pagination.paginationContinuationToken && !prev.some((token) => token === response.pagination.paginationContinuationToken)) {
          return [...prev, response.pagination.paginationContinuationToken];
        }
        return prev;
      });
      const generalData = JSON.parse(response.data);
      return generalData;
    }
    return [];
  }

  async function search(myProject, filters, startDate, endDate, startTime, endTime, newPage, newValue) {
    try {
      setSubmitting(true);
      setProgressDialogOpen(true);
      setLoading(true);
      let creationResponse = [];

      setReducedPage(newPage || 0);
      creationResponse = await searchObject(myProject, filters, calculateStartDateTimeIso(startDate, startTime), calculateEndDateTimeIso(endDate, endTime), paginationTokens[newPage] ? paginationTokens[newPage] : undefined);
      setReducedResponse(creationResponse);
      setMoreThanMaxDaysDateError(false);

      setSubmitting(false);
      setProgressDialogOpen(false);
      setLoading(false);
      return creationResponse;
    } catch (error) {
      console.log('Error!!!', error);
      setProgressDialogOpen(false);
      setSubmitting(false);
      setLoading(false);
      openAlert('Error', error.message);
      return null;
    }
  }

  const onSubmit = (data) => {
    if (validate(selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime, MAX_DAYS_BETWEEN_DATES, setDateStartError, setDateEndError, setSelectedStartDate, setSelectedStartTime, setSelectedEndDate, setSelectedEndTime, setGreaterSmallerDateError, setMoreThanMaxDaysDateError)) {
      search(
        project, selectedFilters, selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime,
      );
    }
  };

  // WEBPAGE______________________________
  const classes = useStyles();

  useEffect(() => {
    setPaginationTokens(['']);
  }, [selectedFilters, selectedStartDate, selectedEndDate, project, params.deviceSerial]);

  const handleRadioChange = (event) => {
    setValidity(event.target.value);
  };

  return (
    <Box spacing={0}>
      <ProgressDialog open={progressDialogOpen} setOpen={setProgressDialogOpen} header="Retrieving statistics, please wait" />
      <SimpleAlert open={alertOpen} setOpen={setAlertOpen} header={alertHeader} body={alertText} />
      <PageBanner title="DEVICE RAW DATA" />
      <Grid
        container
        spacing={0}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classes.content}
        >
          <Filters
            id="DeviceRawData"
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            submitting={submitting}
            selectedStartDate={selectedStartDate}
            setSelectedStartDate={setSelectedStartDate}
            selectedStartTime={selectedStartTime}
            setSelectedStartTime={setSelectedStartTime}
            selectedEndDate={selectedEndDate}
            setSelectedEndDate={setSelectedEndDate}
            selectedEndTime={selectedEndTime}
            setSelectedEndTime={setSelectedEndTime}
            greaterSmallerDateError={greaterSmallerDateError}
            setGreaterSmallerDateError={setGreaterSmallerDateError}
            moreThanMaxDaysDateError={moreThanMaxDaysDateError}
            setMoreThanMaxDaysDateError={setMoreThanMaxDaysDateError}
            filtersVisible={false}
            linkVisible
            linkText="List of message types"
            linkURL="https://nestle.atlassian.net/wiki/spaces/DIIS/pages/107785940/IoT+Message+Types"
            deviceSerialVisible
            deviceSerial={params.deviceSerial}
            setDeviceSerial={onChangeDeviceSerial}
            deviceSerialError={deviceSerialError}
            messageType={params.messageType}
            setMessageType={onChangeMessageType}
            messageTypeVisible
            maxDaysBetweenDates={MAX_DAYS_BETWEEN_DATES}
            subtitle="List of devices and modules impacted by a deployment with the current deployment status"
            filtersComponents={[
              {
                type: 'radio',
                label: 'Message Validity',
                handleChange: handleRadioChange,
                defaultValue: 'valid',
                values: [{
                  label: 'Valid',
                  value: 'valid',
                }, {
                  label: 'Invalid',
                  value: 'invalid',
                }],
              },
            ]}
            setDateRangePickerVisibility={setDateRangePickerVisibility}
          />
        </form>
      </Grid>
      <Grid item xs={12}>
        <DeviceRawDataTable
          search={(newPage) => search(project, selectedFilters, selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime, newPage)}
          dataList={reducedResponse}
          filters={selectedFilters}
          loading={loading}
          dateIni={calculateStartDateTimeIso(selectedStartDate, selectedStartTime)}
          dateEnd={calculateEndDateTimeIso(selectedEndDate, selectedEndTime)}
          rangeDates={MAX_DAYS_BETWEEN_DATES}
          page={reducedPage}
          setPage={setReducedPage}
          rowsPerPage={rowsPerPageConsumption}
          paginationTokens={paginationTokens}
          object={`rawmessages/${project.code}/${validity}`}
          params={params.deviceSerial !== '' ? JSON.stringify(params) : JSON.stringify({})}
          id="DeviceRawData"
          pagination="token"
          columnInclude={['validData', 'deviceSerial', 'invalidData']}
        />
      </Grid>
    </Box>
  );
}
DeviceRawData.propTypes = {
  openSnackBar: PropTypes.func.isRequired,
};
