import routes from '../../config/routes';

const isThisLocation = (localPath, pathname) => pathname.substring(0, localPath.length) === localPath;

const itemIsSelected = (_path, pathname) => {
  // If no specific location is matched, default to 'Assets'
  if (routes.find((route) => route.path === pathname) === undefined) {
    console.log('pathname', pathname);
    if (pathname.includes('/statistics/deploymentDetails/') || pathname.includes('/statistics/deploymentStatus')) {
      return false; //
    }
    return _path === '/assets';
  }
  return isThisLocation(_path, pathname);
};

export default itemIsSelected;
