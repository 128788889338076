/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import {
  Box, Grid, InputLabel, makeStyles, Button, TextField, Chip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  React, useEffect, useState,
} from 'react';
import { Alert } from '@material-ui/lab';
import { useProject } from '../../../config/ProjectContext';
import SimpleAlert from '../../utils/SimpleAlert';
import CustomTextArea from '../../utils/CustomTextArea';
import { DEPLOY_TYPE_MARKET, validMarketRegex } from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  summaryContent: {
    backgroundColor: theme.palette.background.typography,
  },
  gridBackground: {
    backgroundColor: theme.palette.background.form,
    borderRadius: '12px',
  },
  commentGrid: {
    paddingBottom: '22px',
  },
  marketGrid: {
    paddingBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 24,
  },
  marketGridSelectedMarkets: {
    // add a dashed border to the grid to indicate the selected markets
    border: `2px dashed ${theme.palette.primary.modelPicker}`,
    minHeight: 198,
  },
  inputLabelIndicateMarket: {
    paddingBottom: '16px',
    fontSize: '20px',
    lineHeight: '20px',
    letterSpacing: '0',
    color: theme.palette.primary.text,
  },
  inputLabelSelectedMarkets: {
    paddingBottom: '16px',
    paddingTop: '16px',
    fontSize: '20px',
    lineHeight: '20px',
    letterSpacing: '0',
    color: theme.palette.primary.main,
    fontFamily: theme.typography.h5.fontFamily,
    textAlign: 'center',
  },
  labelDescription: {
    fontSize: 15,
    lineHeight: '24px',
    fontWeight: 400,
    paddingBottom: 16,
  },
  inputComments: {
    fontSize: '16px',
    lineHeight: '20px',
    paddingTop: 24,
    paddingBottom: 12,
    color: theme.palette.primary.text,
  },
  helperText: {
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'right',
    marginTop: '-3px',
    paddingTop: '8px',
  },
  comment: {
    // backgroundColor: theme.palette.background.paper,
    width: '134px',
    height: '56px',
    '& .MuiFilledInput-multiline': {
      paddingTop: 16,
      paddingLeft: 16,
    },
  },
  chip: {
    background: theme.palette.background.paper,
    color: theme.palette.secondary.contrastText,
    marginLeft: 6,
    marginRight: 6,
    marginTop: 6,
    width: 67,
  },
  errorAlert: {
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 4,
  },
}));

export default function MarketDeploy({
  setTargets, setType, values, setValues, market, setMarket, selectedMarkets, setSelectedMarkets,
}) {
  //  alert data
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertHeader, setAlertHeader] = useState('');
  const [alertText, setAlertText] = useState('');

  const { project } = useProject();

  // DATA RETRIEVAL __________________________
  async function loadType() {
    setType(DEPLOY_TYPE_MARKET);
  }

  // WEBPAGE______________________________

  // process CSV data
  function openAlert(header, text) {
    setAlertHeader(header);
    setAlertText(text);
    setAlertOpen(true);
  }

  useEffect(() => {
    if (selectedMarkets.length > 0) {
      setTargets(selectedMarkets);
    }
  }, [selectedMarkets]);

  useEffect(() => {
    loadType();
  }, [project]);

  const classes = useStyles();

  const onChangeMarket = (event) => {
    // check if the new introduced character is a letter (or if it is empty)
    if (event.target.value.match(/[a-zA-Z]/) || event.target.value === '') {
      // if it is a character, convert it to uppercase
      setMarket(event.target.value.toUpperCase());
    }
    // Check with a regex if the value has a valid market format
    if (validMarketRegex.test(event.target.value.toUpperCase())) {
      setMarket('');
      // Check if the market is already selected
      if (selectedMarkets.includes(event.target.value.toUpperCase())) {
        openAlert('Error', 'Market already selected');
      } else {
        setSelectedMarkets([...selectedMarkets, event.target.value.toUpperCase()]);
      }
    }
  };

  return (
    <Box>
      <SimpleAlert open={alertOpen} setOpen={setAlertOpen} header={alertHeader} body={alertText} />
      <Grid
        container
        spacing={0}
        direction="row"
        className={classes.summaryContent}
      >
        <Grid
          container
          className={classes.gridBackground}
          direction="row"
          xs={12}
        >
          <Grid item xs={6} className={classes.marketGrid}>
            <InputLabel htmlFor="comments" className={classes.inputLabelIndicateMarket}>Indicates a Market</InputLabel>
            <InputLabel className={classes.labelDescription}>Please enter the 2 uppercase characters associated with the ISO code of the selected country. Once you have introduced one market, you can introduce another.</InputLabel>
            <TextField
              id="inputMarketSelection-marketDeployment"
              inputProps={{
                className: classes.inputComments,
                maxLength: 2,
              }}
              FormHelperTextProps={{
                align: 'right',
                classes: {
                  root: classes.helperText,
                },
              }}
              className={classes.comment}
              variant="filled"
              color="secondary"
              value={market}
              onChange={(event) => onChangeMarket(event)}
              label="Select Market"
            />
            {market.length === 2 && !validMarketRegex.test(market) && <Alert severity="error" className={classes.errorAlert}>Market not valid following ISO-3166 alpha-2</Alert>}
          </Grid>
          <Grid item xs={6} className={classes.marketGrid}>
            <Grid item xs={12} className={classes.marketGridSelectedMarkets}>
              <InputLabel htmlFor="comments" className={classes.inputLabelSelectedMarkets}>Selected Markets</InputLabel>
              <Grid style={{ paddingLeft: 18, paddingRight: 18, paddingBottom: 16 }}>
                {selectedMarkets.map((selectedMarket, selectedMarketId) => (
                  <Chip
                    className={classes.chip}
                    label={selectedMarket}
                    variant="outlined"
                    color="primary"
                    id={`chipMarket${selectedMarketId}`}
                    onDelete={() => setSelectedMarkets(selectedMarkets.filter((marketAux) => marketAux !== selectedMarket))}
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            className={classes.commentGrid}
          >
            <Grid
              item
              xs={12}
              spacing={0}
              direction="column"
              container
            >
              <CustomTextArea
                screenId="inputDeploymentComments-marketDeployment"
                title="Deployment description"
                placeHolder="Comments (optional)"
                size={300}
                values={values}
                setValues={setValues}
                isComment
                rows={4}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

MarketDeploy.propTypes = {
  setTargets: PropTypes.func.isRequired,
  setType: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  market: PropTypes.string.isRequired,
  setMarket: PropTypes.func.isRequired,
  selectedMarkets: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedMarkets: PropTypes.func.isRequired,
};
