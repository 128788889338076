import { Storage } from 'aws-amplify';

export default class S3Service {
  /**
   * Uploads a file to S3.
   * @param {File} file - File to upload
   * @returns {boolean} true if the file is uploaded succesfully, false if an error occurs
   */
  static async uploadFile(file) {
    try {
      await Storage.put(file.name, file);
      return true;
    } catch (error) {
      return false;
    }
  }

  static async deleteFile(filename) {
    try {
      const response = await Storage.remove(filename);
      return response;
    } catch (err) {
      console.error(err.stack || err);
      return false;
    }
  }
}
