/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-no-bind */
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import SearchButton from '../../components/utils/SearchButton';
import { useProject } from '../../config/ProjectContext';
import GraphqlService from '../../service/graphqlService';
import PageBanner from '../../components/utils/PageBanner';
import SimpleAlert from '../../components/utils/SimpleAlert';
import ProgressDialog from '../../components/utils/ProgressDialog';
import { calculateEndDateTimeIso, calculateStartDateTimeIso } from '../../utils/dateUtils';
import BrandObjectTable from './MachineTelemetry/BrandObjectTable';
import DateRangePicker from '../../components/utils/DateRangePicker';
import { DEPLOYMENT_STATUS } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  formField: {
    width: '100%',
    '& ::placeholder': {
      paddingLeft: 8,
      paddingTop: 8,
    },
  },
  inputDescriptionLabel: {
    font: theme.typography.h4.font,
    fontSize: 16,
    color: theme.palette.primary.text,
    paddingTop: 24,
    paddingLeft: 24,
    backgroundColor: theme.palette.background.form,
  },
  contentInfo: {
    padding: '24px',
    backgroundColor: theme.palette.background.typography,
  },
  radioGroup: {
    paddingLeft: 12,
    paddingTop: 7,
    width: '100%',
    backgroundColor: 'white',
    height: 54,
    borderRadius: 4,
  },
  datePicker: {
    marginTop: 8,
    marginBottom: 16,
  },
  bottomRightButton: {
    textAlign: 'right',
    alignSelf: 'flex-end',
    borderRadius: 12,
    width: 138,
  },
}));

export default function DeploymentDetails() {
  const MAX_DAYS_BETWEEN_DATES = 90;
  const { project } = useProject();

  // State variables for filters
  const [params, setParams] = useState({
    isError: false,
    status: '',
    exitCode: '',
    prjSpecExitCode: '',
    deployType: '',
    countryCode: '',
    hw: '',
    fw: '',
    groupBy: '',
    sortDirection: '',
  });

  const [reducedPage, setReducedPage] = useState(0);
  const [paginationTokens, setPaginationTokens] = useState(['']);
  const [reducedResponse, setReducedResponse] = useState(null);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertHeader, setAlertHeader] = useState('');
  const [alertText, setAlertText] = useState('');

  const [loading, setLoading] = useState(false);

  const [selectedStartDate, setSelectedStartDate] = useState(moment().startOf('day'));
  const [selectedStartTime, setSelectedStartTime] = useState(moment().format('HH:mm'));
  const [selectedEndDate, setSelectedEndDate] = useState(undefined);
  const [selectedEndTime, setSelectedEndTime] = useState(undefined);

  const [progressDialogOpen, setProgressDialogOpen] = useState(false);

  const { deploymentID, detailStartDate } = useParams();

  const handleDateChange = (startDate, endDate) => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setParams({ ...params, [name]: value });
  };

  function openAlert(header, text) {
    setAlertHeader(header);
    setAlertText(text);
    setAlertOpen(true);
  }

  const { handleSubmit } = useForm();

  async function searchObject(myProject, startDate, endDate, paginationToken) {
    // Construct query parameters
    const queryParams = { ...params };
    // Remove empty filters
    Object.keys(queryParams).forEach((key) => {
      if (queryParams[key] === '' || queryParams[key] === null) {
        delete queryParams[key];
      }
    });

    const response = await GraphqlService.getLifeCycleObject(
      myProject.code,
      `machinelist/${myProject.code}/${deploymentID}`,
      JSON.stringify(queryParams),
      {},
      startDate,
      endDate,
      paginationToken,
    );

    if (response) {
      setPaginationTokens((prev) => {
        if (
          response?.pagination
          && response?.pagination.paginationContinuationToken
          && !prev.some((token) => token === response.pagination.paginationContinuationToken)
        ) {
          return [...prev, response.pagination.paginationContinuationToken];
        }
        return prev;
      });
      const generalData = JSON.parse(response.data);
      return generalData;
    }
    return [];
  }

  async function search(myProject, startDate, endDate, startTime, endTime, newPage) {
    try {
      setProgressDialogOpen(true);
      setLoading(true);
      setReducedPage(newPage || 0);
      const creationResponse = await searchObject(
        myProject,
        calculateStartDateTimeIso(startDate, startTime),
        calculateEndDateTimeIso(endDate, endTime),
        paginationTokens[newPage] ? paginationTokens[newPage] : undefined,
      );
      setReducedResponse(creationResponse);
      setProgressDialogOpen(false);
      setLoading(false);
      return creationResponse;
    } catch (error) {
      console.error('Error!!!', error);
      setProgressDialogOpen(false);
      setLoading(false);
      openAlert('Error', error.message);
      return null;
    }
  }

  const onSubmit = () => {
    search(project, selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime);
  };

  useEffect(() => {
    if (!detailStartDate) return;
    const parsedStartDate = moment.unix(detailStartDate);
    if (moment(parsedStartDate).isValid()) {
      setSelectedStartDate(parsedStartDate);
      setSelectedStartTime(moment(parsedStartDate).set({ hour: 0, minute: 0 }).format('HH:mm'));
    }
    search(project, parsedStartDate, undefined, moment(parsedStartDate).format('HH:mm'), undefined);
  }, [detailStartDate]);

  const classes = useStyles();

  useEffect(() => {
    setPaginationTokens(['']);
  }, [selectedStartDate, selectedEndDate]);

  return (
    <Box spacing={0}>
      <ProgressDialog open={progressDialogOpen} setOpen={setProgressDialogOpen} header="Retrieving statistics, please wait" />
      <SimpleAlert open={alertOpen} setOpen={setAlertOpen} header={alertHeader} body={alertText} />
      <PageBanner title="DEPLOYMENT DETAILS" />
      <Grid container spacing={0} style={{ padding: '40px' }}>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.content}>
          <Grid item container spacing={2} direction="row" className={classes.topContent}>
            <Grid item xs={12}>
              <InputLabel className={classes.inputSubtitleLabel}>
                List of devices and modules impacted by a deployment with the current deployment status
              </InputLabel>
            </Grid>
            <Grid item xs={12} md={2}>

              <FormControl className={classes.formField}>
                <InputLabel>Is Error</InputLabel>
                <Select
                  name="isError"
                  value={params.isError}
                  onChange={handleInputChange}
                >
                  <MenuItem value="false">Normal</MenuItem>
                  <MenuItem value="true">Error</MenuItem>
                  {/* Add other status options as needed */}
                </Select>
              </FormControl>

            </Grid>

            <Grid item xs={12} md={2}>
              <FormControl fullWidth className={classes.formField}>
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  labelId="status-label"
                  name="status"
                  value={params.status || ''}
                  onChange={handleInputChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {Object.entries(DEPLOYMENT_STATUS).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={2}>
              <TextField
                name="exitCode"
                label="Exit Code"
                value={params.exitCode}
                onChange={handleInputChange}
                className={classes.formField}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <TextField
                name="prjSpecExitCode"
                label="Project-Specific Exit Code"
                value={params.prjSpecExitCode}
                onChange={handleInputChange}
                className={classes.formField}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <FormControl className={classes.formField}>
                <InputLabel>Deploy Type</InputLabel>
                <Select name="deployType" value={params.deployType} onChange={handleInputChange}>
                  <MenuItem value=""><em>None</em></MenuItem>
                  {/* Add deploy type options here */}
                  <MenuItem value="full">Full</MenuItem>
                  <MenuItem value="partial">Partial</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={2}>
              <TextField
                name="countryCode"
                label="Country Code"
                value={params.countryCode}
                onChange={handleInputChange}
                className={classes.formField}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <TextField
                name="hw"
                label="Hardware (hw)"
                value={params.hw}
                onChange={handleInputChange}
                className={classes.formField}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <TextField
                name="fw"
                label="Firmware (fw)"
                value={params.fw}
                onChange={handleInputChange}
                className={classes.formField}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <FormControl className={classes.formField}>
                <InputLabel>Group By</InputLabel>
                <Select name="groupBy" value={params.groupBy} onChange={handleInputChange}>
                  <MenuItem value=""><em>None</em></MenuItem>
                  <MenuItem value="country_code">Country Code</MenuItem>
                  <MenuItem value="asset_exit_code">Asset Exit Code</MenuItem>
                  <MenuItem value="asset_project_exit_code">Asset Project Exit Code</MenuItem>
                  <MenuItem value="hw">Hardware (hw)</MenuItem>
                  <MenuItem value="fw">Firmware (fw)</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={2}>
              <FormControl className={classes.formField}>
                <InputLabel>Sort Direction</InputLabel>
                <Select name="sortDirection" value={params.sortDirection} onChange={handleInputChange}>
                  <MenuItem value=""><em>None</em></MenuItem>
                  <MenuItem value="ASC">Ascending</MenuItem>
                  <MenuItem value="DESC">Descending</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Existing Date Range Picker */}
            <Grid item xs={12} className={classes.datePicker}>
              <DateRangePicker
                initialStartDate={new Date()}
                initialEndDate={new Date()}
                onDateChange={handleDateChange}
                setSelectedStartDate={setSelectedStartDate}
                selectedStartDate={selectedStartDate}
                setSelectedStartTime={setSelectedStartTime}
                selectedStartTime={selectedStartTime}
                setSelectedEndDate={setSelectedEndDate}
                selectedEndDate={selectedEndDate}
                setSelectedEndTime={setSelectedEndTime}
                selectedEndTime={selectedEndTime}
                datesOptional
                setVisibility={false}
                isEndDateEnabled={false}
              />
            </Grid>

            <Grid item container justifyContent="flex-end" xs={12} md={12} className={classes.bottomRightButton}>
              <SearchButton id="btnDeploymentDetailsSearch" type="submit" className={classes.bottomRightButton}>
                SEARCH
              </SearchButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid item xs={12}>
        <BrandObjectTable
          search={(newPage) => search(project, selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime, newPage)}
          dataList={reducedResponse}
          loading={loading}
          dateIni={moment(`${selectedStartDate} ${selectedStartTime}`)}
          dateEnd={selectedEndDate ? moment(`${selectedEndDate} ${selectedEndTime}`) : null}
          rangeDates={MAX_DAYS_BETWEEN_DATES}
          page={reducedPage}
          setPage={setReducedPage}
          rowsPerPage={100}
          paginationTokens={paginationTokens}
          object={`machinelist/${project.code}/${deploymentID}`}
          params={JSON.stringify(params)}
          id="DeploymentDetails"
          pagination="token"
          columnInclude="validData"
        />
      </Grid>
    </Box>
  );
}
