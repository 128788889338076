/* eslint-disable no-nested-ternary */
import {
  TableRow, makeStyles, withStyles, TableCell,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  React,
} from 'react';
// import { formatToPrintableDate } from '../../utils/dateUtils';

const useStyles = makeStyles(() => ({
  cell: {
    size: 'small',
    whiteSpace: 'nowrap',
  },
  cellwrap: {
    size: 'small',
    whiteSpace: 'normal',
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.form,
    },
  },
}))(TableRow);

export default function BrandObjectListItem({
  object, id,
}) {
  const classes = useStyles();
  const elements = Object.entries(object);

  return (
    <StyledTableRow>
      {
        elements.map((entry, index) => (
          <StyledTableCell id={`${id}-${index}`} component="td" className={classes.cellwrap}>
            {entry[1]}
          </StyledTableCell>
        ))
      }
    </StyledTableRow>
  );
}
BrandObjectListItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  object: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};
