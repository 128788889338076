import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import { useProject } from '../config/ProjectContext';
import GraphqlService from '../service/graphqlService';
import { useSnackbar } from '../providers/SnackbarContext';

const cleanKeyWithoutValue = (obj) => Object.keys(obj).reduce((acc, key) => {
  if (obj[key] !== '') {
    acc[key] = obj[key];
  }
  return acc;
}, {});

const parseAndCleanParams = (input) => {
  let obj;
  if (typeof input === 'string') {
    try {
      obj = JSON.parse(input);
    } catch (error) {
      console.error('Failed to parse input string:', error);
      return {};
    }
  } else if (typeof input === 'object' && input !== null) {
    obj = input;
  } else {
    console.error('Invalid input type. Expected a string or an object.');
    return {};
  }

  return cleanKeyWithoutValue(obj);
};
const useCSVDeviceRawDataExport = (params, filters, dateIni, dateEnd, rangeDates, object) => {
  const { project } = useProject();
  const [loading, setLoading] = useState(false);
  const cleanedParam = parseAndCleanParams(params);

  const openSnackBar = useSnackbar();

  // Set up the mutation outside of the exportToCSV function
  const createNotificationMutation = useMutation((variables) => GraphqlService.createNotification(variables.email, variables.notificationTitle, variables.notificationDescription));

  const exportToCSV = async () => {
    try {
      setLoading(true); // Indicate that the process has started
      const userToken = await Auth.currentAuthenticatedUser();
      const email = userToken?.signInUserSession?.idToken?.payload?.identities?.shift().userId || userToken?.attributes?.email;
      openSnackBar('CSV file is being generated', 'info');
      // Use the mutation here
      await createNotificationMutation.mutateAsync({
        email,
        notificationTitle: 'CSV file is being generated',
        notificationDescription: 'Your CSV file is being generated. You will receive a notification with the download link shortly.',
      });

      await GraphqlService.getSandboxObjectCSV(
        project.code, object, JSON.stringify(cleanedParam), filters, dateIni, dateEnd, rangeDates,
      );
    } catch (error) {
      throw new Error(error.message);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return {
    exportToCSV,
    loading,
  };
};

export default useCSVDeviceRawDataExport;
