import * as React from 'react';
import { useEffect } from 'react';
// import { Breadcrumbs } from '@material-ui/core';
// import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import BinaryList from '../components/Binary/List';

export default function Binary() {
  // WEBPAGE_______________________
  useEffect(() => {
  }, []);

  return (
    <Grid
      container
      spacing={1}
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      style={{ padding: '1em' }}
    >
      {/* <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      <Link to="/binaries">Binaries</Link>
    </Breadcrumbs> */}
      <Grid item xs={12}>
        <h1>Binaries</h1>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ height: 400, width: '100%' }}
      >
        <BinaryList />
      </Grid>
    </Grid>
  );
}
