import {
  TableCell, TableRow, useTheme, Tooltip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  React,
} from 'react';
import { formatToPrintableDate } from '../../utils/dateUtils';

export default function MachineTelemetryConsumptionListItem({
  eventMachine, id,
}) {
  // STATE________________________________
  //  state values;

  const theme = useTheme();

  // WEBPAGE

  return (
    <TableRow>
      <TableCell id={`${id}-0`} component="td" scope="release" style={{ width: '7%' }}>
        {eventMachine.serialNumber}
      </TableCell>
      <TableCell id={`${id}-1`} component="td" scope="release" style={{ width: '2%' }}>
        { (eventMachine.market) ? eventMachine.market : '-' }
      </TableCell>
      <Tooltip title="When the event occurs in the machine (local time)" arrow>
        <TableCell id={`${id}-2`} style={{ color: theme.palette.secondary.text, width: '18%' }}>
          { (eventMachine.eventTimestamp && eventMachine.eventTimestamp !== '') ? formatToPrintableDate(eventMachine.eventTimestamp) : '-'}
        </TableCell>
      </Tooltip>
      <Tooltip title="When it was processed by IoT Platform" arrow>
        <TableCell id={`${id}-3`} style={{ color: theme.palette.secondary.text, width: '18%' }}>
          { (eventMachine.serverTimestamp && eventMachine.serverTimestamp !== '') ? formatToPrintableDate(eventMachine.serverTimestamp) : '-'}
        </TableCell>
      </Tooltip>
      <TableCell id={`${id}-4`} style={{ width: '11%' }}>
        {eventMachine.eventType}
      </TableCell>
      <TableCell id={`${id}-5`} style={{ width: '5%' }}>
        { (!!eventMachine.code || eventMachine.code === 0) ? eventMachine.code : '-' }
      </TableCell>
      <TableCell id={`${id}-6`} style={{ width: '5%' }}>
        { eventMachine.description && eventMachine.description !== '' ? eventMachine.description : '-' }
      </TableCell>
    </TableRow>
  );
}
MachineTelemetryConsumptionListItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  eventMachine: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};
