// eslint-disable-next-line import/prefer-default-export
export function downloadFile(file, fileTitle, fileType) {
  const fileName = `${fileTitle}.${fileType}` || `export.${fileType}`;
  const blob = new Blob([file], { type: `text/${fileType};charset=utf-8;` });
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
