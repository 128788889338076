import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Tooltip,
  makeStyles,
  Snackbar,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ReactJson from 'react-json-view';
import cleanAllNullValues from '../../utils/cleanAllNullValues';

const useStyles = makeStyles((theme) => ({
  dialogTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: '#F3916A',
    color: theme.palette.primary.contrastText,
  },
  dialogTitleText: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '1.5rem',
  },
  button: {
    color: theme.palette.primary.contrastText,
    // blue
    backgroundColor: '#3F51B5',
    '&:hover': {
      backgroundColor: '#303F9F',
    },
    borderRadius: '5px',
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  buttonText: {
    marginRight: theme.spacing(1), // Adds spacing between the text and the icon
  },
}));
function JsonPopup({ title, jsonObject }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const dialogRef = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const jsonString = JSON.stringify(cleanAllNullValues(jsonObject), null, 2); // Format JSON with indentation

  const copyToClipboard = () => {
    navigator.clipboard.writeText(jsonString).then(() => {
      setSnackbarMessage('JSON copied to clipboard!');
      setSnackbarOpen(true);
    }).catch(() => {
      setSnackbarMessage('Failed to copy JSON to clipboard.');
      setSnackbarOpen(true);
    });
  };

  return (
    <div>
      {/* Button to open the dialog */}
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Show JSON
      </Button>

      {/* Dialog for showing JSON */}
      <Dialog
        ref={dialogRef}
        open={open}
        onClose={handleClose}
        aria-labelledby="json-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <div className={classes.dialogTitleContainer}>
          <Typography className={classes.dialogTitleText}>
            {title}
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent dividers style={{ backgroundColor: '#272822' }}>
          {/* Display JSON with colors using ReactJson */}
          <ReactJson
            src={cleanAllNullValues(jsonObject)}
            theme="monokai" // Theme for colored syntax highlighting
            collapsed={false}
            displayDataTypes={false}
            enableClipboard={false}
          />
        </DialogContent>
        <DialogActions>
          {/* Button to copy JSON to clipboard */}
          <Tooltip title="Copy JSON">
            <Button className={classes.button} onClick={copyToClipboard}>
              <span className={classes.buttonText}>COPY JSON TO CLIPBOARD</span>
              <FileCopyIcon />
            </Button>
          </Tooltip>
        </DialogActions>
      </Dialog>

      {/* Snackbar for copy feedback */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </div>
  );
}

JsonPopup.propTypes = {
  title: PropTypes.string.isRequired,
  jsonObject: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
};

export default JsonPopup;
