/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */

// format the value returned to be printed in the page
// values, true, false and null have to have special treatment
function formatOutput(value) {
  let result;
  if (value !== null && value !== undefined) {
    result = `${value}`;
  } else {
    result = '-';
  }
  return result;
}

function extractValuesFromObject(obj, transformedObj, previousKey) {
  // Iterate through each property of the object
  Object.keys(obj).forEach((key) => {
    if (key === 'messageBody') {
      transformedObj[key] = JSON.stringify(obj[key]);
      return;
    }
    const value = obj[key];
    const newKey = previousKey ? `${previousKey}.${key}` : key;
    // Check if the value is an object (but not null)
    if (value && Array.isArray(value)) {
      // Use recursion to extract the nested array
      extractValuesFromArray(value, transformedObj, newKey);
    } else if (value && typeof value === 'object') {
      // Use recursion to extract the nested object
      extractValuesFromObject(value, transformedObj, newKey);
    } else {
      // Add the key-value pair to the transformed object as is
      transformedObj[newKey] = formatOutput(value);
    }
  });
}

function extractValuesFromArray(array, transformedObj, previousKey) {
  array.forEach((item, index) => {
    const newKey = `${previousKey}[${index}]`;
    if (item && typeof item === 'object') {
      // Use recursion to extract the nested object
      extractValuesFromObject(item, transformedObj, newKey);
    } else {
      // Add the key-value pair to the transformed object as is
      transformedObj[newKey] = formatOutput(item);
    }
  });
}

export function transformComplexJson(jsonData) {
  // Extract the relevant data
  // Initialize an array to hold the transformed objects
  const transformedObjects = [];
  if (jsonData != null) {
    const brandObjects = jsonData;
    // Iterate through each brand object
    brandObjects.forEach((obj) => {
      // Initialize an object to hold the key-value pairs for this brand object
      const transformedObj = {};
      // Extract the key-value pairs from the object
      extractValuesFromObject(obj, transformedObj);
      // Add the transformed object to the array
      transformedObjects.push(transformedObj);
    });
  }
  return transformedObjects;
}

// extracts the keys from all the data received for a page
export function extractKeys(obj, parentKey = '', keys = [], objKeys = []) {
  Object.keys(obj).forEach((key) => {
    const fullKey = parentKey ? `${key}` : key;
    const value = obj[key];

    // delete from the list the possible parents that for some rows are null
    if (fullKey.includes('.')) {
      objKeys.push(fullKey.substring(0, fullKey.indexOf('.')));
    }

    // If the value is an object (but not null), recurse into it
    if (value && typeof value === 'object' && key !== 'extra') {
      extractKeys(value, fullKey, keys, objKeys);
    } else {
      keys.push(fullKey);
    }
  });

  return { headers: [...new Set(keys)], objKeys };
}

// delete from the header list all the parents that have some info in some rows and are null in the others (ie: dict_event)
export function deleteParentfromHeader(dataHeaderExport) {
  return dataHeaderExport.headers.filter((item) => !dataHeaderExport.objKeys.includes(item, 0));
}

// sort headers alphabetically to avoid pages with different column orders
export function orderHeader(headerExport) {
  headerExport.sort((a, b) => a.localeCompare(b, 'en', { numeric: true }));
}

export function convertToCSV(items, headers) {
  // Convert Object to JSON
  const objArray = JSON.stringify(items);

  const rows = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';

  headers.forEach((h) => {
    str += `${h};`;
  });
  str = `${str.substring(0, str.length - 1)}\r\n`;

  rows.forEach((row) => {
    let line = '';
    const columns = typeof row !== 'object' ? JSON.parse(row) : Object.values(row);

    columns.forEach((column) => {
      if (line !== '') {
        line += ';';
      }
      if (typeof column === 'object') {
        line += '-';
      } else {
        // replace if there is a ; in the data because is the csv separator
        line += column.replaceAll(';', ',');
      }
    });
    str += `${line}\r\n`;
  });

  return `${str}`;
}

export function getColumnInclude(data, columns) {
  if (!data || columns === '' || (Array.isArray(data) && data.length === 0)) {
    return data;
  }
  if (!Array.isArray(columns)) {
    // columns is a string
    const div = columns.split('.');
    // save on dataAux the value of dataList of the column saved in div[0]
    let rows = data[div[0]];
    if (div.length > 1) {
      // if there are more than one level of subobjects
      // we need to iterate over the subobjects
      for (let i = 1; i < div.length; i += 1) {
        rows = rows.map((item) => item[div[i]]);
      }
    }
    return rows;
  }
  // columns is an array
  let rows = [];
  let dataAux = [];
  if (Array.isArray(data)) {
    dataAux = data;
  } else {
    dataAux.push(data);
  }
  // dataAux now is an array of the objects received
  dataAux.forEach((item) => {
    // add to rows the result of getting the values
    const result = getRowsFromItem(item, columns);
    rows = rows.concat(result);
  });
  return rows;
}

export function getRowsFromItem(item, columns) {
  let rows = [];
  const extraColumns = [];
  columns.forEach((column) => {
    // if the value of the column is an array we need to iterate over the subobjects
    if (Array.isArray(item[column])) {
      const div = column.split('.');
      // save on item the value of dataList of the column saved in div[0]
      rows = item[div[0]];
      if (div.length > 1) {
        // if there are more than one level of subobjects
        // we need to iterate over the subobjects
        for (let i = 1; i < div.length; i += 1) {
          rows.map((it) => it[div[i]]);
        }
      }
    } else {
      // if the column is not an array we need to add it to the extraColumns array for them to be added later to the rows
      extraColumns.push({ [column]: item[column] });
    }
  });

  if (extraColumns.length > 0) {
    // add a new field to each object in the rows array with the extraColumns
    extraColumns.forEach((extraColumn) => {
      rows = rows.map((it) => ({ ...it, ...extraColumn }));
    });
  }
  return rows;
}

export function completeNullDataAccordingToHeader(headers) {
  return function (item) {
    const tmpItem = {};
    headers.forEach((header) => {
      tmpItem[header] = item[header] ? item[header] : '-';
    });
    return tmpItem;
  };
}
