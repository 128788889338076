/* eslint-disable indent */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid'; // Import uuidv4 for unique keys
import JsonPopup from '../JsonPopup';

const useStyles = makeStyles((theme) => ({
  dialogTitleContainer: {
    backgroundColor: '#F3916A',
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    marginBottom: theme.spacing(4),
  },
  dialogContent: {
    padding: theme.spacing(3),
  },
}));

function DeploymentResultPopup({ open, onClose, deployResultData }) {
  const classes = useStyles();

  const dialogRef = useRef(null);

  return (
    <Dialog
      ref={dialogRef}
      open={open}
      onClose={onClose}
      aria-labelledby="deploy-result-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle
        id="deploy-result-dialog-title"
        className={classes.dialogTitleContainer}
      >
        Deployment Result
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        {/* Deploy ID and Wave ID */}
        {deployResultData.deployID && (
          <TextField
            label="Deploy ID"
            value={deployResultData.deployID || '-'}
            fullWidth
            InputProps={{ readOnly: true }}
            margin="normal"
            variant="outlined"
            className={classes.textField}
          />
        )}

        {deployResultData.waveId && (
          <TextField
            label="Wave ID"
            value={deployResultData.waveId || '-'}
            fullWidth
            InputProps={{ readOnly: true }}
            margin="normal"
            variant="outlined"
            className={classes.textField}
          />
        )}

        {/* Deploy IDs Table */}
        {Array.isArray(deployResultData.deployIDS) && deployResultData.deployIDS.length > 0 && (
          <>
            <Typography variant="h6">
              Deploy IDs
            </Typography>
            <TableContainer component={Paper} className={classes.tableContainer}>
              <Table>
                <TableBody>
                  {deployResultData.deployIDS.map((id) => (
                    <TableRow key={uuidv4()}>
                      <TableCell>{id}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        {/* New Deploy IDs Table */}
        {Array.isArray(deployResultData.newDeployIDs) && deployResultData.newDeployIDs.length > 0 && (
          <>
            <Typography variant="h6">
              New Deploy IDs
            </Typography>
            <TableContainer component={Paper} className={classes.tableContainer}>
              <Table>
                <TableBody>

                  {deployResultData.newDeployIDs.map((id) => (
                    <TableRow key={uuidv4()}>
                      <TableCell>{id}</TableCell>
                    </TableRow>
                  ))}

                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        {/* Already Existing Deploy IDs Table */}
        {Array.isArray(deployResultData.alreadyExistingDeployIDs) && deployResultData.alreadyExistingDeployIDs.length > 0 && (
          <>
            <Typography variant="h6">
              Already Existing Deploy IDs
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {
                    deployResultData.alreadyExistingDeployIDs.map((id) => (
                      <TableRow key={uuidv4()}>
                        <TableCell>{id}</TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </DialogContent>
      <DialogActions>

        <JsonPopup
          title="Asset JSON"
          jsonObject={typeof deployResultData === 'object' && deployResultData !== null ? deployResultData : {}}
        />

        <Button onClick={onClose} color="primary" variant="contained">
          Close
        </Button>

      </DialogActions>
    </Dialog>
  );
}

DeploymentResultPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  deployResultData: PropTypes.shape({
    deployID: PropTypes.string,
    waveId: PropTypes.string,
    deployIDS: PropTypes.arrayOf(PropTypes.string),
    newDeployIDs: PropTypes.arrayOf(PropTypes.string),
    alreadyExistingDeployIDs: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default DeploymentResultPopup;
