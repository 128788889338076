const roles = require('./roles.json');

function mapRoleToPermissions(cognitoGroups) {
  const permissions = [];
  cognitoGroups.forEach((group) => {
    const project = group.split(':')[0];
    const role = group.split(':')[1];
    const rolesPermissions = roles.find((item) => item.name === role);
    if (rolesPermissions) {
      rolesPermissions.permissions
        .forEach((item) => permissions.push(`${project}:${item}`));
    }
  });
  return permissions;
}

export default mapRoleToPermissions;
