/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import {
  Box, makeStyles, LinearProgress, Grid,
  Typography, Table, Switch,
  TableBody, TableContainer, TableRow, TableCell, Button, FormControlLabel,
} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { Bar, Line } from 'react-chartjs-2';
import moment from 'moment';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from 'chart.js';
import {
  KeyboardDatePicker, MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import SearchIcon from '@material-ui/icons/Search';
import PageBanner from '../../components/utils/PageBanner';
import { ReactComponent as CalendarIcon } from '../../assets/ic_calendar.svg';
import GraphqlService from '../../service/graphqlService';
import MessageCard from '../../components/utils/MessageCard';
import ProgressDialog from '../../components/utils/ProgressDialog';
import { useProject } from '../../config/ProjectContext';
// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
);

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
  },
  button: {
    float: 'right',
    margin: '0 0 16px 24px',
  },
  tableButton: {
    height: 48,
    justifyContent: 'center',
    margin: 4,
    color: 'white',
    borderRadius: 12,
  },
  tableIcons: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 16,
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  info: {
    backgroundColor: '#E2EAF9', // '#f5f5f5
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: 12,
  },
  infoTypo: {
    fontSize: 12,
  },
}));

const TotalUsersPerDayChart = ({ data, selectedStartDate, selectedEndDate }) => {
  const classes = useStyles(); // Moved to top-level
  if (!data || !data.totalUsersPerDay) {
    return null;
  }

  const { totalUsersPerDay } = data;

  const start = moment(selectedStartDate);
  const end = moment(selectedEndDate);
  const daysDifference = end.diff(start, 'days');

  const labels = [];
  if (daysDifference < 30) {
    // Display dates excluding weekends
    const current = start.clone();
    while (current <= end) {
      if (current.day() !== 0 && current.day() !== 6) {
        labels.push(current.format('YYYY-MM-DD'));
      }
      current.add(1, 'day');
    }
  } else if (daysDifference <= 180) {
    // Display weeks
    const current = start.clone().startOf('isoWeek');
    while (current <= end) {
      labels.push(current.format('YYYY-[W]WW-YYYY'));
      current.add(1, 'week');
    }
  } else {
    // Display months
    const current = start.clone().startOf('month');
    while (current <= end) {
      labels.push(current.format('YYYY-MM'));
      current.add(1, 'month');
    }
  }

  // Fill values array with data matching the labels
  const values = labels.map((label) => {
    const labelDate = moment(label, ['YYYY-MM-DD', 'YYYY-[W]WW-YYYY', 'YYYY-MM']);
    let totalValue = 0;
    let count = 0;

    Object.entries(totalUsersPerDay).forEach(([date, value]) => {
      const entryDate = moment(date);
      if (daysDifference < 30) {
        if (labelDate.isSame(entryDate, 'day')) {
          totalValue = value;
          count = 1;
        }
      } else if (daysDifference <= 180) {
        if (labelDate.isSame(entryDate, 'isoWeek') && labelDate.year() === entryDate.year()) {
          totalValue += value;
          count += 1;
        }
      } else if (labelDate.isSame(entryDate, 'month') && labelDate.year() === entryDate.year()) {
        totalValue += value;
        count += 1;
      }
    });

    return count > 0 ? totalValue / count : 0;
  });

  const chartData = {
    labels,
    datasets: [
      {
        type: 'bar',
        label: 'Average Users',
        data: values,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgb(75, 192, 192)',
        borderWidth: 1,
      },
      {
        type: 'line',
        label: 'Trend',
        data: values,
        borderColor: 'rgb(255, 99, 132)',
        fill: false,
        tension: 0.1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      x: {
        stacked: false, // Disable stacking on the x-axis
      },
      y: {
        beginAtZero: true,
        stacked: false, // Disable stacking on the y-axis
      },
    },
  };

  // State for pagination
  const [page, setPage] = React.useState(0);

  // Handlers for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // order by day desc totalUsersPerDay
  const totalActionsData = Object.entries(totalUsersPerDay).sort((a, b) => new Date(b[0]) - new Date(a[0]));

  const pageData = totalActionsData.slice(page * 5, page * 5 + 5);

  return (
    <>
      <Grid className={classes.info}>
        <Typography variant="body" className={classes.infoTypo}>
          Calculates and lists the number of users per day.
        </Typography>
      </Grid>
      <Grid container spacing={12}>
        <Grid item xs={12} md={6}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Number of Users</TableCell>
                </TableRow>
                {pageData.map(([date, count]) => (
                  <TableRow key={date}>
                    <TableCell>{date}</TableCell>
                    <TableCell>{count}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5]}
              component="div"
              count={totalActionsData.length}
              rowsPerPage={5}
              page={page}
              onPageChange={handleChangePage}
            />
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <Bar data={chartData} options={options} />
        </Grid>
      </Grid>
    </>
  );
};

TotalUsersPerDayChart.propTypes = {
  data: PropTypes.object.isRequired,
  selectedStartDate: PropTypes.object.isRequired,
  selectedEndDate: PropTypes.object.isRequired,
};

const TotalActionsPerDayChart = ({ data, selectedStartDate, selectedEndDate }) => {
  const classes = useStyles(); // Moved to top-level
  if (!data || !data.totalActionsPerDay) {
    return null;
  }

  const { totalActionsPerDay } = data;
  const start = moment(selectedStartDate);
  const end = moment(selectedEndDate);
  const daysDifference = end.diff(start, 'days');

  const labels = [];
  if (daysDifference < 30) {
    // Display dates excluding weekends
    const current = start.clone();
    while (current <= end) {
      if (current.day() !== 0 && current.day() !== 6) {
        labels.push(current.format('YYYY-MM-DD'));
      }
      current.add(1, 'day');
    }
  } else if (daysDifference <= 180) {
    // Display weeks
    const current = start.clone().startOf('isoWeek');
    while (current <= end) {
      labels.push(current.format('YYYY-[W]WW-YYYY'));
      current.add(1, 'week');
    }
  } else {
    // Display months
    const current = start.clone().startOf('month');
    while (current <= end) {
      labels.push(current.format('YYYY-MM'));
      current.add(1, 'month');
    }
  }

  // Fill values array with data matching the labels
  const values = labels.map((label) => {
    const labelDate = moment(label, ['YYYY-MM-DD', 'YYYY-[W]WW-YYYY', 'YYYY-MM']);
    let totalValue = 0;
    let count = 0;

    Object.entries(totalActionsPerDay).forEach(([date, value]) => {
      const entryDate = moment(date);
      if (daysDifference < 30) {
        if (labelDate.isSame(entryDate, 'day')) {
          totalValue = value;
          count = 1;
        }
      } else if (daysDifference <= 180) {
        if (labelDate.isSame(entryDate, 'isoWeek') && labelDate.year() === entryDate.year()) {
          totalValue += value;
          count += 1;
        }
      } else if (labelDate.isSame(entryDate, 'month') && labelDate.year() === entryDate.year()) {
        totalValue += value;
        count += 1;
      }
    });

    return count > 0 ? totalValue / count : 0;
  });

  const chartData = {
    labels,
    datasets: [{
      label: 'Average Actions',
      data: values,
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1,
    }],
  };

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      x: {
        stacked: false, // Disable stacking on the x-axis
      },
      y: {
        beginAtZero: true,
        stacked: false, // Disable stacking on the y-axis
      },
    },
  };

  // State for pagination
  const [page, setPage] = React.useState(0);

  // Handlers for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Get the current page data
  const totalActionsData = Object.entries(totalActionsPerDay);
  const pageData = totalActionsData.slice(page * 5, page * 5 + 5);

  return (
    <>
      <Grid className={classes.info}>
        <Typography variant="body" className={classes.infoTypo}>
          Calculates and lists the number of user actions that occurred on each day within a specified period.
        </Typography>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Number of User Actions</TableCell>
                </TableRow>
                {pageData.map(([date, count]) => (
                  <TableRow key={date}>
                    <TableCell>{date}</TableCell>
                    <TableCell>{count}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5]}
              component="div"
              count={totalActionsData.length}
              rowsPerPage={5}
              page={page}
              onPageChange={handleChangePage}
            />
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <Line data={chartData} options={options} />
        </Grid>
      </Grid>
    </>
  );
};

TotalActionsPerDayChart.propTypes = {
  data: PropTypes.object.isRequired,
  selectedStartDate: PropTypes.object.isRequired,
  selectedEndDate: PropTypes.object.isRequired,
};

const ActiveUsers = ({ data }) => {
  const classes = useStyles(); // Moved to top-level
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <Grid className={classes.info}>
        <Typography variant="body" className={classes.infoTypo}>
          Identifies and lists users who have engaged with the platform within the specified timeframe.
        </Typography>
      </Grid>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>First Action</TableCell>
              <TableCell>Last Action</TableCell>
            </TableRow>
            {data.activeUsers
              // order by user.lastaction in desc
              .sort((a, b) => new Date(b.lastAction) - new Date(a.lastAction))
              .slice(page * 5, page * 5 + 5)
              .map((user) => (
                <TableRow key={user.cognitoEmail}>
                  <TableCell>{user.cognitoEmail}</TableCell>
                  <TableCell>{user.firstAction}</TableCell>
                  <TableCell>{user.lastAction}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5]}
        component="div"
        count={data.activeUsers.length}
        rowsPerPage={5}
        page={page}
        onChangePage={handleChangePage}
      />
    </>
  );
};

ActiveUsers.propTypes = {
  data: PropTypes.object.isRequired,
};

const NewUsers = ({ data }) => {
  const classes = useStyles(); // Moved to top-level
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <Grid className={classes.info}>
        <Typography variant="body" className={classes.infoTypo}>
          Identifies users who have initiated their first action within a specified date range.
        </Typography>
      </Grid>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>First Action</TableCell>
            </TableRow>
            {data.newUsers
              .slice(page * 5, page * 5 + 5)
              .map((user) => (
                <TableRow key={user.cognitoEmail}>
                  <TableCell>{user.cognitoEmail}</TableCell>
                  <TableCell>{user.firstAction}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5]}
        component="div"
        count={data.activeUsers.length}
        rowsPerPage={5}
        page={page}
        onChangePage={handleChangePage}
      />
    </>
  );
};

NewUsers.propTypes = {
  data: PropTypes.object.isRequired,
};

const ActionsPerTypeChart = ({ data }) => {
  const classes = useStyles(); // Moved to top-level

  // Calculate action types counts
  const actionTypes = {};
  data.actionPerDayPerType.forEach((day) => {
    Object.entries(day).forEach(([, actions]) => {
      Object.keys(actions).forEach((type) => {
        actionTypes[type] = (actionTypes[type] || 0) + Number(actions[type]);
      });
    });
  });
  const entries = Object.entries(actionTypes);
  // Sort array based on the values
  entries.sort((a, b) => b[1] - a[1]);
  // Convert the sorted array back to an object
  const actionTypesSorted = Object.fromEntries(entries);
  // Prepare data for the chart
  const chartData = {
    labels: Object.keys(actionTypes),
    datasets: [{
      label: 'Total Actions Per Type',
      data: Object.values(actionTypes),
      backgroundColor: 'rgba(255, 206, 86, 0.6)',
    }],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  // States for pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Handlers for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Get the current page data
  const actionsTypeArray = Object.entries(actionTypesSorted);
  const pageData = actionsTypeArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <Grid className={classes.info}>
        <Typography variant="body" className={classes.infoTypo}>
          Aggregates and displays the number of actions performed, categorized by type, over a specified period.
        </Typography>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Action Type</TableCell>
                <TableCell>Number of Actions</TableCell>
              </TableRow>
              {pageData.map(([type, count]) => (
                <TableRow key={type}>
                  <TableCell>{type}</TableCell>
                  <TableCell>{count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={actionsTypeArray.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Bar data={chartData} options={options} />
        </Grid>
      </Grid>
    </>
  );
};

ActionsPerTypeChart.propTypes = {
  data: PropTypes.object.isRequired,
};

const ActionsSectionsPerTypeChart = ({ data }) => {
  const classes = useStyles(); // Moved to top-level

  // Calculate action types counts
  const actionTypes = { Telemetry: 0, FOTA: 0 };
  data.actionPerDayPerType.forEach((day) => {
    Object.entries(day).forEach(([, actions]) => {
      Object.keys(actions).forEach((type) => {
        const count = Number(actions[type]);
        if (type.toLowerCase().includes('asset') || type.toLowerCase().includes('deploy') || type.toLowerCase().includes('lifecycle')) {
          actionTypes.FOTA += count;
        } else {
          actionTypes.Telemetry += count;
        }
      });
    });
  });

  // Prepare data for the chart
  const chartData = {
    labels: Object.keys(actionTypes),
    datasets: [{
      label: 'Total Actions Per Service',
      data: Object.values(actionTypes),
      backgroundColor: 'rgba(255, 206, 86, 0.6)',
    }],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  // States for pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Handlers for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Get the current page data
  const actionsTypeArray = Object.entries(actionTypes);
  const pageData = actionsTypeArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <Grid className={classes.info}>
        <Typography variant="body" className={classes.infoTypo}>
          Aggregates and displays the number of actions performed, categorized by section, over a specified period.
        </Typography>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Action per Service</TableCell>
                <TableCell>Number of Actions</TableCell>
              </TableRow>
              {pageData.map(([category, count]) => (
                <TableRow key={category}>
                  <TableCell>{category}</TableCell>
                  <TableCell>{count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={actionsTypeArray.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Bar data={chartData} options={options} />
        </Grid>
      </Grid>
    </>
  );
};

ActionsSectionsPerTypeChart.propTypes = {
  data: PropTypes.object.isRequired,
};

const ActionsPerDayPerTypeChart = ({ data }) => {
  const classes = useStyles(); // Moved to top-level

  // Extracting dates and action types from the data
  const dates = data.actionPerDayPerType.map((item) => Object.keys(item)[0]);
  const actionTypesSet = new Set();

  data.actionPerDayPerType.forEach((day) => {
    const actions = Object.values(day)[0];
    Object.keys(actions).forEach((type) => actionTypesSet.add(type));
  });

  const actionTypes = Array.from(actionTypesSet);

  // Sort dates for the chart in ascending order
  const sortedDates = [...dates].sort((a, b) => new Date(a) - new Date(b));

  // Initialize chart datasets with zeros
  const chartDataSets = actionTypes.map((type) => ({
    label: type,
    data: Array(sortedDates.length).fill(0),
    backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.6)`,
  }));

  // Fill datasets with actual data
  sortedDates.forEach((date, index) => {
    const day = data.actionPerDayPerType.find((item) => Object.keys(item)[0] === date);
    const actions = day[date];
    actionTypes.forEach((type, typeIndex) => {
      chartDataSets[typeIndex].data[index] = actions[type] || 0;
    });
  });

  const chartData = {
    labels: sortedDates,
    datasets: chartDataSets,
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        stacked: true, // Enable stacking on the x-axis
      },
      y: {
        stacked: true, // Enable stacking on the y-axis
      },
    },
  };

  // State for pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);

  // Handlers for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };

  // Get the current page data in descending order
  const sortedTableData = [...data.actionPerDayPerType].sort((a, b) => new Date(Object.keys(b)[0]) - new Date(Object.keys(a)[0]));
  const pageData = sortedTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <Grid className={classes.info}>
        <Typography variant="body" className={classes.infoTypo}>
          Tracks and reports the number of specific types of actions that occur each day within a set period.
        </Typography>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
              {pageData.map((item) => (
                <TableRow key={Object.keys(item)[0]}>
                  <TableCell>{Object.keys(item)[0]}</TableCell>
                  <TableCell>
                    {Object.entries(item[Object.keys(item)[0]]).reduce((acc, [type, count]) => `${acc}${type}: ${count}, `, '').slice(0, -2)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[3]}
            component="div"
            count={data.actionPerDayPerType.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Bar data={chartData} options={options} />
        </Grid>
      </Grid>
    </>
  );
};

ActionsPerDayPerTypeChart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default function KPIsCharts() {
  const classes = useStyles(); // Moved to top-level
  const { project } = useProject();

  const [selectedStartDate, setSelectedStartDate] = useState(moment().subtract(1, 'days'));
  const [selectedEndDate, setSelectedEndDate] = useState(moment());
  const [selectedFormattedStartDate, setSelectedFormattedStartDate] = useState(null);
  const [selectedFormattedEndDate, setSelectedFormattedEndDate] = useState(null);
  const [searchButton, setSearchButton] = useState(false);
  const [seeAllProjects, setSeeAllProjects] = useState(false);
  const getActionStatistics = async () => GraphqlService.getActionStatistics(selectedStartDate, selectedEndDate, seeAllProjects ? undefined : project?.code);

  const setLastDay = () => {
    setSelectedStartDate(moment().subtract(1, 'days'));
    setSelectedEndDate(moment());
    setSelectedFormattedStartDate(moment().subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ssZ'));
    setSelectedFormattedEndDate(moment().format('YYYY-MM-DDTHH:mm:ssZ'));
    setSearchButton(!searchButton);
  };

  const setLastWeek = () => {
    setSelectedStartDate(moment().subtract(7, 'days'));
    setSelectedEndDate(moment());
    setSelectedFormattedStartDate(moment().subtract(7, 'days').format('YYYY-MM-DDTHH:mm:ssZ'));
    setSelectedFormattedEndDate(moment().format('YYYY-MM-DDTHH:mm:ssZ'));
    setSearchButton(!searchButton);
  };

  const setLastMonth = () => {
    setSelectedStartDate(moment().subtract(1, 'months'));
    setSelectedEndDate(moment());
    setSelectedFormattedStartDate(moment().subtract(1, 'months').format('YYYY-MM-DDTHH:mm:ssZ'));
    setSelectedFormattedEndDate(moment().format('YYYY-MM-DDTHH:mm:ssZ'));
    setSearchButton(!searchButton);
  };

  const handleChangeStartDate = (date) => {
    if (date === null) {
      setSelectedStartDate(null);
      selectedFormattedStartDate(null);
    } else {
      setSelectedStartDate(moment(date));
      const dateFormatted = moment(date).format('YYYY-MM-DDTHH:mm:ssZ');
      setSelectedFormattedStartDate(dateFormatted);
    }
  };

  const handleChangeEndDate = (date) => {
    if (date === null) {
      setSelectedEndDate(null);
      selectedFormattedEndDate(null);
    } else {
      setSelectedEndDate(moment(date));
      const dateFormatted = moment(date).format('YYYY-MM-DDTHH:mm:ssZ');
      setSelectedFormattedEndDate(dateFormatted);
    }
  };
  const {
    data,
    error,
    isLoading,
  } = useQuery(['kpis', searchButton, seeAllProjects, project?.code], () => getActionStatistics(), {
    cacheTime: 0,
    select: (res) => JSON.parse(res),
  });

  const renderContent = () => {
    if (error) {
      return <MessageCard message={`Error: ${error.message}`} />;
    }

    if (isLoading) {
      return (
        <>
          <ProgressDialog open header="Retrieving KPIs users, please wait" />
          <LinearProgress id="linear-progress-id" style={{ width: '100%' }} />
        </>
      );
    }

    return (
      <Box className={classes.background}>
        {data && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h5">
                Active Users (
                {data?.activeUsers?.length}
                )
              </Typography>
              <ActiveUsers data={data} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                Total Users (
                {data?.totalCognitoUsers}
                )
              </Typography>
              <TotalUsersPerDayChart data={data} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Total Actions</Typography>
              <TotalActionsPerDayChart data={data} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Actions Per Type</Typography>
              <ActionsPerTypeChart data={data} />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5">Services</Typography>
              <ActionsSectionsPerTypeChart data={data} />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5">Actions Per Day Per Type</Typography>
              <ActionsPerDayPerTypeChart data={data} />
            </Grid>
          </Grid>
        )}
      </Box>
    );
  };

  return (
    <Box>
      <PageBanner
        title="KPIS CHARTS"
      />
      <Grid
        className={classes.background}
        container
        spacing={0}
        direction="column"
        alignItems="stretch"
      >

        <Grid container direction="row" spacing={2}>
          <div className={classes.tableIcons}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                autoOk
                id="inputDateFrom"
                variant="inline"
                orientation="landscape"
                inputVariant="filled"
                label="From"
                value={selectedStartDate}
                format="DD/MM/yyyy"
                onChange={(date) => handleChangeStartDate(date)}
                color="secondary"
                keyboardIcon={<CalendarIcon />}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                autoOk
                id="inputDateTo"
                variant="inline"
                orientation="landscape"
                inputVariant="filled"
                label="To"
                value={selectedEndDate}
                format="DD/MM/yyyy"
                onChange={(date) => handleChangeEndDate(date)}
                color="secondary"
                keyboardIcon={<CalendarIcon />}
              />
            </MuiPickersUtilsProvider>
            <Button id="btnSearch" type="form" startIcon={<SearchIcon />} className={classes.tableButton} color="secondary" variant="contained" onClick={() => setSearchButton(!searchButton)}> Search </Button>
            <Button id="btnLastDay" type="form" startIcon={<SearchIcon />} className={classes.tableButton} color="secondary" variant="contained" onClick={setLastDay}> Last Day </Button>
            <Button id="btnLastWeek" type="form" startIcon={<SearchIcon />} className={classes.tableButton} color="secondary" variant="contained" onClick={setLastWeek}> Last Week </Button>
            <Button id="btnLastMonth" type="form" startIcon={<SearchIcon />} className={classes.tableButton} color="secondary" variant="contained" onClick={setLastMonth}> Last Month </Button>
          </div>
          <div className={classes.tableIcons}>
            {/* /** switch */}
            <FormControlLabel
              control={<Switch checked={seeAllProjects} onChange={(e) => setSeeAllProjects(e.target.checked)} />}
              label="See all projects statistics"
            />
          </div>
        </Grid>
        {renderContent()}

      </Grid>
    </Box>
  );
}
