export const AppSyncAPIKey = 'da2-iwdiflpjmjbunjonycxq3h2yse';
export const UserPoolId = 'eu-west-1_YMMGBKWAX';
export const UserPoolClientId = '19tecch8e75mbl418eod0fi4i7';
export const GraphQLAPIURL = 'https://api.stag.alm.niotp.com/graphql';
export const IdentityPoolId = 'eu-west-1:b7bd85b1-7563-4d44-bd61-70c65b50590c';
export const target = 'STAG';
export const region = 'eu-west-1';

// STAG
// export const UserPoolClientId = '19tecch8e75mbl418eod0fi4i7';
// export const AppSyncAPIKey = 'da2-iwdiflpjmjbunjonycxq3h2yse';
// export const UserPoolId = 'eu-west-1_YMMGBKWAX';
// export const GraphQLAPIURL = 'https://api.stag.alm.niotp.com/graphql';
// export const IdentityPoolId = 'eu-west-1:b7bd85b1-7563-4d44-bd61-70c65b50590c';
// export const target = 'STAG';
// export const region = 'eu-west-1';

// eslint-disable-next-line import/no-dynamic-require
const env = require(`./env.${target}`);

export const config = {
  aws_project_region: region,
  aws_user_pools_id: UserPoolId,
  aws_user_pools_web_client_id: UserPoolClientId,
  aws_appsync_graphqlEndpoint: GraphQLAPIURL,
  aws_appsync_apiKey: AppSyncAPIKey,
  aws_appsync_authenticationType: 'AWS_LAMBDA',
  Auth: {
    identityPoolId: IdentityPoolId,
    region,
    userPoolId: UserPoolId,
    userPoolWebClientId: UserPoolClientId,
  },
  Storage: {
    AWSS3: {
      bucket: `alm-graphql-files-bucket-${target.toLowerCase()}-${region}`,
      region,
    },
  },
  oauth: {
    domain: env.authUri,
    redirectSignIn: env.redirectUri,
    redirectSignOut: env.redirectUri,
    responseType: 'token',
    scope: ['openid'],
  },
  env,
};

export default config;
