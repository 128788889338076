/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import jwt_decode from 'jwt-decode';

function useRole() {
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchRole() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const decodedToken = jwt_decode(user.signInUserSession.accessToken.jwtToken);
        if (decodedToken) {
          const rawGroups = decodedToken['cognito:groups'] ? decodedToken['cognito:groups'] : decodedToken.groups;
          if (rawGroups[0] === 'rootAccess') {
            setRole('rootAccess');
            return;
          }
          setRole(rawGroups[0].split(':')[1]);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    fetchRole();
  }, []);

  return { role, loading, error };
}

export default useRole;
