import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import {
  makeStyles, Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  title: {
    marginBottom: theme.spacing(4),
    fontSize: 20,
    color: theme.palette.primary.main,
  },
  dot: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    margin: theme.spacing(0.5),
  },
  dotsContainer: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  progress: {
    color: theme.palette.secondary.main,
  },
}));

const LoadingAnimation = ({ title }) => {
  const classes = useStyles();

  // Colors for the bouncing dots
  const colors = ['#F3916A', '#5374AD', '#F3916A'];

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>{title}</Typography>
      <div className={classes.dotsContainer}>
        {[0, 1, 2].map((index) => (
          <motion.div
            key={index}
            className={classes.dot}
            style={{ backgroundColor: colors[index] }}
            animate={{
              y: [0, -20, 0], // Bounce up and down
            }}
            transition={{
              duration: 0.5,
              repeat: Infinity,
              repeatDelay: 0.2,
              delay: index * 0.2, // Create staggered effect for each dot
            }}
          />
        ))}
      </div>
    </div>
  );
};

LoadingAnimation.propTypes = {
  title: PropTypes.string.isRequired,
};

export default LoadingAnimation;
