import {
  TableCell, TableRow, Tooltip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  React,
} from 'react';
import { formatToPrintableDate } from '../../utils/dateUtils';

export default function MachineTelemetryFOTAListItem({
  eventMachine, widths, id,
}) {
  // STATE________________________________

  // WEBPAGE

  return (
    <TableRow>
      <TableCell id={`${id}-0`} scope="release" style={{ width: widths[0] }}>
        {eventMachine.serialNumber || '-'}
      </TableCell>
      <Tooltip title="When the phase occurs in the machine" arrow>
        <TableCell id={`${id}-1`} style={{ width: widths[1] }}>
          { (eventMachine.statusDate && eventMachine.statusDate !== '') ? formatToPrintableDate(eventMachine.statusDate) : '-'}
        </TableCell>
      </Tooltip>
      <TableCell id={`${id}-3`} style={{ width: widths[4] }}>
        { eventMachine.statusPhase || '-' }
      </TableCell>
      <TableCell id={`${id}-4`} style={{ width: widths[3] }}>
        { eventMachine.description || '-' }
      </TableCell>
      <TableCell id={`${id}-5`} style={{ width: widths[5] }}>
        { eventMachine.deploymentType || '-' }
      </TableCell>
      <TableCell id={`${id}-6`} style={{ width: widths[6] }}>
        { eventMachine.firmwareVersion || '-' }
      </TableCell>
    </TableRow>
  );
}
MachineTelemetryFOTAListItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  eventMachine: PropTypes.object.isRequired,
  widths: PropTypes.arrayOf(PropTypes.string).isRequired,
  id: PropTypes.string.isRequired,
};
