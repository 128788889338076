import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import { useProject } from '../config/ProjectContext';
import GraphqlService from '../service/graphqlService';
import { useSnackbar } from '../providers/SnackbarContext';

const useCSVExport = (params, filters, dateIni, dateEnd, rangeDates, object) => {
  const { project } = useProject();
  const [loading, setLoading] = useState(false);
  const openSnackBar = useSnackbar();
  // Define the mutation at the top level of the custom hook
  const createNotificationMutation = useMutation((variables) => GraphqlService.createNotification(variables.email, variables.notificationTitle, variables.notificationDescription));

  const exportToCSV = async () => {
    try {
      setLoading(true); // Set loading before starting async operation
      const userToken = await Auth.currentAuthenticatedUser();
      const email = userToken?.signInUserSession?.idToken?.payload?.identities?.shift().userId || userToken?.attributes?.email;
      openSnackBar('CSV file is being generated', 'info');
      // Perform mutation
      await createNotificationMutation.mutateAsync({
        email,
        notificationTitle: 'CSV file is being generated',
        notificationDescription: 'Your CSV file is being generated. You will receive a notification with the download link shortly.',
      });

      if (object.includes('machinelist')) {
        await GraphqlService.getLifeCycleObjectCSV(
          project.code, object, JSON.stringify(params), filters, dateIni, dateEnd, 90,
        );
      } else {
        await GraphqlService.getTelemetryObjectCSV(
          project.code, object, JSON.stringify(params), filters, dateIni, dateEnd, rangeDates,
        );
      }
    } catch (error) {
      throw new Error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    exportToCSV,
    loading,
  };
};

export default useCSVExport;
