/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Button, Grid,
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import * as React from 'react';
import { useEffect, useState } from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import GraphqlService from '../../service/graphqlService';
import { useProject } from '../../config/ProjectContext';

const columns = [
  { field: 'id', headerName: 'Key', width: 450 },
  { field: 'lastModified', headerName: 'Last Modified', width: 200 },
  { field: 'eTag', headerName: 'eTag', width: 130 },
  { field: 'size', headerName: 'Size', width: 90 },
  { field: 'storageClass', headerName: 'Storage Class', width: 130 },
  { field: 'userUpload', headerName: 'User Upload', width: 256 },
  { field: 'uploadTimestamp', headerName: 'Date Upload', width: 250 },
];

export default function BinaryList() {
  const [binaries, setBinaries] = useState([]);

  const { project } = useProject();

  // DATA RETRIEVAL_______________________

  async function fetchBinaries() {
    const binariesList = await GraphqlService.getBinaries(project.code);
    const tempBinaries = [];
    binariesList.forEach((o) => {
      o.id = o.s3FileProperties.key;
      o.lastModified = o.s3FileProperties.lastModified;
      o.eTag = o.s3FileProperties.eTag;
      o.size = o.s3FileProperties.size;
      o.storageClass = o.s3FileProperties.storageClass;
      tempBinaries.push(o);
    });
    setBinaries(tempBinaries);
  }

  // WEBPAGE______________________________

  useEffect(() => {
    fetchBinaries();
  }, [project]);

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Button variant="outlined" color="primary" endIcon={<RefreshIcon />} onClick={fetchBinaries}>
          Refresh
        </Button>
      </Grid>
      <Grid item style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={binaries}
          columns={columns}
          pageSize={10}
          checkboxSelection
        />
      </Grid>
    </Grid>
  );
}
