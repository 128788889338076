import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Checkbox,
  makeStyles,
  Typography,
  useTheme,
  IconButton,
  ListItem,
  ListItemText,
  InputAdornment,
  TextField,
  Box,
  TableRow,
  TableBody,
  Paper,
  TableContainer,
  Table,
  TableCell,
  withStyles,
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  React, useContext, useState,
} from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useMutation } from '@tanstack/react-query';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ReactJson from 'react-json-view';
import JsonPopup from '../JsonPopup';
import GraphqlService from '../../service/graphqlService';
import { formatToPrintableDate } from '../../utils/dateUtils';
import SelectedObjectsToDeployContext from '../../config/SelectedAssetsContext';
import Restricted from '../../authorization/Restricted';
import {
  CREATE_NON_GLOBAL_DEPLOY_PERMISSION,
} from '../../utils/constants';
import EditableInput from '../EditableInput';
import { useProject } from '../../config/ProjectContext';
import { useSnackbar } from '../../providers/SnackbarContext';
import BundleDetailPopup from '../../screens/Bundle/BundleDetailPopup';
import cleanAllNullValues from '../../utils/cleanAllNullValues';

export default function AssetAccordion({
  asset: initialAsset, id, expandedProp,
}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: 0,
      border: `thin solid ${theme.palette.background.form}`,
      color: theme.palette.primary.text,
      backgroundColor: 'white',
    },
    headingTitle: {
      fontSize: 16,
    },
    headingDate: {
      fontSize: 14,
    },
    details: {
      borderTop: `3px solid ${theme.palette.background.paper}`,
      lineHeight: '90px',
      backgroundColor: alpha(theme.palette.background.paper, 0.1),
    },
    detailsSelected: {
      lineHeight: '9f0px',
      padding: 24,
      backgroundColor: alpha(theme.palette.primary.banner, 0.1),
    },
    detailsTitle: {
      fontSize: 14,
      paddingRight: '8px',
    },
    key: {
      color: theme.palette.primary.subMain,
    },
    cardContent: {
      backgroundColor: 'white',
      boxShadow: theme.customShadows.regularShadow,
      color: theme.palette.primary.text,
      flexGrow: 1,
      borderRadius: 12,
      padding: 12,
    },
    summaryExpanded: {
      backgroundColor: alpha(theme.palette.background.paper, 0.3),
      wordBreak: 'break-word',
    },
    summaryCollapsed: {
      backgroundColor: theme.palette.background.paper,
      wordBreak: 'break-word',
    },
    selectedAsset: {
      backgroundColor: alpha(theme.palette.primary.banner, 0.3),
    },
    jsonCopyButton: {
      backgroundColor: theme.palette.primary.button,
      color: theme.palette.common.white,
      padding: '8px 16px',
      borderRadius: 12,
      '& :disabled': {
        color: theme.palette.primary.light,
      },
    },
    reactJsonContainer: {
      width: '100%',
      padding: theme.spacing(1),
      backgroundColor: 'white',
      borderRadius: theme.shape.borderRadius,
      overflowX: 'auto', // Allow horizontal scrolling if needed
      marginBottom: 18,
    },
    // try to reduce the top padding between elements ant title
    descriptionElements: {
      padding: 0,
      margin: 0,
      marginTop: -32,
    },
  }));

  const classes = useStyles();
  const theme = useTheme();
  const updateAssetMutation = useMutation((variables) => GraphqlService.updateAssetDescription(variables.projectId, variables.fileId, variables.assetDescription));
  const openSnackBar = useSnackbar();
  const [expanded, setExpanded] = useState(expandedProp || false);
  const { selectedObjectsToDeploy: selectedAssets, setSelectedObjectsToDeploy: setSelectedAssets } = useContext(SelectedObjectsToDeployContext);
  const { project } = useProject();
  const [asset, setAsset] = useState(initialAsset);

  function handleClickCheckbox(e) {
    e.stopPropagation();
    const selectedAsset = JSON.parse(e.target.value);

    if (e.target.checked) {
      setSelectedAssets([...selectedAssets, selectedAsset]);
    } else {
      setSelectedAssets(selectedAssets.filter((item) => item.fileID !== selectedAsset.fileID));
    }
  }

  async function handleDescriptionChange(description) {
    return updateAssetMutation.mutateAsync({
      projectId: project.code,
      fileId: asset.fileID,
      assetDescription: description,
    });
  }

  const handleInputChange = (description) => {
    handleDescriptionChange(JSON.stringify(description))
      .then((res) => {
        setAsset({
          ...asset,
          assetDescription: JSON.parse(res),
        });
        openSnackBar('Asset description updated successfully!', 'success');
      })
      .catch((err) => {
        openSnackBar(`An error occurred while updating the asset description. Error: ${err} `, 'error');
      });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      openSnackBar('Value copied successfully to the clipboard!', 'success');
    }).catch((err) => {
      openSnackBar('Failed to copy text to clipboard', err);
    });
  };

  const isSelected = selectedAssets.some((selectedAsset) => selectedAsset.fileID === asset.fileID);
  const determineAcordionSummaryClassName = () => {
    if (isSelected) {
      return classes.selectedAsset;
    }
    if (expanded) {
      return classes.summaryExpanded;
    }
    return classes.summaryCollapsed;
  };

  const StyledTableCell = withStyles((themeCell) => ({
    head: {
      backgroundColor: 'rgba(0, 0, 0, 0.01)',
      color: themeCell.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
    // reduce the high of the cell
    root: {
      padding: 0,
    },
    selected: {},
  }))(TableCell);

  const StyledTableRow = withStyles(() => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(0, 0, 0, 0.02)',
      },
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
      // selected
      '&$selected': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
  }))(TableRow);

  return (
    <Accordion
      id={`asset-accordion-detail-${id}`}
      expanded={expanded}
      className={classes.root}
      TransitionProps={{ unmountOnExit: true }}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon id={`${id}btnExpand`} style={{ color: theme.palette.primary.text }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={determineAcordionSummaryClassName()}
      >
        <Grid
          style={{ margin: -18 }}
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid item xs={1} sm={1}>
            <Restricted
              to={[CREATE_NON_GLOBAL_DEPLOY_PERMISSION]}
            >
              <Checkbox
                value={JSON.stringify(asset)}
                onClick={(e) => handleClickCheckbox(e)}
              />
            </Restricted>
          </Grid>
          <Grid item xs={5} sm={3}>
            <ListItem>
              <ListItemText
                id={`${id}Type`}
                primary={
                  <Typography variant="caption" color="textSecondary">Type</Typography>
                }
                secondary={<Typography variant="body1">{asset.type}</Typography>}
              />
            </ListItem>
          </Grid>

          <Grid item xs={6} sm={3}>
            <ListItem>
              <ListItemText
                id={`${id}Version`}
                primary={
                  <Typography variant="caption" color="textSecondary">Version</Typography> // Smaller, gray text
                }
                secondary={<Typography variant="body1">{!asset.assetDescription?.Version || asset.assetDescription?.Version === null ? 'Not available' : `v.${asset.assetDescription.Version}`}</Typography>}
              />
            </ListItem>
          </Grid>

          <Grid item xs={12} sm={5}>
            <ListItem>
              <ListItemText
                id={`${id}Date`}
                primary={
                  <Typography variant="caption" color="textSecondary">Created Date</Typography>
                }
                secondary={<Typography variant="body1">{formatToPrintableDate(asset.dateTime)}</Typography>}
              />
            </ListItem>
          </Grid>
        </Grid>
      </AccordionSummary>
      {/** ********* Details ********** */}
      <AccordionDetails className={isSelected ? classes.detailsSelected : classes.details}>
        <Grid container spacing={1} alignItems="stretch">

          <Grid item xs={12} md={6}>
            <TextField
              label="File ID"
              value={asset.fileID || 'No value for file ID'}
              InputProps={{
                readOnly: true,
                endAdornment: asset.fileID && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => copyToClipboard(asset.fileID)}>
                      <FileCopyIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Grid>

          {/* Chunks */}
          <Grid item xs={12} md={6}>
            <TextField
              label="Chunks"
              value={Array.isArray(asset.chunks) ? asset.chunks.map((path) => path.split('/').pop()).join(', ') : 'No info for chunks'}
              InputProps={{
                readOnly: true,
                endAdornment: Array.isArray(asset.chunks) && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => copyToClipboard(asset.chunks.map((path) => path.split('/').pop()).join(', '))}>
                      <FileCopyIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              label="Manufacturer Signature"
              value={asset.manufacturerSignature || 'There is no value for the manufacturer signature'}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </Grid>

          {/* Software requirements */}
          <Grid item xs={12} md={4}>
            <TextField
              label="Software Requirements"
              value={asset.softwareRequirements || 'No info for software requirements'}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </Grid>

          {/* Hardware requirements */}
          <Grid item xs={12} md={4}>
            <TextField
              label="Hardware Requirements"
              value={asset.hardwareRequirements || 'No info for hardware requirements'}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </Grid>

          {/* Type requirements */}
          {asset.typeRequirements && asset.typeRequirements.length > 0 && (

            <Grid item xs={12} md={12} classNane={classes.cardContent}>
              <Typography variant="subtitle1">
                Type requirements for the asset.
              </Typography>
              <Grid container spacing={1} alignItems="stretch">
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Type"
                    value={asset.typeRequirements[0].type || 'No info for type'}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    label="Software Requirements"
                    value={asset.typeRequirements[0].softwareRequirements || 'No info for software requirements'}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    label="Hardware Requirements"
                    value={asset.typeRequirements[0].hardwareRequirements || 'No info for hardware requirements'}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

          )}

          {/* Metadata */}
          {(asset.metaData.major || asset.metaData.minor || asset.metaData.build || asset.metaData.hash) && (
            <Grid item xs={12} className={classes.reactJsonContainer}>
              <Typography variant="subtitle1">
                Metadata for the asset:
              </Typography>
              <ReactJson
                src={cleanAllNullValues(asset?.metaData)}
                theme="rjv-default"
                collapsed
                displayDataTypes={false}
                enableClipboard={false}
                style={{ fontSize: '1rem', lineHeight: '1.5' }}
              />
            </Grid>
          )}

          {asset.assetDescription && (
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1">
                Detailed descriptions for the asset:
              </Typography>
              <Box display="flex" flexWrap="wrap" mt={1} mb={1}>
                {Object.entries(asset.assetDescription).map(([key, value]) => (
                  <Box key={key} className={classes.descriptionElements}>
                    <EditableInput
                      label={key}
                      value={value || '-'}
                      onChange={(change) => handleInputChange({ ...asset.assetDescription, [key]: change })}
                      fullWidth
                      margin="dense"
                    />
                  </Box>
                ))}
              </Box>
            </Grid>
          )}

          {asset.releaseIDs && asset.releaseIDs.length > 0 && (

            <Grid item xs={12} md={6} className={classes.reactJsonContainer}>
              <Typography variant="subtitle1">
                This Asset is part of the following Bundles:
              </Typography>

              <TableContainer component={Paper} className={classes.table}>
                <Table aria-label="selected assets table">

                  <TableBody>
                    {asset.releaseIDs?.map((releaseID) => (
                      <StyledTableRow key={releaseID}>
                        <StyledTableCell key={`${releaseID}`}>
                          {releaseID}
                        </StyledTableCell>
                        <StyledTableCell id={`${releaseID}-link`}>
                          <BundleDetailPopup releaseID={releaseID} />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

          )}

          {/* copy asset json with a button, align to the right */}
          <Box display="flex" justifyContent="flex-end" width="100%">
            <JsonPopup
              title="Asset JSON"
              jsonObject={typeof asset === 'object' && asset !== null ? asset : {}}
            />
          </Box>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
AssetAccordion.propTypes = {
  asset: PropTypes.instanceOf(Object),
  id: PropTypes.string,
  expandedProp: PropTypes.bool,
};
AssetAccordion.defaultProps = {
  asset: undefined,
  id: undefined,
  expandedProp: false,
};
