/* eslint-disable indent */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box, Grid, makeStyles, Table,
  TableBody, TableContainer, TableFooter, TablePagination, TableRow,
  LinearProgress, Paper, TableCell, TableHead, Card, CardContent, Typography,
  Tooltip,
} from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  React, useEffect,
} from 'react';
import MachineTelemetryFOTAListItem from '../../../components/MachineTelemetry/MachineTelemetryFOTAListItem';
import TablePaginationActions from '../../../components/utils/TablePaginationActions';
import SecondaryButton from '../../../components/utils/SecondaryButton';
import {
  CSV_FILETYPE,
} from '../../../utils/constants';
import { downloadFile } from '../../../utils/exportUtils';
import * as rowNames from './rowNames';

const useStyles = makeStyles((theme) => ({
  headerTable: {
    font: theme.typography.h5.font,
    fontSize: 12,
    color: theme.palette.common.black,
    paddingBottom: 16,
  },
  tableHead: {
    color: theme.palette.primary.subMain,
  },
  headerBorderTable: {
    borderBottom: `1.5px solid ${theme.palette.primary.banner}`,
  },
  bottomRightExportButton: {
    paddingRight: 8,
    paddingLeft: 8,
    paddingBottom: 16,
    textAlign: 'right',
    alignSelf: 'flex-end',
    borderRadius: 0,
    width: 138,
  },
  tableContainer: {
    borderRadius: 12,
    boxShadow: theme.customShadows.noTopShadow,
  },
  historyTable: {
    borderRadius: 0,
    minWidth: 200,
    '& tr': {
      maxWidth: 976,
      height: 48,
      paddingLeft: 24,
      paddingRight: 24,
      '& td': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
  separateVoidGrid: {
    padding: 24,
    flexWrap: 'noWrap',
  },
  content: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
    backgroundColor: theme.palette.background.typography,
  },
  exportCSVText: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'none',
  },
  cardNoMachineTelemetryText: {
    color: theme.palette.primary.text,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 16,
    textAlign: 'center',
  },
  legend: {
    marginTop: 8,
    color: theme.palette.secondary.text,
    fontSize: 16,
  },
  cardNoMachineFota: {
    borderRadius: 12,
  },
}));

export default function MachineTelemetryFOTA({
  search, dataList, loading, openAlert, page, setPage, rowsPerPage, paginationTokens,
}) {
  // STATE________________________________

  const headerNames = [rowNames.machineId, rowNames.phaseTimestamp,
  rowNames.phase, rowNames.description, rowNames.firmwareType,
  rowNames.firmwareVersion];
  const headerWidths = ['20%', '10%', '10%', '20%', '20%', '10%', '10%'];

  // FORM_________________________________
  // Business for CVS data export

  function orderCsvRow(row) {
    const orderedRow = [];
    if (!Array.isArray(row)) {
      headerNames.forEach((name) => {
        switch (name) {
          case rowNames.machineId:
            orderedRow.push(row.serialNumber);
            break;
          case rowNames.phaseTimestamp:
            orderedRow.push(row.statusDate);
            break;
          case rowNames.description:
            orderedRow.push(row.description);
            break;
          case rowNames.phase:
            orderedRow.push(row.statusPhase);
            break;
          case rowNames.firmwareType:
            orderedRow.push(row.deploymentType);
            break;
          case rowNames.firmwareVersion:
            orderedRow.push(row.firmwareVersion);
            break;
          default:
            break;
        }
      });
    } else {
      orderedRow.push(...row);
    }
    return orderedRow;
  }

  function convertToCSV(items, headers) {
    if (headers) {
      items.unshift(headers);
    }
    // Convert Object to JSON
    const objArray = JSON.stringify(items);
    const rows = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;

    let str = '';
    rows.forEach((row) => {
      let line = '';
      const columns = orderCsvRow(row);

      columns.forEach((column) => {
        if (line !== '') {
          line += ';';
        }
        if (typeof column === 'object') {
          line += '-';
        } else if (moment(column, moment.ISO_8601, true).isValid()) {
          line += moment(new Date(column)).format('DD/MM/yyyy HH:mm:ss');
        } else {
          line += column;
        }
      });
      str += `${line}\r\n`;
    });
    return `${str}`;
  }

  async function onClickExport() {
    try {
      const csv = convertToCSV([...dataList], headerNames);
      downloadFile(csv, 'FotaEvents', CSV_FILETYPE);
      openAlert('Success', 'CSV file is being generated');
    } catch (error) {
      openAlert('Error', error.message);
    }
  }

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    search(newPage);
  };

  function removeDuplicates(data) {
    const result = [];
    data.forEach((d) => {
      if (!result.some((element) => (
        d.deploymentId === element.deploymentId
        && d.timestamp === element.timestamp
        && d.statusDate === element.statusDate
        && d.statusCode === element.statusCode
        && d.description === element.description
      ))) {
        result.push(d);
      }
    });
    return result;
  }

  // WEBPAGE______________________________
  const classes = useStyles();

  useEffect(() => { }, []);

  return (
    <Box>
      {(dataList !== null) && (dataList.length > 0)
        && (
          <Grid
            container
            spacing={0}
            direction="row"
            className={classes.content}
          >
            <Grid item xs className={classes.legend}>
              <Typography>Data will be retrieved in real time</Typography>
            </Grid>
            <Grid item xs className={classes.bottomRightExportButton}>
              <Tooltip title={`Exports the ${dataList.length} present rows`} arrow>
                <span>
                  <SecondaryButton
                    id="btnFotaExport"
                    onClick={() => onClickExport()}
                  >
                    Export .CSV
                  </SecondaryButton>
                </span>
              </Tooltip>
            </Grid>

            <Grid
              item
              xs={12}
            >

              <TableContainer component={Paper} className={classes.tableContainer}>
                <Table className={classes.historyTable} aria-label="custom pagination table">
                  <TableHead className={classes.headerBorderTable}>
                    <TableRow className={classes.headerTable}>
                      {/* eslint-disable-next-line max-len */}
                      {headerNames.map((head, i) => <TableCell className={classes.tableHead} style={{ width: headerWidths[i] }}>{head}</TableCell>)}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {removeDuplicates(dataList)
                      .map((resp, i) => (
                        <MachineTelemetryFOTAListItem
                          id={`cellFota${i}`}
                          eventMachine={resp}
                          widths={headerWidths}
                        />
                      ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow key="paginationFooter">
                      <TablePagination
                        rowsPerPageOptions={[rowsPerPage]}
                        colSpan={10}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        ActionsComponent={TablePaginationActions}
                        labelDisplayedRows={({ from, to, count }) => (count !== -1
                          ? `${from}-${to + (page * 100)} of ${count + ((paginationTokens?.length - 1) * 100)}`
                          : `${from}-${to} of more than ${to}`)}
                        count={
                          paginationTokens?.length > 1 && dataList?.length === rowsPerPage
                            ? -1
                            : removeDuplicates(dataList).length
                        }
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
                {loading && <LinearProgress />}
              </TableContainer>

            </Grid>
          </Grid>
        )}
      {(dataList !== null) && (dataList.length === 0)
        && !loading
        && (
          <Grid
            item
            xs={12}
            className={classes.separateVoidGrid}
          >
            <Card className={classes.cardNoMachineFota}>
              <CardContent style={{ padding: 16 }}>
                <Typography className={classes.cardNoMachineTelemetryText}>
                  No FOTA events available for this machine
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
    </Box>
  );
}
MachineTelemetryFOTA.propTypes = {
  search: PropTypes.func.isRequired,
  dataList: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  openAlert: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  paginationTokens: PropTypes.array.isRequired,
};
