/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Restricted from '../../authorization/Restricted';
import itemIsSelected from '../utils/SelectedItems';

const useStyles = makeStyles((theme) => ({
  drawerItem: {
    paddingLeft: theme.spacing(6),
    minHeight: 48,
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: 'white',
    },
  },
  drawerItemIcon: {
    minWidth: 24, // Ensure consistent icon alignment
    marginRight: 12, // Ensure consistent spacing between icon and text
  },
}));

function LeftBarItem({
  name, path, icon, permission, onToggle,
}) {
  const { pathname } = useLocation();
  const [isSelected, setIsSelected] = React.useState(false);
  const classes = useStyles();

  useEffect(() => {
    setIsSelected(itemIsSelected(path, pathname));
  }, [pathname, path]);

  useEffect(() => {
    if (isSelected) {
      onToggle();
    }
  }, [isSelected, onToggle]);

  return (
    <Restricted to={[permission]}>
      <ListItem
        id={`listItem${name.replaceAll(' ', '')}`}
        button
        component={Link}
        to={path}
        key={name}
        selected={isSelected}
        classes={{ root: classes.drawerItem }}
        onClick={onToggle}
      >
        <ListItemIcon className={classes.drawerItemIcon}>{icon}</ListItemIcon>
        <ListItemText primary={name} />
      </ListItem>
    </Restricted>
  );
}

LeftBarItem.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  permission: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default LeftBarItem;
