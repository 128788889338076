/* eslint-disable max-len */
import { useState } from 'react';
import GraphqlService from '../service/graphqlService';

export default function useReleasesEndpoint() {
  // Output state
  const [releases, setReleases] = useState();
  // Input state
  const [modelId, setModelId] = useState(1);
  const [limit, setLimit] = useState(50);
  const [offset, setOffset] = useState(0);

  async function loadReleases(newModelId, paginationLimit, paginationOffset) {
    setModelId(newModelId);
    setLimit(paginationLimit);
    setOffset(paginationOffset);
    const fetchedReleases = GraphqlService.getReleases(
      newModelId, paginationLimit, paginationOffset,
    );
    setReleases(fetchedReleases);
    return fetchedReleases;
  }

  async function getReleases(newModelId, paginationLimit, paginationOffset) {
    if (!releases || limit !== paginationLimit || offset !== paginationOffset || modelId !== newModelId) {
      return loadReleases(newModelId, paginationLimit, paginationOffset);
    }
    return releases;
  }

  return {
    loadReleases,
    getReleases,
  };
}
