function isStringifiedObject(str) {
  // Check if it's a string and try to parse it
  if (typeof str === 'string') {
    try {
      const parsed = JSON.parse(str);
      // If parsing is successful and returns an object, it’s a stringified object
      return parsed && typeof parsed === 'object';
    } catch (e) {
      // If JSON.parse throws, it's not a stringified object
      return false;
    }
  }
  // If it's not a string, return false
  return false;
}

export default isStringifiedObject;
