/* eslint-disable react/jsx-props-no-spreading */
import { Button, makeStyles } from '@material-ui/core';
import { React } from 'react';
import { ReactComponent as ExportFieldIcon } from '../../assets/ic_export.svg';

export default function SecondaryButton({ ...other }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      color: theme.palette.primary.text,
      backgroundColor: theme.palette.background.form,
      border: 'none',
      borderRadius: '12px',
      height: 40,
      '& :disabled': {
        borderColor: theme.palette.primary.light,
        color: theme.palette.primary.light,
      },
    },
    label: {
      fontSize: 12,
      fontWeight: theme.typography.fontWeightRegular,
      textTransform: 'none',
    },
  }));
  const classes = useStyles();
  return (<Button {...other} endIcon={<ExportFieldIcon />} classes={{ root: classes.root, label: classes.label }} color="primary" variant="outlined" />);
}
