const cleanAllNullValues = (obj) => {
  if (obj === null) {
    return undefined;
  }
  if (Array.isArray(obj)) {
    return obj.map((value) => cleanAllNullValues(value));
  }
  if (obj && typeof obj === 'object') {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (value !== null) {
        acc[key] = cleanAllNullValues(value);
      }
      return acc;
    }, {});
  }
  return obj;
};

export default cleanAllNullValues;
