/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box, Grid, makeStyles, InputLabel,
} from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  React, useContext, useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useProject } from '../../config/ProjectContext';
import GraphqlService from '../../service/graphqlService';
import PageBanner from '../../components/utils/PageBanner';
import SimpleAlert from '../../components/utils/SimpleAlert';
import ProgressDialog from '../../components/utils/ProgressDialog';
import Filters from '../../components/utils/Filters';
import { validate, calculateEndDateTimeIso, calculateStartDateTimeIso } from '../../utils/dateUtils';
import BrandObjectTable from './MachineTelemetry/BrandObjectTable';

const useStyles = makeStyles((theme) => ({
  formField: {
    width: '100%',
    '& ::placeholder': {
      paddingLeft: 8,
      paddingTop: 8,
    },
  },
  formPlaceholder: {
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: 'blue',
    },
  },
  inputDescriptionLabel: {
    font: theme.typography.h4.font,
    fontSize: 16,
    color: theme.palette.primary.text,
    paddingTop: 24,
    paddingLeft: 24,
    backgroundColor: theme.palette.background.form,
  },
  contentInfo: {
    paddingTop: 24,
    paddingBottom: 8,
    paddingLeft: 24,
    paddingRight: 24,
    backgroundColor: theme.palette.background.typography,
  },
  // tabs
  headerTabPanel: {
    backgroundColor: theme.palette.background.paper,
  },
  labelHeaderTabPanel: {
    color: theme.palette.primary.labelHeader,
    fontFamily: theme.typography.h3.fontFamily,
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '0',
    lineHeight: '16px',
    textAlign: 'center',
    width: '720px',
    height: '48px',
  },
  detailsTitle: {
    paddingRight: '5px',
    fontFamily: theme.typography.h5.fontFamily,
    lineHeight: theme.typography.h5.lineHeight,
    letterSpacing: theme.typography.h5.letterSpacing,
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.primary.subMain,
  },
  accordion: {
    borderRadius: '12px !important',
  },
  details: {
    paddingLeft: 24,
    paddingRight: 24,
  },
}));

export default function DeploymentStatus({ openSnackBar }) {
  const MAX_DAYS_BETWEEN_DATES = 90;
  const [params, setParams] = useState({ isError: false });

  // STATE________________________________
  const { project } = useProject();

  // PARAMS
  const { deploymentID, detailStartDate } = useParams();

  // grid
  const [totalCount, setTotalCount] = useState(0);
  const [reducedPage, setReducedPage] = useState(0);
  const [paginationTokens, setPaginationTokens] = useState(['']);
  const [rowsPerPageConsumption] = useState(100);
  const [reducedResponse, setReducedResponse] = useState(null);

  //  alert data
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertHeader, setAlertHeader] = useState('');
  const [alertText, setAlertText] = useState('');

  //  state values
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);

  // errors
  const [dateStartError, setDateStartError] = useState(false);
  const [dateEndError, setDateEndError] = useState(false);
  const [greaterSmallerDateError, setGreaterSmallerDateError] = useState(false);
  const [moreThanMaxDaysDateError, setMoreThanMaxDaysDateError] = useState(false);

  // data
  const [selectedFilters, setSelectedFilters] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState(moment().add(-90, 'days'));
  const [selectedStartTime, setSelectedStartTime] = useState(moment().add(-90, 'days').add(1, 'hour').format('HH:mm'));
  const [selectedEndDate, setSelectedEndDate] = useState(moment());
  const [selectedEndTime, setSelectedEndTime] = useState(moment().format('HH:mm'));

  // progress dialog
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);

  function openAlert(header, text) {
    setAlertHeader(header);
    setAlertText(text);
    setAlertOpen(true);
  }

  // FORM_________________________________
  const {
    handleSubmit, register, formState: { errors },
  } = useForm();

  // functions to obtain the data to show
  async function searchObject(myProject, filters, startDate, endDate, paginationToken) {
    const response = await GraphqlService.getLifeCycleObject(
      myProject.code, `machinecount/${myProject.code}/${deploymentID}`, JSON.stringify(params), filters, startDate, endDate, paginationToken,
    );

    // eslint-disable-next-line no-plusplus
    if (response) {
      setPaginationTokens((prev) => {
        if (response?.pagination && response?.pagination.paginationContinuationToken && !prev.some((token) => token === response.pagination.paginationContinuationToken)) {
          return [...prev, response.pagination.paginationContinuationToken];
        }
        return prev;
      });
      const generalData = JSON.parse(response.data);
      return generalData;
    }
    return [];
  }

  async function search(myProject, filters, startDate, endDate, startTime, endTime, newPage, newValue) {
    try {
      setSubmitting(true);
      setProgressDialogOpen(true);
      setLoading(true);
      let creationResponse = [];

      setReducedPage(newPage || 0);
      creationResponse = await searchObject(myProject, filters, calculateStartDateTimeIso(startDate, startTime), calculateEndDateTimeIso(endDate, endTime), paginationTokens[newPage] ? paginationTokens[newPage] : undefined);
      setReducedResponse(creationResponse);
      setMoreThanMaxDaysDateError(false);

      setSubmitting(false);
      setProgressDialogOpen(false);
      setLoading(false);
      return creationResponse;
    } catch (error) {
      console.log('Error!!!', error);
      setProgressDialogOpen(false);
      setSubmitting(false);
      setLoading(false);
      openAlert('Error', error.message);
      return null;
    }
  }

  const onSubmit = (data) => {
    search(
      project, selectedFilters, selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime,
    );
  };

  useEffect(() => {
    if (!detailStartDate) return;
    const parsedStartDate = moment.unix(detailStartDate);
    if (moment(parsedStartDate).isValid()) {
      setSelectedStartDate(parsedStartDate);
      // set selected start time to 00:00
      setSelectedStartTime(moment(parsedStartDate).set({ hour: 0, minute: 0 }).format('HH:mm'));
    }
    search(
      project, selectedFilters, parsedStartDate, undefined, moment(parsedStartDate).format('HH:mm'), undefined,
    );
  }, [detailStartDate]);

  // WEBPAGE______________________________
  const classes = useStyles();

  useEffect(() => {
    setPaginationTokens(['']);
  }, [selectedFilters, selectedStartDate, selectedEndDate]);

  const handleRadioChange = (event) => {
    setParams({ ...params, isError: event.target.value });
  };

  useEffect(() => {
    search(
      project, selectedFilters, selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime,
    );
  }, [params]);

  return (
    <Box spacing={0}>
      <ProgressDialog open={progressDialogOpen} setOpen={setProgressDialogOpen} header="Retrieving statistics, please wait" />
      <SimpleAlert open={alertOpen} setOpen={setAlertOpen} header={alertHeader} body={alertText} />
      <PageBanner title="DEPLOYMENT STATUS" />
      <Grid
        container
        spacing={0}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classes.content}
        >
          <Filters
            id="DeploymentStatus"
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            submitting={submitting}
            selectedStartDate={selectedStartDate}
            setSelectedStartDate={setSelectedStartDate}
            selectedStartTime={selectedStartTime}
            setSelectedStartTime={setSelectedStartTime}
            selectedEndDate={selectedEndDate}
            setSelectedEndDate={setSelectedEndDate}
            selectedEndTime={selectedEndTime}
            setSelectedEndTime={setSelectedEndTime}
            greaterSmallerDateError={greaterSmallerDateError}
            setGreaterSmallerDateError={setGreaterSmallerDateError}
            moreThanMaxDaysDateError={moreThanMaxDaysDateError}
            setMoreThanMaxDaysDateError={setMoreThanMaxDaysDateError}
            filtersVisible={false}
            deviceSerialVisible={false}
            maxDaysBetweenDates={MAX_DAYS_BETWEEN_DATES}
            subtitle="Summary of devices and modules impacted by a deployment, grouped by the latest deployment status"
            filtersComponents={[
              {
                type: 'radio',
                label: 'Exit Code Type',
                handleChange: handleRadioChange,
                defaultValue: 'false',
                values: [{
                  label: 'Normal',
                  value: 'false',
                }, {
                  label: 'Error',
                  value: 'true',
                }],
              },
            ]}
          />
        </form>
      </Grid>
      <Grid item xs={12}>
        <BrandObjectTable
          search={(newPage) => search(project, selectedFilters, selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime, newPage)}
          dataList={reducedResponse}
          filters={selectedFilters}
          loading={loading}
          dateIni={moment(`${selectedStartDate} ${selectedStartTime}`)}
          dateEnd={selectedEndDate ? moment(`${selectedEndDate} ${selectedEndTime}`) : null}
          rangeDates={MAX_DAYS_BETWEEN_DATES}
          page={reducedPage}
          setPage={setReducedPage}
          rowsPerPage={rowsPerPageConsumption}
          paginationTokens={paginationTokens}
          object={`machinecount/${project.code}/${deploymentID}`}
          params={JSON.stringify(params)}
          id="DeploymentStatus"
          pagination="token"
          columnInclude="validData"
        />
      </Grid>
    </Box>
  );
}
DeploymentStatus.propTypes = {
  openSnackBar: PropTypes.func.isRequired,
};
