/* eslint-disable no-param-reassign */
import { useQuery } from '@tanstack/react-query';
import GraphqlService from '../service/graphqlService';

// Custom hook to fetch and manage config data
const useConfig = () => {
  // Function to fetch config data
  const getConfig = async () => {
    const result = await GraphqlService.getConfig();
    return result || [];
  };

  // Use the useQuery hook to fetch config data
  const {
    data: configData,
    isLoading: configLoading,
    error: configError,
  } = useQuery(['config'], getConfig, {
    cacheTime: 0,
    select: (data) => {
      if (!data) return {};
      const object = JSON.parse(data);
      const result = object.reduce((obj, item) => {
        obj[item.name] = item.value;
        return obj;
      }, {});
      return result;
    },
  });

  return { configData, configLoading, configError };
};

export default useConfig;
