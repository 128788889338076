import React from 'react';
import {
  Container, Typography, Button, Box,
} from '@material-ui/core';
import { motion } from 'framer-motion';
import { targetName } from '../utils/portalEnvs';
import { target } from '../config/awsExports';

// Definimos la animación con framer motion
const pageVariants = {
  initial: {
    opacity: 0,
    y: -20,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.5 },
  },
};

const NoProjectsPage = () => (
  <motion.div
    initial="initial"
    animate="animate"
    variants={pageVariants}
    style={{
      height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center',
    }}
  >
    <Container
      maxWidth="sm"
      style={{
        textAlign: 'center', backgroundColor: '#f5f5f5', padding: '40px', borderRadius: '8px',
      }}
    >
      <Box mb={4} ml={18}>
        <Typography variant="h1" component="h1" style={{ margin: '10px', padding: '15px' }}>
          No Projects Assigned
        </Typography>
        <Typography variant="body1" color="textSecondary">
          You currently do not have any projects assigned. Please contact our IoT team for assistance.
        </Typography>
      </Box>
      <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
        <Box ml={18}>
          <Button
            variant="contained"
            color="primary"
            href={`mailto:iot-portal@internal.nestle.com?subject=%5BPortal-${targetName[target]}%5D%20Access%20request%20from%20portal%20user`}
          >
            Contact IoT Team
          </Button>
        </Box>
      </motion.div>
    </Container>
  </motion.div>
);

export default NoProjectsPage;
