// Check if window.process is defined, if not define an empty object
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend,
} from 'chart.js';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './config/configureAmplify';

ChartJS.register(ArcElement, Tooltip, Legend);

if (typeof window !== 'undefined' && !window.process) {
  window.process = {};
}

// Only render inside StrictMode if it's available
const rootComponent = (
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Render the root component
ReactDOM.render(
  rootComponent,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
