import {
  createContext,
} from 'react';

/*
  The object to deploy can be assets or bundles.
  For Bundles, now is just possible to deploy one bundle, so the array will contain the Bundle with the isBundle flag
  [{
      ...BundleObject
      releaseID,
      isBundle: true,
    }]

*/

const SelectedObjectsToDeployContext = createContext({
  selectedObjectsToDeploy: [],
  setSelectedObjectsToDeploy: () => { },
});

export default SelectedObjectsToDeployContext;
