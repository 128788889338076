/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Grid, Box, LinearProgress, makeStyles, Checkbox, ListItemText, Input,
  Table, TableBody, TableContainer, TableFooter, TableRow,
  Paper, TableCell, TableHead, withStyles, Button, IconButton, Divider,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import {
  React, useEffect, useState, useContext,
} from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { useLocation } from 'react-router-dom';
import PageBanner from '../../components/utils/PageBanner';
import DeleteBundlesAlert from './DeleteBundlesAlert';
// Local imports
import { useProject } from '../../config/ProjectContext';
import GraphqlService from '../../service/graphqlService';
import YesNoDialog from '../../components/utils/YesNoDialog';
import PaginationComponent from '../../components/Pagination';
import ProgressDialog from '../../components/utils/ProgressDialog';
import { useSnackbar } from '../../providers/SnackbarContext';
import MessageCard from '../../components/utils/MessageCard';
import BundleWizard from './BundleWizard';
import JsonPopup from '../../components/JsonPopup';
import BundleDetailPopup from './BundleDetailPopup';
import SelectedObjectsToDeployContext from '../../config/SelectedAssetsContext';
// Import the BundleWizard component
function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  index: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
};

export default function BundleList() {
  const useStyles = makeStyles((theme) => ({
    separate: {
      padding: 24,
    },
    headerTabPanel: {
      height: 48,
      backgroundColor: theme.palette.background.paper,
    },
    pagination: {
      background: theme.palette.background.paper,
      borderRadius: 12,
      boxShadow: theme.customShadows.regularShadow,
      color: theme.palette.primary.text,
      float: 'right',
      padding: 0,
      width: '100%',
    },
    table: {
      marginTop: theme.spacing(2),
    },
    labelHeaderTabPanel: {
      color: theme.palette.primary.subMain,
      fontFamily: theme.typography.h3.fontFamily,
      fontSize: '16px',
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: '0',
      lineHeight: '19px',
      textAlign: 'center',
      width: '720px',
      height: '48px',
    },
    background: {
      backgroundColor: theme.palette.background.typography,
    },
    button: {
      float: 'right',
      margin: '0 24px 24px 0',
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      fontSize: 16,
      textTransform: 'none',
      // hover
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      // radious
      borderRadius: 12,
    },
    tableIcons: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: 24,
      flexWrap: 'wrap',
    },
    selectPaginationSize: {
      marginRight: '8px',
      borderRadius: 12,
    },
    fileTypeFilterStyle: {
      minWidth: 210,
      marginTop: 6,
    },
    orderDateStyle: {
      minWidth: 130,
      marginTop: 6,
    },
    deployIdFilterStyle: {
      minWidth: 450,
      borderRadius: 12,
      marginRight: 8,
    },
    cell: {
      size: 'small',
      whiteSpace: 'nowrap',
    },
    cellwrap: {
      size: 'small',
      whiteSpace: 'normal',
    },
    selectedItems: {
      fontSize: 14,
      color: theme.palette.text.secondary,
    },
    detailIcon: {
      // size of the icon has to be smaller than the row height
      padding: 0,
    },
    detailFooter: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      backgroundColor: 'white',
      boxShadow: theme.shadows[3],
      padding: theme.spacing(2),
      border: `1px solid ${theme.palette.divider}`,
      zIndex: 1300,
      [theme.breakpoints.up('md')]: {
        marginLeft: 279,
      },
      height: '55%',
      // if it's mobile, the height is 100% of the screen
      [theme.breakpoints.down('sm')]: {
        height: '80%',
      },
      // add and scroll to the asset detail footer
      overflow: 'auto',
    },
    titleDivide: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    detailTitleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        marginRight: 280,
      },
    },
    detailFooterContainer: {
      with: '80%',
    },
    filters: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(2),
      alignItems: 'flex-end',
    },
    formControl: {
      minWidth: 120,
    },
    assetTypeFilterStyle: {
      minWidth: 210,
    },
    descriptionFormControl: {
      minWidth: 410,
    },
  }));
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const { project } = useProject();
  const [alertOpen, setAlertOpen] = useState(false);
  const [yesNoDialogOpen, setYesNoDialogOpen] = useState(false);
  const [bundleToDelete, setBundleToDelete] = useState(() => { });
  const [yesNoDialogText, setYesNoDialogText] = useState('');
  const [lastProjectCode, setLastProjectCode] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const [selectedBundles, setSelectedBundles] = useState([]);
  const [showWizard, setShowWizard] = useState(false); // State to control the visibility of the wizard
  const openSnackBar = useSnackbar();

  const [allChecked, setAllChecked] = useState(false);
  const [indeterminateCheck, setIndeterminateCheck] = useState(false);

  const tableHeaderItems = ['Title', 'Description', 'Creation Date', 'Bundle ID', '', ''];

  const [sortDirection, setSortDirection] = useState('DESC');
  const [listDeleted, setListDeleted] = useState('NON-DELETED');

  const [filterByReleaseDescriptionValue, setFilterByReleaseDescriptionValue] = useState('');
  const [debouncedFilterReleaseDescription, setDebouncedFilterReleaseDescription] = useState(filterByReleaseDescriptionValue);

  const [lastAssetTypeFilter, setLastAssetTypeFilter] = useState();
  const [closeAssetTypeFilter, setCloseAssetTypeFilter] = useState();
  const [openAssetTypeFilter, setOpenAssetTypeFilter] = useState(false);
  const [selectedAssetTypes, setSelectedAssetTypes] = useState([]);
  const [assetTypeFilter, setAssetTypeFilter] = useState();

  const [bundlesDeleted, setBundlesDeleted] = useState([]);
  const [bundleNotDeleted, setBundleNotDeleted] = useState();
  const [errorBundleNotDeleted, setErrorBundleNotDeleted] = useState();

  const { setSelectedObjectsToDeploy } = useContext(SelectedObjectsToDeployContext);

  useEffect(() => {
    setSelectedObjectsToDeploy([]);
  }, []);

  useEffect(() => {
    // Si la ruta es /bundles/create, mostrar automáticamente el wizard
    if (location.pathname === '/bundles/create') {
      setShowWizard(true);
    }
  }, [location.pathname]);

  const getType = closeAssetTypeFilter === null ? null : closeAssetTypeFilter;

  const fetchBundles = async () => {
    if (project?.code !== lastProjectCode) {
      setCurrentPage(0);
      setLastProjectCode(project?.code);
      setAssetTypeFilter(null);
    } else if (assetTypeFilter !== lastAssetTypeFilter) {
      setCurrentPage(0);
      setLastAssetTypeFilter(assetTypeFilter);
    }

    return GraphqlService.listBundles({
      projectId: project?.code,
      sortDirection,
      fileType: getType,
      page: currentPage,
      pageSize,
      deleted: listDeleted === 'DELETED' ? 1 : undefined,
      releaseDescriptionValue: debouncedFilterReleaseDescription,
      releaseDescriptionKey: debouncedFilterReleaseDescription ? 'description' : undefined,
    });
  };

  const {
    data: bundles,
    error: errorFetchingBundles,
    isLoading,
    refetch,
  } = useQuery(['bundles', project?.code, currentPage, pageSize, sortDirection, debouncedFilterReleaseDescription, getType, listDeleted], () => fetchBundles(), {
    enabled: !!project,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 0,
  });

  const fetchAssetTypes = async () => GraphqlService.getAssetTypes({
    projectId: project.code,
  });

  const {
    data: assetTypes,
    error: errorFetchingAssetTypes,
    isLoading: isLoadingAssetTypes,
  } = useQuery(['assetTypes', project?.code, project?.permission, currentPage, pageSize, sortDirection], () => fetchAssetTypes({
    projectId: project?.code,
  }), {
    enabled: !!project,
    cacheTime: 0,
  });

  useEffect(() => {
    // Set a timeout to update the debounced filter value
    const handler = setTimeout(() => {
      setDebouncedFilterReleaseDescription(filterByReleaseDescriptionValue);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [filterByReleaseDescriptionValue]);

  const errorFetchingFileTypes = false;

  const deleteBundleMutation = useMutation((variables) => GraphqlService.deleteBundle(variables.projectId, variables.releaseID));

  async function deleteBundleMutationAsync(projectId, releaseID) {
    try {
      await deleteBundleMutation.mutateAsync({ projectId, releaseID });
    } catch (error) {
      // Rethrow the error to allow deleteAllBundles to catch and handle it
      throw new Error(`Bundle ${releaseID} failed to delete: ${error.message || error}`);
    }
  }

  async function deleteAllBundles() {
    setBundlesDeleted([]);

    try {
      setIsDeleting(true);

      for (const bundle of selectedBundles) {
        setBundleNotDeleted(bundle);
        await deleteBundleMutationAsync(project?.code, bundle.releaseID);
        setBundlesDeleted([...bundlesDeleted, bundle]);
        setBundleNotDeleted(null);
      }

      setSelectedBundles([]); // Clear selected bundles after successful deletion of all
      refetch();
      openSnackBar('All bundles deleted successfully', 'success');
    } catch (error) {
      // Display the error and list of successfully deleted bundles
      setErrorBundleNotDeleted(error.message);
      setAlertOpen(true);
    } finally {
      refetch();
      setIsDeleting(false);
    }
  }

  async function deleteBundle(projectId, releaseID) {
    try {
      setBundlesDeleted([]);
      setIsDeleting(true);
      await deleteBundleMutationAsync(projectId, releaseID);
      setSelectedBundles([]); // Clear selected bundles after successful deletion of all
      refetch();
      openSnackBar('Bundle deleted successfully', 'success');
    } catch (error) {
      // Display the error and list of successfully deleted bundles
      setErrorBundleNotDeleted(error.message);
      setAlertOpen(true);
    } finally {
      setIsDeleting(false);
    }
  }

  useEffect(() => {
    if (errorFetchingFileTypes) {
      openSnackBar('Error fetching Bundle File Types', 'error');
    }
  }, [errorFetchingFileTypes]);

  const classes = useStyles();
  const handlePageChange = (event, value) => {
    setCurrentPage(value - 1);
  };

  const handleSelectChange = (event) => {
    setPageSize(event.target.value);
  };

  const handleSelectAllClick = () => {
    if (bundles?.result?.length === selectedBundles?.length) {
      setSelectedBundles([]);
      setAllChecked(false);
    } else {
      const newSelecteds = bundles?.result?.map((n) => n);
      setSelectedBundles(newSelecteds);
      setAllChecked(true);
    }
    setIndeterminateCheck(false);
  };

  const handleClick = (event, bundle) => {
    const selectedIndex = selectedBundles.indexOf(bundle);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedBundles, bundle);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedBundles.slice(1));
    } else if (selectedIndex === selectedBundles.length - 1) {
      newSelected = newSelected.concat(selectedBundles.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedBundles.slice(0, selectedIndex),
        selectedBundles.slice(selectedIndex + 1),
      );
    }

    if (newSelected.length === bundles?.result?.length) {
      setAllChecked(true);
      setIndeterminateCheck(false);
    } else if (newSelected.length === 0) {
      setAllChecked(false);
      setIndeterminateCheck(false);
    } else {
      setIndeterminateCheck(true);
      setAllChecked(false);
    }

    setSelectedBundles(newSelected);
  };

  const isSelected = (bundle) => selectedBundles.find((b) => b.releaseID === bundle.releaseID);

  const handleCreateBundleClick = () => {
    setShowWizard(true);
  };

  const handleWizardComplete = () => {
    setShowWizard(false);
    // Refetch
    refetch();
  };

  const handleCloseAssetTypeFilter = () => {
    setOpenAssetTypeFilter(false);
    setCloseAssetTypeFilter(assetTypeFilter);
  };

  const handleOpenAssetTypeFilter = () => {
    setOpenAssetTypeFilter(true);
  };

  const handleWizardCancel = () => {
    setShowWizard(false);
  };

  if (!project || bundles?.length === 0) {
    return <MessageCard message="No bundles retrieved" />;
  }

  if (isLoading || !project?.code) {
    return (
      <>
        <ProgressDialog open header="Retrieving bundles, please wait" />
        <LinearProgress style={{ width: '100%' }} />
      </>
    );
  }

  if (isDeleting) {
    return (
      <>
        <ProgressDialog open header="Deleting bundles, please wait" />
        <LinearProgress style={{ width: '100%' }} />
      </>
    );
  }

  const handleAssetTypeFilterChange = (event) => {
    const { value } = event.target;
    // Need to handle the selection as an array
    setSelectedAssetTypes(value);
    // transform the array in a string with values sepparated by commas
    if (!value) {
      setAssetTypeFilter(null);
    } else setAssetTypeFilter(value.join(',').toString());
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(0, 0, 0, 0.02)',
      },
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
      // selected
      '&$selected': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
  }))(TableRow);

  const getVersionFromString = (stringValue) => {
    // Use a regular expression to find the value of the Version key
    const versionMatch = stringValue?.match(/Version=([^,}]*)/);

    // Check if a match was found and extract the value
    if (versionMatch && versionMatch[1]) {
      return versionMatch[1].trim();
    }

    return undefined;
  };

  const openAsset = (fileId) => {
    const url = `${window.location.origin}/#/assetDetail/${fileId}`;
    window.open(url, '_blank');
  };

  if (errorFetchingBundles) {
    return (
      <DeleteBundlesAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        bundlesDeleted={bundlesDeleted}
        bundleNotDeleted={bundleNotDeleted}
        errorBundleNotDeleted={errorBundleNotDeleted}
      />
    );
  }

  return (
    <Box>
      <PageBanner title="BUNDLES" />
      {showWizard ? (
        <BundleWizard onComplete={handleWizardComplete} onCancel={handleWizardCancel} assetTypes={assetTypes?.data} /> // Use the BundleWizard component
      ) : (
        <>
          <YesNoDialog
            open={yesNoDialogOpen}
            setOpen={setYesNoDialogOpen}
            header="Cancel bundles"
            body={yesNoDialogText}
            action={() => deleteBundle(bundleToDelete)}
            actionName="Yes"
            id="confirmationCancelBundles"
          />
          <DeleteBundlesAlert
            open={alertOpen}
            setOpen={setAlertOpen}
            bundlesDeleted={bundlesDeleted}
            bundleNotDeleted={bundleNotDeleted}
            errorBundleNotDeleted={errorBundleNotDeleted}
          />

          <Grid item xs={12} className={classes.separate}>

            <Button
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
              onClick={() => deleteAllBundles()}
              disabled={selectedBundles.length === 0}
              className={classes.button}
            >
              {selectedBundles.length === 1 ? 'Delete Bundle' : 'Delete Bundles'}
            </Button>

            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
              onClick={handleCreateBundleClick}
              className={classes.button}
            >
              Create Bundle
            </Button>

            <Grid container className={classes.filters} spacing={1}>
              <Grid item xs={12} sm="auto">
                {/* Release Description Value Filter */}
                <FormControl variant="outlined" className={classes.descriptionFormControl}>
                  <InputLabel>Description Value</InputLabel>
                  <Input
                    value={filterByReleaseDescriptionValue}
                    onChange={(e) => setFilterByReleaseDescriptionValue(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm="auto">
                <FormControl variant="filled" className={classes.formControl}>
                  <InputLabel>Sort Direction</InputLabel>
                  <Select
                    value={sortDirection}
                    onChange={(e) => setSortDirection(e.target.value)}
                  >
                    <MenuItem value="ASC">ASC</MenuItem>
                    <MenuItem value="DESC">DESC</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm="auto">
                <FormControl variant="filled" className={classes.formControl}>
                  <InputLabel>Deleted</InputLabel>
                  <Select
                    value={listDeleted}
                    onChange={(e) => setListDeleted(e.target.value)}
                  >
                    <MenuItem value="NON-DELETED">NON DELETED</MenuItem>
                    <MenuItem value="DELETED">DELETED</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {
                assetTypes?.data?.length > 0 && !isLoadingAssetTypes && (
                  <Grid item xs={12} sm="auto">
                    <FormControl variant="filled" className={classes.assetTypeFilterStyle}>
                      <InputLabel>Asset Type Filter</InputLabel>
                      <Select
                        multiple
                        open={openAssetTypeFilter}
                        onOpen={handleOpenAssetTypeFilter}
                        onClose={handleCloseAssetTypeFilter}
                        value={selectedAssetTypes}
                        onChange={handleAssetTypeFilterChange}
                        className={classes.selectPaginationSize}
                        renderValue={(selected) => selected.join(', ')}
                      >
                        {assetTypes?.data.map((assetType) => (
                          <MenuItem key={assetType} value={assetType}>
                            <Checkbox checked={selectedAssetTypes.indexOf(assetType) > -1} />
                            <ListItemText primary={assetType} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )
              }

            </Grid>
            <div>
              <Typography component="div" className={classes.selectedItems}>
                {`${selectedBundles.length || 0} bundles selected`}
              </Typography>
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="custom pagination table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell padding="checkbox">
                      <Checkbox
                        indeterminate={indeterminateCheck}
                        checked={allChecked}
                        onChange={handleSelectAllClick}
                        className={classes.headerCheckbox}
                      />
                    </StyledTableCell>
                    {tableHeaderItems?.map((head) => <StyledTableCell key={head}>{head}</StyledTableCell>)}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {bundles?.result?.map((item) => {
                    const isItemSelected = isSelected(item);
                    return (
                      <StyledTableRow
                        key={item.releaseID}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        selected={isItemSelected}
                      >
                        <StyledTableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onClick={(event) => handleClick(event, item)} />
                        </StyledTableCell>
                        <StyledTableCell id={`${item.releaseID}-title`} component="td" className={classes.cell}>
                          {item.title}
                        </StyledTableCell>
                        <StyledTableCell id={`${item.releaseID}-2`} component="td" className={classes.cell}>
                          {item.releaseDescription?.description}
                        </StyledTableCell>
                        <StyledTableCell id={`${item.releaseID}-3`} component="td" className={classes.cell}>
                          {item.dateTime.toString('dd/MM/yyyy HH:mm:ss')}
                        </StyledTableCell>
                        <StyledTableCell id={`${item.releaseID}-1`} component="td" className={classes.cellwrap}>
                          {item.releaseID}
                        </StyledTableCell>
                        <StyledTableCell id={`${item.releaseID}-4`}>

                          <BundleDetailPopup releaseID={item.releaseID} />

                        </StyledTableCell>
                        <StyledTableCell id={`${item.releaseID}-4`}>
                          <JsonPopup
                            title={`Bundle ${typeof item.title === 'string' ? item.title : 'Unknown'}`}
                            jsonObject={typeof item === 'object' && item !== null ? item : {}}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {isLoading && <LinearProgress />}
            </TableContainer>
          </Grid>
          <div className={classes.tableIcons}>
            {bundles?.pagination && (
              <PaginationComponent
                pageSize={pageSize}
                handleSelectChange={handleSelectChange}
                pagination={bundles.pagination}
                handlePageChange={handlePageChange}
              />
            )}
          </div>
        </>
      )}
    </Box>
  );
}
