/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import {
  Box, Grid,
  makeStyles, withStyles,
  Table, TableBody, TableContainer, TableHead, TableRow, Paper, TableCell,

} from '@material-ui/core';

import { config } from '../../config/awsExports';
import PageBanner from '../../components/utils/PageBanner';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.background.typography,
    padding: 16,
  },
  button: {
    float: 'right',
    margin: '0 0 16px 24px',
  },
  tableButton: {
    height: 48,
    justifyContent: 'center',
    margin: 4,
    color: 'white',
    borderRadius: 12,
  },
  tableIcons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: 16,
    flexWrap: 'wrap',
  },
  mainTable: {
    borderRadius: 12,
    boxShadow: theme.customShadows.noTopShadow,
  },
  notificationTitle: {
    flexGrow: 1,
  },
  closeNotificationsButton: {
    float: 'right',
  },
  titleNotifications: {
    padding: 0,
  },
  separate: {
    marginBottom: theme.spacing(2),
  },
}));

const StyledTableHeaderCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: '9px',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
  root: {
    cursor: 'pointer',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.form,
    },
  },
}))(TableRow);

const { env } = config;

const openUserActivity = (link) => {
  window.open(link, '_blank');
};

const LOGS = [
  {
    name: 'Auth',
    link: env.authLogs,
  },
  {
    name: 'Backend',
    link: env.backendLogs,
  },
  {
    name: 'EventBridge',
    link: env.authLogs,
  },
];

const COGNITO = [
  {
    name: 'Permissions',
    link: env.cognito,
  },
];

const RELEASES = [
  {
    name: 'Release Documentation',
    link: env.releases,
  },
];

const JIRA = [
  {
    name: 'Roadmap',
    link: env.roadMap,
  },
  {
    name: 'Backlog',
    link: env.backlog,
  },

];

const BUSINESSDOCU = [
  {
    name: 'Portal Documentation',
    link: env.businessDocumentation,
  },
];

const sections = [
  {
    name: '📄 CONFLUENCE PAGE',
    data: BUSINESSDOCU,
  },
  {
    name: '🚗 JIRA',
    data: JIRA,
  },
  {
    name: '🏁 RELEASES',
    data: RELEASES,
  },
  {
    name: '🎯 AWS LOGS',
    data: LOGS,
  },
  {
    name: '🤪 AWS COGNITO',
    data: COGNITO,
  },

];

export default function PortalInfo() {
  const classes = useStyles();

  return (
    <Box>
      <PageBanner title="USEFUL LINKS" />
      <Grid className={classes.background} container spacing={0} direction="column" alignItems="stretch">

        {sections.map((section) => (
          <div className={classes.separate}>
            <TableContainer component={Paper} className={classes.mainTable}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableHeaderCell className={classes.tableHead}>{section.name}</StyledTableHeaderCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {section.data.map((item) => (
                    <StyledTableRow key="logs">
                      <StyledTableCell onClick={() => openUserActivity(item.link)}>{item.name}</StyledTableCell>

                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ))}
      </Grid>

    </Box>
  );
}
