/* eslint-disable react/jsx-props-no-spreading */
import { Box } from '@material-ui/core';
import React from 'react';
import { PropTypes } from 'prop-types';

export function a11yProps(index) {
  return {
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
      <Box>
        <>{children}</>
      </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
