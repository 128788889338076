/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box, Grid, makeStyles, Table,
  TableBody, TableContainer, TableFooter, TablePagination, TableRow,
  LinearProgress, Paper, TableCell, TableHead, Card, CardContent, Typography,
  withStyles,

} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  React,
  useEffect,
} from 'react';
import { useMutation } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import GraphqlService from '../../../service/graphqlService';
import MachineTelemetryConsumptionListItem from '../../../components/MachineTelemetry/MachineTelemetryConsumptionListItem';
import TablePaginationActions from '../../../components/utils/TablePaginationActions';
import SecondaryButton from '../../../components/utils/SecondaryButton';
import { useProject } from '../../../config/ProjectContext';

const useStyles = makeStyles((theme) => ({
  headerTable: {
    font: theme.typography.h5.font,
    fontSize: 12,
    color: theme.palette.common.black,
  },
  tableHead: {
    color: theme.palette.primary.subMain,
    paddingLeft: 20,
  },
  headerBorderTable: {
    borderBottom: `1.5px solid ${theme.palette.primary.banner}`,
  },
  bottomRightExportButton: {
    paddingRight: 8,
    paddingLeft: 8,
    paddingBottom: 16,
    textAlign: 'right',
    alignSelf: 'flex-end',
    borderRadius: 0,
    width: 138,
  },
  historyTable: {
    borderRadius: 0,
    minWidth: 200,
    '& tr': {
      maxWidth: 976,
      height: 48,
      '& td': {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 20,
        paddingRight: 12,
      },
    },
  },
  separate: {
    borderRadius: 12,
  },
  separateVoidGrid: {
    flexWrap: 'noWrap',
    borderRadius: '12px !important',
  },
  content: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
    backgroundColor: theme.palette.background.typography,
  },
  exportCSVText: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'none',
  },
  contentGrid: {
    paddingBottom: 16,
  },
  cardNoMachineTelemetryText: {
    color: theme.palette.primary.text,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 16,
    textAlign: 'center',
  },
  detailsTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
    paddingRight: '5px',
  },
  headingTitle: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
  },
  machineInfoCardPadding16: {
    padding: '16px !important',
  },
  telemetryTable: {
    borderRadius: 12,
    boxShadow: theme.customShadows.noTopShadow,
  },
  bottomLeftLegend: {
    paddingTop: 4,
  },
  cardNoMachineTelemetry: {
    borderRadius: 12,
  },
}));

const FirmwaresNamesStartDot = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    height: 8,
    width: 8,
    borderRadius: 90,
    marginRight: 8,
    marginBottom: 3,
    marginTop: 4,
    verticalAlign: 'middle',
    display: 'inline-flex',
  },
}))(Box);

export default function MachineTelemetryConsumption({
  search, dataList, totalCount, serialNumber, loading, dateIni, dateEnd,
  openAlert, page, setPage, rowsPerPage,
}) {
  // STATE________________________________
  const { project } = useProject();

  const headerExport = ['Device Serial', 'Market', 'Device', 'Platform', 'Type', 'Code', 'Description'];
  const createNotificationMutation = useMutation((variables) => GraphqlService.createNotification(variables.email, variables.notificationTitle, variables.notificationDescription));

  async function onClickExport() {
    try {
      const userToken = await Auth.currentAuthenticatedUser();
      const email = userToken?.signInUserSession?.idToken?.payload?.identities?.shift().userId || userToken?.attributes?.email;

      // Perform mutation
      await createNotificationMutation.mutateAsync({
        email,
        notificationTitle: 'CSV file is being generated',
        notificationDescription: 'Your CSV file is being generated. You will receive a notification with the download link shortly.',
      });
      await GraphqlService.getMachineTelemetryConsumptionCSV(
        project.code, serialNumber, dateIni, dateEnd,
      );
      openAlert('Info', 'CSV file is being generated');
    } catch (error) {
      openAlert('Error', error.message);
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    search(newPage);
  };

  // WEBPAGE______________________________
  const classes = useStyles();

  useEffect(() => { }, []);

  return (
    <Box>
      <Grid
        container
        spacing={0}
        direction="row"
        className={classes.content}
      >
        {(dataList !== null) && (dataList.length > 0)
          && (
            <Grid
              container
              spacing={0}
              direction="row"
              className={classes.contentGrid}
            >
              <Grid item xs={6} className={classes.bottomLeftLegend}>
                <Typography className={classes.bottomLeftLegend}>Data will be retrieved in real time</Typography>
              </Grid>

              <Grid item xs={6} className={classes.bottomRightExportButton}>

                <span>
                  <SecondaryButton
                    id="btnTelemetryExport"
                    onClick={() => onClickExport()}
                  >
                    Export .CSV
                  </SecondaryButton>
                </span>

              </Grid>

              <Grid
                item
                xs={12}
                className={classes.separate}
              >

                <TableContainer component={Paper} className={classes.telemetryTable} id="machine-telemetry-table-body">
                  <Table className={classes.historyTable} aria-label="custom pagination table">
                    <TableHead className={classes.headerBorderTable}>
                      <TableRow className={classes.headerTable}>
                        {headerExport.map((head) => <TableCell className={classes.tableHead}>{head}</TableCell>)}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataList.map((resp, i) => (
                        <MachineTelemetryConsumptionListItem
                          id={`cellTelemetry${i}`}
                          eventMachine={resp}
                        />
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow key="paginationFooter">
                        <TablePagination
                          rowsPerPageOptions={[20]}
                          colSpan={10}
                          count={totalCount}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                          }}
                          onChangePage={handleChangePage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                  {loading && <LinearProgress />}
                </TableContainer>

              </Grid>
            </Grid>

          )}
        {(dataList !== null) && (dataList.length === 0)
          && !loading
          && (
            <Grid
              item
              xs={12}
              className={classes.separateVoidGrid}
            >
              <Card className={classes.cardNoMachineTelemetry}>
                <CardContent className={classes.machineInfoCardPadding16}>
                  <Typography className={classes.cardNoMachineTelemetryText}>
                    No machine events available for this machine
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
      </Grid>
    </Box>
  );
}
MachineTelemetryConsumption.propTypes = {
  search: PropTypes.func.isRequired,
  dataList: PropTypes.array.isRequired,
  totalCount: PropTypes.number.isRequired,
  serialNumber: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  dateIni: PropTypes.object.isRequired,
  dateEnd: PropTypes.object.isRequired,
  openAlert: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
