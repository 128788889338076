/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box, Grid, InputLabel, makeStyles, TextField, Typography,
} from '@material-ui/core';
import {
  KeyboardDatePicker, MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  React, useContext, useEffect, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { Alert } from '@material-ui/lab';
import { useProject } from '../../config/ProjectContext';
import GraphqlService from '../../service/graphqlService';
import PageBanner from '../../components/utils/PageBanner';
import SimpleAlert from '../../components/utils/SimpleAlert';
import ProgressDialog from '../../components/utils/ProgressDialog';
import SearchButton from '../../components/utils/SearchButton';
import {
  REGEX_SERIAL_NUMBER,
} from '../../utils/constants';
import MachineTelemetryFOTA from './MachineTelemetry/MachineTelemetryFOTA';
import { ReactComponent as CalendarIcon } from '../../assets/ic_calendar.svg';

const useStyles = makeStyles((theme) => ({
  formField: {
    width: '100%',
  },
  inputDescriptionLabel: {
    font: theme.typography.h4.font,
    fontSize: 16,
    color: theme.palette.primary.text,
  },
  topContent: {
    backgroundColor: theme.palette.background.paper,
    padding: 24,
  },
  bottomLeftFields: {
    paddingLeft: 24,
  },
  bottomRightFields: {
    paddingLeft: 24,
  },
  errorAlert: {
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 4,
  },
  bottomRightButton: {
    paddingRight: 8,
    paddingLeft: 8,
    textAlign: 'right',
    alignSelf: 'flex-end',
    borderRadius: 12,
    paddingTop: 8,
    width: 138,
  },
  searchIcon: {
    marginRight: '16px',
  },
  picker: {
    '& .MuiIconButton-root': {
      color: theme.palette.primary.button,
    },
    width: '100%',
  },
  bottomContent: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  // tabs
  headerTabPanel: {
    backgroundColor: theme.palette.background.paper,
  },
  labelHeaderTabPanel: {
    color: theme.palette.primary.labelHeader,
    fontFamily: theme.typography.h3.fontFamily,
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '0',
    lineHeight: '16px',
    textAlign: 'center',
    width: '720px',
    height: '48px',
  },
  // machine info card
  machineInfoPaddingTop: {
    paddingTop: 24,
  },
  machineInfoCardPrimaryText: {
    color: theme.palette.primary.text,
  },
  machineInfoCardPaddingBottomTop: {
    paddingBottom: 4,
    paddingTop: 4,
  },
  machineInfoCardTitle: {
    display: 'flex',
    marginBottom: 12,
  },
  machineInfoCardFontSize16: {
    fontSize: 16,
  },
  machineInfoCardSubTitle: {
    display: 'flex',
    paddingLeft: 16,
  },
  detailsTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
    paddingRight: '5px',
  },
  machineInfoCardFontSize14: {
    fontSize: 14,
  },
  machineInfoCardPaddingLeft16: {
    paddingLeft: 16,
  },
  machineInfoCardExtraField: {
    display: 'flex',
    paddingLeft: 16,
    marginTop: 4,
  },
  machineInfoCardFlex: {
    display: 'flex',
  },
  machineInfoCardPaddingLeft32: {
    paddingLeft: 32,
  },
}));

export default function FotaEvents({ openSnackBar }) {
  const MAX_MONTHS_BETWEEN_DATES = 6.03286;
  // STATE________________________________
  const { project } = useProject();

  // grid
  const [lifecyclePage, setLifecyclePage] = useState(0);
  const [paginationTokens, setPaginationTokens] = useState(['']);
  const [rowsPerPageFOTA] = useState(100);
  //  alert data
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertHeader, setAlertHeader] = useState('');
  const [alertText, setAlertText] = useState('');
  //  state values
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  // errors
  const [dateStartError, setDateStartError] = useState(false);
  const [dateEndError, setDateEndError] = useState(false);
  const [greaterSmallerDateError, setGreaterSmallerDateError] = useState(false);
  const [moreThanMaxDaysDateError, setMoreThanMaxDaysDateError] = useState(false);
  const [moreThanMaxMonthsDateError, setMoreThanMaxMonthsDateError] = useState(false);

  // data
  const [selectedSerialNumber, setSelectedSerialNumber] = useState();
  const [serialNumberError, setSerialNumberError] = useState(false);
  const [serialNumberRegexError, setSerialNumberRegexError] = useState(false);
  const [serialNumberModelRegexError, setSerialNumberModelRegexError] = useState(false);

  const [selectedStartDate, setSelectedStartDate] = useState(moment().add(-30, 'days'));
  const [selectedEndDate, setSelectedEndDate] = useState(moment());
  // progress dialog
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);

  const [lifecycleResponse, setLifecycleResponse] = useState(null);

  function openAlert(header, text) {
    setAlertHeader(header);
    setAlertText(text);
    setAlertOpen(true);
  }

  // FORM_________________________________
  const {
    handleSubmit, register, formState: { errors },
  } = useForm();

  // validations

  function datesValid(dateStart, dateEnd) {
    let validation = true;

    if (dateStart > dateEnd) {
      setGreaterSmallerDateError(true);
      validation = false;
    }

    if (moment(dateEnd).diff(moment(dateStart), 'months', true) > MAX_MONTHS_BETWEEN_DATES) {
      setMoreThanMaxMonthsDateError(true);
      setMoreThanMaxDaysDateError(false);
      validation = false;
    }
    return validation;
  }

  function serialNumberValidOrEmpty(serialNumber) {
    const validation = (!serialNumber || (serialNumber.length > 0 && (REGEX_SERIAL_NUMBER).test(serialNumber)));
    return validation;
  }

  function validate(serialNumber, dateStart, dateEnd) {
    let validates = true;
    if (!serialNumber) {
      setSerialNumberError(true);
      validates = false;
    }
    if (!serialNumberValidOrEmpty(serialNumber)) {
      setSerialNumberRegexError(true);
      validates = false;
    }
    /* if (model.deploy_reg_ex && !JSON.parse(model.deploy_reg_ex)?.some((regex) => serialNumber.match(regex))) {
      setSerialNumberModelRegexError(true);
      validates = false;
    } */
    if (!dateStart.isValid()) {
      setDateStartError(true);
      validates = false;
    } else if (!dateEnd.isValid()) {
      setDateEndErrorsetDateEndError(true);
      validates = false;
    } else if (!datesValid(dateStart, dateEnd)) {
      validates = false;
    }

    return validates;
  }

  async function searchFOTA(myProject, serialNumber, startDate, endDate, paginationToken) {
    const creationResponse = await GraphqlService.getMachineTelemetryFOTA(
      myProject, serialNumber, startDate?.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), paginationToken,
    );
    setPaginationTokens((prev) => {
      if (creationResponse?.paginationToken && !prev.some((token) => token === creationResponse.paginationToken)) {
        return [...prev, creationResponse.paginationToken];
      }
      return prev;
    });
    return creationResponse?.machineTelemetryFOTA || [];
  }

  async function search(myProject, serialNumber, startDate, endDate, newPage, newValue) {
    try {
      setSubmitting(true);
      setProgressDialogOpen(true);
      setLoading(true);
      let creationResponse = [];

      setLifecyclePage(newPage || 0);
      creationResponse = await searchFOTA(
        myProject,
        serialNumber,
        startDate,
        endDate,
        paginationTokens[newPage] ? paginationTokens[newPage] : undefined,
      );
      setLifecycleResponse(creationResponse);
      setMoreThanMaxMonthsDateError(false);
      setMoreThanMaxDaysDateError(false);
      setSubmitting(false);
      setProgressDialogOpen(false);
      setLoading(false);
      return creationResponse;
    } catch (error) {
      setProgressDialogOpen(false);
      setSubmitting(false);
      setLoading(false);
      openAlert('Error', error.message);
      return null;
    }
  }

  const onSubmit = (data) => {
    if (validate(selectedSerialNumber, selectedStartDate, selectedEndDate)) {
      search(
        project.code, selectedSerialNumber, selectedStartDate, selectedEndDate,
      );
    }
  };

  // WEBPAGE______________________________
  const classes = useStyles();

  useEffect(() => {
    setPaginationTokens(['']);
  }, [selectedSerialNumber, selectedStartDate, selectedEndDate]);

  useEffect(() => {
    setSerialNumberError(false);
    setSerialNumberRegexError(false);
    setSerialNumberModelRegexError(false);
  }, [project]);

  return (
    <Box spacing={0}>
      <ProgressDialog open={progressDialogOpen} setOpen={setProgressDialogOpen} header="Retrieving statistics, please wait" />
      <SimpleAlert open={alertOpen} setOpen={setAlertOpen} header={alertHeader} body={alertText} />
      <PageBanner title="FOTA EVENTS" />
      <Grid
        container
        spacing={0}
      >
        <form onSubmit={handleSubmit(onSubmit)} className={classes.content}>
          <Grid
            item
            container
            spacing={0}
            direction="row"
            className={classes.topContent}
          >
            <Grid item xs={12}>
              <InputLabel
                className={classes.inputDescriptionLabel}
              >
                Enter the device serial and the time period to view its fota events.
              </InputLabel>
            </Grid>
            <Grid
              item
              container
              xs={12}
              spacing={0}
              direction="row"
              className={classes.bottomContent}
            >
              <Grid item xs={6}>
                <TextField
                  id="inputFotaMachine"
                  label="Device serial"
                  className={classes.formField}
                  variant="filled"
                  InputProps={{
                    maxLength: 128,
                  }}
                  disabled={submitting}
                  value={selectedSerialNumber}
                  autoFocus
                  error={serialNumberError}
                  onChange={(event) => {
                    setSerialNumberError(!event.target.value);
                    setSelectedSerialNumber(event.target.value);
                    setSerialNumberRegexError(false);
                    setSerialNumberModelRegexError(false);
                  }}
                  color="secondary"
                />
                {serialNumberError && <Alert severity="error" className={classes.errorAlert}>Cannot be empty</Alert>}
                {serialNumberRegexError && <Alert severity="error" className={classes.errorAlert}>Device serial only allows numbers and letters</Alert>}
                {serialNumberModelRegexError && <Alert severity="error" className={classes.errorAlert}>Device serial does not match with model</Alert>}
              </Grid>
              <Grid item xs={3} className={classes.bottomLeftFields}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    autoOk
                    id="inputFotaFrom"
                    variant="inline"
                    orientation="landscape"
                    inputVariant="filled"
                    label="From"
                    value={selectedStartDate}
                    format="DD/MM/yyyy"
                    onChange={(date) => {
                      setSelectedStartDate(date);
                      setDateStartError(date !== ' ' || !date);
                      setGreaterSmallerDateError(false);
                      setMoreThanMaxDaysDateError(false);
                      setMoreThanMaxMonthsDateError(false);
                    }}
                    className={classes.picker}
                    color="secondary"
                    keyboardIcon={<CalendarIcon />}
                  />
                </MuiPickersUtilsProvider>
                {!selectedStartDate && <Alert severity="error" className={classes.errorAlert}>Cannot be empty</Alert>}
                {greaterSmallerDateError && <Alert severity="error" className={classes.errorAlert}>From date has to be before the To date</Alert>}
                {moreThanMaxDaysDateError && <Alert severity="error" className={classes.errorAlert}>Dates need to be max. 30 days apart</Alert>}
                {moreThanMaxMonthsDateError && <Alert severity="error" className={classes.errorAlert}>Dates need to be max. 6 months apart</Alert>}
              </Grid>
              <Grid item xs={3} className={classes.bottomRightFields}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    autoOk
                    id="inputFotaTo"
                    variant="inline"
                    orientation="landscape"
                    inputVariant="filled"
                    label="To"
                    value={selectedEndDate}
                    format="DD/MM/yyyy"
                    onChange={(date) => {
                      setDateEndError(!date);
                      setSelectedEndDate(date);
                      setGreaterSmallerDateError(false);
                      setMoreThanMaxDaysDateError(false);
                      setMoreThanMaxMonthsDateError(false);
                    }}
                    className={classes.picker}
                    color="secondary"
                    keyboardIcon={<CalendarIcon />}
                  />
                </MuiPickersUtilsProvider>
                {!selectedEndDate && <Alert severity="error" className={classes.errorAlert}>Cannot be empty</Alert>}
                {greaterSmallerDateError && <Alert severity="error" className={classes.errorAlert}>To date has to be after the From date</Alert>}
                {moreThanMaxDaysDateError && <Alert severity="error" className={classes.errorAlert}>Dates need to be max. 30 days apart</Alert>}
                {moreThanMaxMonthsDateError && <Alert severity="error" className={classes.errorAlert}>Dates need to be max. 6 months apart</Alert>}
              </Grid>
            </Grid>

            <Grid
              container
              spacing={0}
              direction="row"
            >
              <Grid item xs className={classes.bottomRightButton}>
                <SearchButton
                  id="btnFotaSearch"
                  type="submit"
                  className={classes.bottomRightButton}
                >
                  SEARCH
                </SearchButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid item xs={12}>
        <MachineTelemetryFOTA
          search={(newPage) => search(project.code, selectedSerialNumber, selectedStartDate, selectedEndDate, newPage)}
          dataList={lifecycleResponse}
          serialNumber={selectedSerialNumber}
          loading={loading}
          dateIni={selectedStartDate}
          dateEnd={selectedEndDate}
          openAlert={openAlert}
          page={lifecyclePage}
          setPage={setLifecyclePage}
          rowsPerPage={rowsPerPageFOTA}
          paginationTokens={paginationTokens}
        />
      </Grid>
    </Box>
  );
}
FotaEvents.propTypes = {
  openSnackBar: PropTypes.func.isRequired,
};
