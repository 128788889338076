/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box, Grid, makeStyles, AppBar, Tab, Tabs, Typography,
} from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  React, useEffect, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useProject } from '../../config/ProjectContext';
import GraphqlService from '../../service/graphqlService';
import PageBanner from '../../components/utils/PageBanner';
import SimpleAlert from '../../components/utils/SimpleAlert';
import ProgressDialog from '../../components/utils/ProgressDialog';
import BrandObjectTable from './MachineTelemetry/BrandObjectTable';
import { a11yProps } from '../../components/TabPanel';

import {
  validate, calculateEndDateTimeIso, calculateStartDateTimeIso,
} from '../../utils/dateUtils';
import JSONBuilderFilters, { valueTypes } from '../../components/utils/JSONBuilderFilters';

const useStyles = makeStyles((theme) => ({
  formField: {
    width: '100%',
    '& ::placeholder': {
      paddingLeft: 8,
      paddingTop: 8,
    },
  },
  formPlaceholder: {
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: 'blue',
    },
  },
  inputDescriptionLabel: {
    font: theme.typography.h4.font,
    color: theme.palette.primary.text,
  },
  // tabs
  headerTabPanel: {
    height: 48,
    backgroundColor: theme.palette.background.paper,
  },
  labelHeaderTabPanel: {
    color: theme.palette.primary.subMain,
    fontFamily: theme.typography.h3.fontFamily,
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '0',
    lineHeight: '19px',
    textAlign: 'center',
    width: '720px',
    height: '48px',
  },
  detailsTitle: {
    paddingRight: '5px',
    fontFamily: theme.typography.h5.fontFamily,
    lineHeight: theme.typography.h5.lineHeight,
    letterSpacing: theme.typography.h5.letterSpacing,
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.primary.subMain,
  },
  accordion: {
    borderRadius: '12px !important',
  },
  details: {
    paddingLeft: 24,
    paddingRight: 24,
  },
}));

const jsonColumns = [
  {
    id: 'serial', label: 'Serial', valueType: valueTypes.STRING, values: [],
  },
  {
    id: 'model', label: 'Model', valueType: valueTypes.STRING, values: [],
  },
  {
    id: 'country_code', label: 'Country Code', valueType: valueTypes.STRING, values: [],
  },
  {
    id: 'fw', label: 'Firmware', valueType: valueTypes.STRING, values: [],
  },
  {
    id: 'hw', label: 'Hardware', valueType: valueTypes.STRING, values: [],
  },
  {
    id: 'sdk', label: 'SDK', valueType: valueTypes.STRING, values: [],
  },
  {
    id: 'extra', label: 'Extra', valueType: valueTypes.STRING, values: [],
  },
  {
    id: 'timezone', label: 'Timezone', valueType: valueTypes.STRING, values: [],
  },
  {
    id: 'received_first_on_utc', label: 'Received First On UTC', valueType: valueTypes.DATE, values: [],
  },
  {
    id: 'received_on_utc', label: 'Received On UTC', valueType: valueTypes.DATE, values: [],
  },
  {
    id: 'processed_on_utc', label: 'Processed On UTC', valueType: valueTypes.DATE, values: [],
  },
];

export default function DeviceInfo({ openSnackBar }) {
  const MAX_DAYS_BETWEEN_DATES = 30;
  const params = { include_gateway: true, include_modules: true, add_dict: false };
  const paramsHistory = {};
  // STATE________________________________
  const { project } = useProject();

  // grid
  const [totalCount, setTotalCount] = useState(0);
  const [reducedPage, setReducedPage] = useState(0);
  const [paginationTokens, setPaginationTokens] = useState(['']);
  const [rowsPerPageConsumption] = useState(10);
  const [reducedResponse, setReducedResponse] = useState(null);

  //  alert data
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertHeader, setAlertHeader] = useState('');
  const [alertText, setAlertText] = useState('');

  //  state values
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);

  // errors
  const [dateStartError, setDateStartError] = useState(false);
  const [dateEndError, setDateEndError] = useState(false);
  const [greaterSmallerDateError, setGreaterSmallerDateError] = useState(false);
  const [moreThanMaxDaysDateError, setMoreThanMaxDaysDateError] = useState(false);
  const [deviceSerialError, setDeviceSerialError] = useState(false);

  // data
  const [selectedFilters, setSelectedFilters] = useState('');
  const [deviceSerial, setDeviceSerial] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState(moment().add(-29, 'days'));
  const [selectedStartTime, setSelectedStartTime] = useState(moment().add(-29, 'days').format('HH:mm'));
  const [selectedEndDate, setSelectedEndDate] = useState(moment());
  const [selectedEndTime, setSelectedEndTime] = useState(moment().format('HH:mm'));

  // progress dialog
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [dateRangePickerVisibility, setDateRangePickerVisibility] = useState(true);

  // tabs
  const [valueTab, setValueTab] = useState(0);

  function openAlert(header, text) {
    setAlertHeader(header);
    setAlertText(text);
    setAlertOpen(true);
  }

  // FORM_________________________________
  const {
    handleSubmit, register, formState: { errors },
  } = useForm();

  // functions to obtain the data to show
  async function searchObject(myProject, filters, startDate, endDate, newPage) {
    setDateRangePickerVisibility(!dateRangePickerVisibility);
    let deviceInfoResponse = [];
    if (valueTab === 0) {
      deviceInfoResponse = await GraphqlService.getTelemetryObject(
        myProject.code, 'devices', JSON.stringify(params), filters, startDate, endDate, rowsPerPageConsumption, (newPage || 0) * rowsPerPageConsumption, MAX_DAYS_BETWEEN_DATES,
      );
    } else {
      deviceInfoResponse = await GraphqlService.getTelemetryObject(
        myProject.code, `devices/${deviceSerial}/history`, JSON.stringify(paramsHistory), filters, startDate, endDate, rowsPerPageConsumption, (newPage || 0) * rowsPerPageConsumption, MAX_DAYS_BETWEEN_DATES,
      );
    }
    // eslint-disable-next-line no-plusplus
    if (deviceInfoResponse) {
      if (totalCount < 1 || !newPage) {
        setTotalCount(deviceInfoResponse.pagination.totalRows);
      }
      return JSON.parse(deviceInfoResponse.data);
    }
    return [];
  }

  async function search(myProject, filters, startDate, endDate, startTime, endTime, newPage, newValue) {
    try {
      setSubmitting(true);
      setProgressDialogOpen(true);
      setLoading(true);
      let creationResponse = [];

      setReducedPage(newPage || 0);
      creationResponse = await searchObject(myProject, filters, calculateStartDateTimeIso(startDate, startTime), calculateEndDateTimeIso(endDate, endTime), newPage);
      setPaginationTokens(['']);
      setReducedResponse(creationResponse);
      setMoreThanMaxDaysDateError(false);

      setSubmitting(false);
      setProgressDialogOpen(false);
      setLoading(false);
      return creationResponse;
    } catch (error) {
      console.log('Error!!!', error);
      setProgressDialogOpen(false);
      setSubmitting(false);
      setLoading(false);
      openAlert('Error', error.message);
      return null;
    }
  }

  const onSearch = (event, filters) => {
    if (valueTab === 1 && deviceSerial === '') {
      setDeviceSerialError(true);
      return;
    }
    setDeviceSerialError(false);
    event.preventDefault();
    if (validate(selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime, MAX_DAYS_BETWEEN_DATES, setDateStartError, setDateEndError, setSelectedStartDate, setSelectedStartTime, setSelectedEndDate, setSelectedEndTime, setGreaterSmallerDateError, setMoreThanMaxDaysDateError)) {
      search(
        project, filters, selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime,
      );
    }
  };

  // WEBPAGE______________________________
  const classes = useStyles();

  useEffect(() => {
    setPaginationTokens(['']);
  }, [selectedFilters, selectedStartDate, selectedEndDate]);

  const handleChange = (event, newValue) => {
    setReducedResponse(null);
    setValueTab(newValue);
  };

  return (
    <Box spacing={0}>
      <ProgressDialog open={progressDialogOpen} setOpen={setProgressDialogOpen} header="Retrieving statistics, please wait" />
      <SimpleAlert open={alertOpen} setOpen={setAlertOpen} header={alertHeader} body={alertText} />
      <PageBanner title="DEVICE INFO" />
      <AppBar position="static" elevation={0}>
        <Tabs value={valueTab} onChange={handleChange} centered className={classes.headerTabPanel} classes={{ indicator: classes.indicator }}>
          <Tab id="tabCurrent" label="CURRENT" {...a11yProps(0)} className={classes.labelHeaderTabPanel} />
          <Tab id="tabHistory" label="HISTORY" {...a11yProps(1)} className={classes.labelHeaderTabPanel} />
        </Tabs>
      </AppBar>
      <Grid
        container
        spacing={0}
      >
        <Grid item xs={12}>
          {valueTab === 0 ? (

            <div>
              <JSONBuilderFilters
                id="DeviceInfo"
                setSelectedFilters={setSelectedFilters}
                submitting={submitting}
                selectedStartDate={selectedStartDate}
                setSelectedStartDate={setSelectedStartDate}
                selectedStartTime={selectedStartTime}
                setSelectedStartTime={setSelectedStartTime}
                selectedEndDate={selectedEndDate}
                setSelectedEndDate={setSelectedEndDate}
                selectedEndTime={selectedEndTime}
                setSelectedEndTime={setSelectedEndTime}
                filtersVisible
                linkVisible
                deviceSerialVisible={false}
                jsonColumns={jsonColumns}
                setDateRangePickerVisibility={dateRangePickerVisibility}
                onSearch={onSearch}
              />
            </div>

          ) : (
            <div style={{ padding: 0 }}>
              <JSONBuilderFilters
                id="DeviceInfoHistory"
                setSelectedFilters={setSelectedFilters}
                submitting={submitting}
                selectedStartDate={selectedStartDate}
                setSelectedStartDate={setSelectedStartDate}
                selectedStartTime={selectedStartTime}
                setSelectedStartTime={setSelectedStartTime}
                selectedEndDate={selectedEndDate}
                setSelectedEndDate={setSelectedEndDate}
                selectedEndTime={selectedEndTime}
                setSelectedEndTime={setSelectedEndTime}
                filtersVisible
                linkVisible
                deviceSerialVisible
                deviceSerial={deviceSerial}
                setDeviceSerial={setDeviceSerial}
                deviceSerialError={deviceSerialError}
                jsonColumns={jsonColumns}
                onSearch={onSearch}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          <BrandObjectTable
            search={(newPage) => search(project, selectedFilters, selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime, newPage)}
            dataList={reducedResponse}
            totalCount={totalCount}
            filters={selectedFilters}
            loading={loading}
            dateIni={calculateStartDateTimeIso(selectedStartDate, selectedStartTime)}
            dateEnd={calculateEndDateTimeIso(selectedEndDate, selectedEndTime)}
            rangeDates={MAX_DAYS_BETWEEN_DATES}
            page={reducedPage}
            setPage={setReducedPage}
            rowsPerPage={rowsPerPageConsumption}
            object={valueTab === 0 ? 'devices' : `devices/${deviceSerial}/history`}
            params={JSON.stringify(params)}
            id={valueTab === 0 ? 'DeviceInfo' : 'DeviceInfoHistory'}
          />
        </Grid>

      </Grid>
    </Box>
  );
}
DeviceInfo.propTypes = {
  openSnackBar: PropTypes.func.isRequired,
};
