/* eslint-disable no-unused-vars */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card, CardContent, CircularProgress, Grid, Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: 24,
  },
  cardsTypography: {
    color: theme.palette.primary.text,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 16,
    textAlign: 'center',
    marginTop: 8,
    marginBottom: 8,
    marginLeft: 16,
    marginRight: 16,
  },
  loading: {
    marginTop: 8,
    marginBottom: 8,
    marginLeft: 16,
    marginRight: 16,
  },
}));

export default function LoadingCard() {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      alignItems="center"
      justifyContent="center"
    >
      <Card className={classes.card}>
        <CardContent style={{ padding: 16 }}>
          <Grid
            container
            spacing={0}
            direction="row"
            alignItems="center"
          >
            <Grid item>
              <Typography className={classes.cardsTypography}>
                Loading
              </Typography>
            </Grid>
            <Grid item>
              <CircularProgress className={classes.loading} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
