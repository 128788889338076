/* eslint-disable react/jsx-props-no-spreading */
import { Button, makeStyles } from '@material-ui/core';
import { React } from 'react';
import SearchIcon from '@material-ui/icons/Search';

export default function SearchButton(props) {
  const useStyles = makeStyles((theme) => ({
    primaryButton: {
      backgroundColor: theme.palette.primary.button,
      color: theme.palette.common.white,
      padding: '8px 16px',
      borderRadius: 12,
      '& :disabled': {
        color: theme.palette.primary.light,
      },
    },
  }));

  const classes = useStyles();
  return (<Button {...props} startIcon={<SearchIcon />} className={classes.primaryButton} color="secondary" variant="contained" />);
}
